// Generated by re2js 4.0.1 on Sun Jan  5 16:16:20 2025
import * as T from "./typedefs"

export function parsePsql(typ: string): T.PostgreSQLDataType | null {
    let yyinput = typ + '\0';
    let yycursor = 0;
    let yymarker = 0;

    const num = (b: number, e: number) : number =>
	Number(yyinput.substring(b,e));

    const sec_prec = (p: number) : number =>
	+yyinput[p];

    const adim = (b: number, e: number) : number => {
      let count = 0;
      for (let i = b; i < e; i++) {
        if (yyinput[i] === '[') {
            count++;
        }
      }
      return count;
    }

    const ret = (r: T.PostgreSQLDataType, ab: number | undefined, ae: number | undefined) : T.PostgreSQLDataType => {
      if(ab === -1) {
        return r;
      } else {
        return { kind: 'array', elementType: r, dimensions: adim(ab!, ae!) } as T.PostgreSQLDataType;
      }
    }
    
    // Final tag variables available in semantic action.
    let ab
let ae
let d
let dh
let dm
let ds
let h
let hm
let hs
let m
let mm
let ms
let s
let t
let t1
let t2
let t3
let t4
let y
let ym


    // Intermediate tag variables used by the lexer (must be autogenerated).
    let yyt1
let yyt10
let yyt11
let yyt12
let yyt13
let yyt14
let yyt15
let yyt16
let yyt2
let yyt3
let yyt4
let yyt5
let yyt6
let yyt7
let yyt8
let yyt9


    
{
    let yych = 0
    let yystate = 0
    yyl: while (true) {
        switch (yystate) {
            case 0:
                yych = yyinput.charCodeAt(yycursor)
                yycursor += 1;
                switch (yych) {
                    case 0x22:
                        yystate = 3
                        continue yyl
                    case 0x42:
                    case 0x62:
                        yystate = 4
                        continue yyl
                    case 0x43:
                    case 0x63:
                        yystate = 5
                        continue yyl
                    case 0x44:
                    case 0x64:
                        yystate = 6
                        continue yyl
                    case 0x46:
                    case 0x66:
                        yystate = 7
                        continue yyl
                    case 0x49:
                    case 0x69:
                        yystate = 8
                        continue yyl
                    case 0x4A:
                    case 0x6A:
                        yystate = 9
                        continue yyl
                    case 0x4C:
                    case 0x6C:
                        yystate = 10
                        continue yyl
                    case 0x4D:
                    case 0x6D:
                        yystate = 11
                        continue yyl
                    case 0x4E:
                    case 0x6E:
                        yystate = 12
                        continue yyl
                    case 0x50:
                    case 0x70:
                        yystate = 13
                        continue yyl
                    case 0x52:
                    case 0x72:
                        yystate = 14
                        continue yyl
                    case 0x53:
                    case 0x73:
                        yystate = 15
                        continue yyl
                    case 0x54:
                    case 0x74:
                        yystate = 16
                        continue yyl
                    case 0x55:
                    case 0x75:
                        yystate = 17
                        continue yyl
                    case 0x56:
                    case 0x76:
                        yystate = 18
                        continue yyl
                    case 0x58:
                    case 0x78:
                        yystate = 19
                        continue yyl
                    default:
                        yystate = 1
                        continue yyl
                }
            case 1:
                yystate = 2
                continue yyl
            case 2:
                { return null; }
            case 3:
                yymarker = yycursor;
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x43:
                    case 0x63:
                        yycursor += 1;
                        yystate = 20
                        continue yyl
                    default:
                        yystate = 2
                        continue yyl
                }
            case 4:
                yymarker = yycursor;
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x49:
                    case 0x69:
                        yycursor += 1;
                        yystate = 22
                        continue yyl
                    case 0x4F:
                    case 0x6F:
                        yycursor += 1;
                        yystate = 23
                        continue yyl
                    case 0x50:
                    case 0x70:
                        yycursor += 1;
                        yystate = 24
                        continue yyl
                    case 0x59:
                    case 0x79:
                        yycursor += 1;
                        yystate = 25
                        continue yyl
                    default:
                        yystate = 2
                        continue yyl
                }
            case 5:
                yymarker = yycursor;
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x48:
                    case 0x68:
                        yycursor += 1;
                        yystate = 26
                        continue yyl
                    case 0x49:
                    case 0x69:
                        yycursor += 1;
                        yystate = 27
                        continue yyl
                    default:
                        yystate = 2
                        continue yyl
                }
            case 6:
                yymarker = yycursor;
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x41:
                    case 0x61:
                        yycursor += 1;
                        yystate = 28
                        continue yyl
                    case 0x4F:
                    case 0x6F:
                        yycursor += 1;
                        yystate = 29
                        continue yyl
                    default:
                        yystate = 2
                        continue yyl
                }
            case 7:
                yymarker = yycursor;
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x4C:
                    case 0x6C:
                        yycursor += 1;
                        yystate = 30
                        continue yyl
                    default:
                        yystate = 2
                        continue yyl
                }
            case 8:
                yymarker = yycursor;
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x4E:
                    case 0x6E:
                        yycursor += 1;
                        yystate = 31
                        continue yyl
                    default:
                        yystate = 2
                        continue yyl
                }
            case 9:
                yymarker = yycursor;
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x53:
                    case 0x73:
                        yycursor += 1;
                        yystate = 32
                        continue yyl
                    default:
                        yystate = 2
                        continue yyl
                }
            case 10:
                yymarker = yycursor;
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x49:
                    case 0x69:
                        yycursor += 1;
                        yystate = 33
                        continue yyl
                    case 0x53:
                    case 0x73:
                        yycursor += 1;
                        yystate = 34
                        continue yyl
                    default:
                        yystate = 2
                        continue yyl
                }
            case 11:
                yymarker = yycursor;
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x41:
                    case 0x61:
                        yycursor += 1;
                        yystate = 35
                        continue yyl
                    case 0x4F:
                    case 0x6F:
                        yycursor += 1;
                        yystate = 36
                        continue yyl
                    default:
                        yystate = 2
                        continue yyl
                }
            case 12:
                yymarker = yycursor;
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x55:
                    case 0x75:
                        yycursor += 1;
                        yystate = 37
                        continue yyl
                    default:
                        yystate = 2
                        continue yyl
                }
            case 13:
                yymarker = yycursor;
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x41:
                    case 0x61:
                        yycursor += 1;
                        yystate = 38
                        continue yyl
                    case 0x4F:
                    case 0x6F:
                        yycursor += 1;
                        yystate = 39
                        continue yyl
                    default:
                        yystate = 2
                        continue yyl
                }
            case 14:
                yymarker = yycursor;
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x45:
                    case 0x65:
                        yycursor += 1;
                        yystate = 40
                        continue yyl
                    default:
                        yystate = 2
                        continue yyl
                }
            case 15:
                yymarker = yycursor;
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x45:
                    case 0x65:
                        yycursor += 1;
                        yystate = 41
                        continue yyl
                    case 0x4D:
                    case 0x6D:
                        yycursor += 1;
                        yystate = 42
                        continue yyl
                    default:
                        yystate = 2
                        continue yyl
                }
            case 16:
                yymarker = yycursor;
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x45:
                    case 0x65:
                        yycursor += 1;
                        yystate = 43
                        continue yyl
                    case 0x49:
                    case 0x69:
                        yycursor += 1;
                        yystate = 44
                        continue yyl
                    case 0x53:
                    case 0x73:
                        yycursor += 1;
                        yystate = 45
                        continue yyl
                    default:
                        yystate = 2
                        continue yyl
                }
            case 17:
                yymarker = yycursor;
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x55:
                    case 0x75:
                        yycursor += 1;
                        yystate = 46
                        continue yyl
                    default:
                        yystate = 2
                        continue yyl
                }
            case 18:
                yymarker = yycursor;
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x41:
                    case 0x61:
                        yycursor += 1;
                        yystate = 47
                        continue yyl
                    default:
                        yystate = 2
                        continue yyl
                }
            case 19:
                yymarker = yycursor;
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x4D:
                    case 0x6D:
                        yycursor += 1;
                        yystate = 48
                        continue yyl
                    default:
                        yystate = 2
                        continue yyl
                }
            case 20:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x48:
                    case 0x68:
                        yycursor += 1;
                        yystate = 49
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 21:
                yycursor = yymarker;
                yystate = 2
                continue yyl
            case 22:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x47:
                    case 0x67:
                        yycursor += 1;
                        yystate = 50
                        continue yyl
                    case 0x54:
                    case 0x74:
                        yycursor += 1;
                        yystate = 51
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 23:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x4F:
                    case 0x6F:
                        yycursor += 1;
                        yystate = 52
                        continue yyl
                    case 0x58:
                    case 0x78:
                        yycursor += 1;
                        yystate = 53
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 24:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x43:
                    case 0x63:
                        yycursor += 1;
                        yystate = 54
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 25:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x54:
                    case 0x74:
                        yycursor += 1;
                        yystate = 55
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 26:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x41:
                    case 0x61:
                        yycursor += 1;
                        yystate = 56
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 27:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x44:
                    case 0x64:
                        yycursor += 1;
                        yystate = 57
                        continue yyl
                    case 0x52:
                    case 0x72:
                        yycursor += 1;
                        yystate = 58
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 28:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x54:
                    case 0x74:
                        yycursor += 1;
                        yystate = 59
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 29:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x55:
                    case 0x75:
                        yycursor += 1;
                        yystate = 60
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 30:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x4F:
                    case 0x6F:
                        yycursor += 1;
                        yystate = 61
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 31:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x45:
                    case 0x65:
                        yycursor += 1;
                        yystate = 62
                        continue yyl
                    case 0x54:
                    case 0x74:
                        yycursor += 1;
                        yystate = 63
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 32:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x4F:
                    case 0x6F:
                        yycursor += 1;
                        yystate = 64
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 33:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x4E:
                    case 0x6E:
                        yycursor += 1;
                        yystate = 65
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 34:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x45:
                    case 0x65:
                        yycursor += 1;
                        yystate = 66
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 35:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x43:
                    case 0x63:
                        yycursor += 1;
                        yystate = 67
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 36:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x4E:
                    case 0x6E:
                        yycursor += 1;
                        yystate = 68
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 37:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x4D:
                    case 0x6D:
                        yycursor += 1;
                        yystate = 69
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 38:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x54:
                    case 0x74:
                        yycursor += 1;
                        yystate = 70
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 39:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x49:
                    case 0x69:
                        yycursor += 1;
                        yystate = 71
                        continue yyl
                    case 0x4C:
                    case 0x6C:
                        yycursor += 1;
                        yystate = 72
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 40:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x41:
                    case 0x61:
                        yycursor += 1;
                        yystate = 73
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 41:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x52:
                    case 0x72:
                        yycursor += 1;
                        yystate = 74
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 42:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x41:
                    case 0x61:
                        yycursor += 1;
                        yystate = 75
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 43:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x58:
                    case 0x78:
                        yycursor += 1;
                        yystate = 76
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 44:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x4D:
                    case 0x6D:
                        yycursor += 1;
                        yystate = 77
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 45:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x52:
                    case 0x72:
                        yycursor += 1;
                        yystate = 78
                        continue yyl
                    case 0x54:
                    case 0x74:
                        yycursor += 1;
                        yystate = 79
                        continue yyl
                    case 0x56:
                    case 0x76:
                        yycursor += 1;
                        yystate = 80
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 46:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x49:
                    case 0x69:
                        yycursor += 1;
                        yystate = 81
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 47:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x52:
                    case 0x72:
                        yycursor += 1;
                        yystate = 82
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 48:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x4C:
                    case 0x6C:
                        yycursor += 1;
                        yystate = 83
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 49:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x41:
                    case 0x61:
                        yycursor += 1;
                        yystate = 84
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 50:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x49:
                    case 0x69:
                        yycursor += 1;
                        yystate = 85
                        continue yyl
                    case 0x53:
                    case 0x73:
                        yycursor += 1;
                        yystate = 86
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 51:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x00:
                        yyt1 = -1;
                        yyt2 = -1;
                        yyt3 = -1;
                        yyt4 = -1;
                        yycursor += 1;
                        yystate = 87
                        continue yyl
                    case 0x20:
                        yyt2 = -1;
                        yyt3 = -1;
                        yyt4 = yycursor;
                        yycursor += 1;
                        yystate = 88
                        continue yyl
                    case 0x28:
                        yycursor += 1;
                        yystate = 89
                        continue yyl
                    case 0x5B:
                        yyt2 = -1;
                        yyt3 = -1;
                        yyt4 = yycursor;
                        yycursor += 1;
                        yystate = 90
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 52:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x4C:
                    case 0x6C:
                        yycursor += 1;
                        yystate = 91
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 53:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x00:
                        yyt1 = -1;
                        yyt2 = -1;
                        yycursor += 1;
                        yystate = 92
                        continue yyl
                    case 0x20:
                        yyt2 = yycursor;
                        yycursor += 1;
                        yystate = 93
                        continue yyl
                    case 0x5B:
                        yyt2 = yycursor;
                        yycursor += 1;
                        yystate = 94
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 54:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x48:
                    case 0x68:
                        yycursor += 1;
                        yystate = 95
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 55:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x45:
                    case 0x65:
                        yycursor += 1;
                        yystate = 96
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 56:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x52:
                    case 0x72:
                        yycursor += 1;
                        yystate = 97
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 57:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x52:
                    case 0x72:
                        yycursor += 1;
                        yystate = 98
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 58:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x43:
                    case 0x63:
                        yycursor += 1;
                        yystate = 99
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 59:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x45:
                    case 0x65:
                        yycursor += 1;
                        yystate = 100
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 60:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x42:
                    case 0x62:
                        yycursor += 1;
                        yystate = 101
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 61:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x41:
                    case 0x61:
                        yycursor += 1;
                        yystate = 102
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 62:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x54:
                    case 0x74:
                        yycursor += 1;
                        yystate = 103
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 63:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x32:
                        yycursor += 1;
                        yystate = 104
                        continue yyl
                    case 0x34:
                        yycursor += 1;
                        yystate = 105
                        continue yyl
                    case 0x38:
                        yycursor += 1;
                        yystate = 106
                        continue yyl
                    case 0x45:
                    case 0x65:
                        yycursor += 1;
                        yystate = 107
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 64:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x4E:
                    case 0x6E:
                        yycursor += 1;
                        yystate = 108
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 65:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x45:
                    case 0x65:
                        yycursor += 1;
                        yystate = 109
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 66:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x47:
                    case 0x67:
                        yycursor += 1;
                        yystate = 110
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 67:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x41:
                    case 0x61:
                        yycursor += 1;
                        yystate = 111
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 68:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x45:
                    case 0x65:
                        yycursor += 1;
                        yystate = 112
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 69:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x45:
                    case 0x65:
                        yycursor += 1;
                        yystate = 113
                        continue yyl
                    case 0x52:
                    case 0x72:
                        yycursor += 1;
                        yystate = 114
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 70:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x48:
                    case 0x68:
                        yycursor += 1;
                        yystate = 115
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 71:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x4E:
                    case 0x6E:
                        yycursor += 1;
                        yystate = 116
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 72:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x59:
                    case 0x79:
                        yycursor += 1;
                        yystate = 117
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 73:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x4C:
                    case 0x6C:
                        yycursor += 1;
                        yystate = 118
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 74:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x49:
                    case 0x69:
                        yycursor += 1;
                        yystate = 119
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 75:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x4C:
                    case 0x6C:
                        yycursor += 1;
                        yystate = 120
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 76:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x54:
                    case 0x74:
                        yycursor += 1;
                        yystate = 121
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 77:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x45:
                    case 0x65:
                        yycursor += 1;
                        yystate = 122
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 78:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x41:
                    case 0x61:
                        yycursor += 1;
                        yystate = 123
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 79:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x5A:
                    case 0x7A:
                        yycursor += 1;
                        yystate = 124
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 80:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x45:
                    case 0x65:
                        yycursor += 1;
                        yystate = 125
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 81:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x44:
                    case 0x64:
                        yycursor += 1;
                        yystate = 126
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 82:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x42:
                    case 0x62:
                        yycursor += 1;
                        yystate = 127
                        continue yyl
                    case 0x43:
                    case 0x63:
                        yycursor += 1;
                        yystate = 128
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 83:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x00:
                        yyt1 = -1;
                        yyt2 = -1;
                        yycursor += 1;
                        yystate = 129
                        continue yyl
                    case 0x20:
                        yyt2 = yycursor;
                        yycursor += 1;
                        yystate = 130
                        continue yyl
                    case 0x5B:
                        yyt2 = yycursor;
                        yycursor += 1;
                        yystate = 131
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 84:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x52:
                    case 0x72:
                        yycursor += 1;
                        yystate = 132
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 85:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x4E:
                    case 0x6E:
                        yycursor += 1;
                        yystate = 133
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 86:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x45:
                    case 0x65:
                        yycursor += 1;
                        yystate = 134
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 87:
                t1 = yyt2;
                t2 = yyt3;
                ab = yyt4;
                ae = yyt1;
                {
        if (t1 === -1) {
          return ret({ kind: 'bits', size: 1 } as T.PsqlBits, ab,ae);
        } else {
          return ret({ kind: 'bits', size: num(t1!,t2!) } as T.PsqlBits, ab,ae);
	}
      }
            case 88:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x20:
                        yycursor += 1;
                        yystate = 88
                        continue yyl
                    case 0x28:
                        yycursor += 1;
                        yystate = 89
                        continue yyl
                    case 0x56:
                    case 0x76:
                        yycursor += 1;
                        yystate = 135
                        continue yyl
                    case 0x5B:
                        yycursor += 1;
                        yystate = 90
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 89:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x20:
                        yycursor += 1;
                        yystate = 89
                        continue yyl
                    case 0x31:
                    case 0x32:
                    case 0x33:
                    case 0x34:
                    case 0x35:
                    case 0x36:
                    case 0x37:
                    case 0x38:
                    case 0x39:
                        yyt2 = yycursor;
                        yycursor += 1;
                        yystate = 136
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 90:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x30:
                    case 0x31:
                    case 0x32:
                    case 0x33:
                    case 0x34:
                    case 0x35:
                    case 0x36:
                    case 0x37:
                    case 0x38:
                    case 0x39:
                        yycursor += 1;
                        yystate = 90
                        continue yyl
                    case 0x5D:
                        yycursor += 1;
                        yystate = 137
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 91:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x45:
                    case 0x65:
                        yycursor += 1;
                        yystate = 138
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 92:
                ab = yyt2;
                ae = yyt1;
                { return ret({ kind: 'box' } as T.PsqlBox, ab,ae); }
            case 93:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x20:
                        yycursor += 1;
                        yystate = 93
                        continue yyl
                    case 0x5B:
                        yycursor += 1;
                        yystate = 94
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 94:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x30:
                    case 0x31:
                    case 0x32:
                    case 0x33:
                    case 0x34:
                    case 0x35:
                    case 0x36:
                    case 0x37:
                    case 0x38:
                    case 0x39:
                        yycursor += 1;
                        yystate = 94
                        continue yyl
                    case 0x5D:
                        yycursor += 1;
                        yystate = 139
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 95:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x41:
                    case 0x61:
                        yycursor += 1;
                        yystate = 140
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 96:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x41:
                    case 0x61:
                        yycursor += 1;
                        yystate = 141
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 97:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x00:
                        yyt1 = -1;
                        yyt2 = -1;
                        yyt3 = -1;
                        yyt4 = -1;
                        yycursor += 1;
                        yystate = 142
                        continue yyl
                    case 0x20:
                        yyt2 = -1;
                        yyt3 = -1;
                        yyt4 = yycursor;
                        yycursor += 1;
                        yystate = 143
                        continue yyl
                    case 0x28:
                        yycursor += 1;
                        yystate = 144
                        continue yyl
                    case 0x41:
                    case 0x61:
                        yycursor += 1;
                        yystate = 145
                        continue yyl
                    case 0x5B:
                        yyt2 = -1;
                        yyt3 = -1;
                        yyt4 = yycursor;
                        yycursor += 1;
                        yystate = 146
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 98:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x00:
                        yyt1 = -1;
                        yyt2 = -1;
                        yycursor += 1;
                        yystate = 147
                        continue yyl
                    case 0x20:
                        yyt2 = yycursor;
                        yycursor += 1;
                        yystate = 148
                        continue yyl
                    case 0x5B:
                        yyt2 = yycursor;
                        yycursor += 1;
                        yystate = 149
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 99:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x4C:
                    case 0x6C:
                        yycursor += 1;
                        yystate = 150
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 100:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x00:
                        yyt1 = -1;
                        yyt2 = -1;
                        yycursor += 1;
                        yystate = 151
                        continue yyl
                    case 0x20:
                        yyt2 = yycursor;
                        yycursor += 1;
                        yystate = 152
                        continue yyl
                    case 0x52:
                    case 0x72:
                        yycursor += 1;
                        yystate = 153
                        continue yyl
                    case 0x5B:
                        yyt2 = yycursor;
                        yycursor += 1;
                        yystate = 154
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 101:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x4C:
                    case 0x6C:
                        yycursor += 1;
                        yystate = 155
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 102:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x54:
                    case 0x74:
                        yycursor += 1;
                        yystate = 156
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 103:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x00:
                        yyt1 = -1;
                        yyt2 = -1;
                        yycursor += 1;
                        yystate = 157
                        continue yyl
                    case 0x20:
                        yyt2 = yycursor;
                        yycursor += 1;
                        yystate = 158
                        continue yyl
                    case 0x5B:
                        yyt2 = yycursor;
                        yycursor += 1;
                        yystate = 159
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 104:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x00:
                        yyt1 = -1;
                        yyt2 = -1;
                        yycursor += 1;
                        yystate = 160
                        continue yyl
                    case 0x20:
                        yyt2 = yycursor;
                        yycursor += 1;
                        yystate = 161
                        continue yyl
                    case 0x5B:
                        yyt2 = yycursor;
                        yycursor += 1;
                        yystate = 162
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 105:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x00:
                        yyt1 = -1;
                        yyt2 = -1;
                        yycursor += 1;
                        yystate = 163
                        continue yyl
                    case 0x20:
                        yyt2 = yycursor;
                        yycursor += 1;
                        yystate = 164
                        continue yyl
                    case 0x52:
                    case 0x72:
                        yycursor += 1;
                        yystate = 165
                        continue yyl
                    case 0x5B:
                        yyt2 = yycursor;
                        yycursor += 1;
                        yystate = 166
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 106:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x00:
                        yyt1 = -1;
                        yyt2 = -1;
                        yycursor += 1;
                        yystate = 167
                        continue yyl
                    case 0x20:
                        yyt2 = yycursor;
                        yycursor += 1;
                        yystate = 168
                        continue yyl
                    case 0x52:
                    case 0x72:
                        yycursor += 1;
                        yystate = 169
                        continue yyl
                    case 0x5B:
                        yyt2 = yycursor;
                        yycursor += 1;
                        yystate = 170
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 107:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x47:
                    case 0x67:
                        yycursor += 1;
                        yystate = 171
                        continue yyl
                    case 0x52:
                    case 0x72:
                        yycursor += 1;
                        yystate = 172
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 108:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x00:
                        yyt1 = -1;
                        yyt2 = -1;
                        yycursor += 1;
                        yystate = 173
                        continue yyl
                    case 0x20:
                        yyt2 = yycursor;
                        yycursor += 1;
                        yystate = 174
                        continue yyl
                    case 0x42:
                    case 0x62:
                        yycursor += 1;
                        yystate = 175
                        continue yyl
                    case 0x5B:
                        yyt2 = yycursor;
                        yycursor += 1;
                        yystate = 176
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 109:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x00:
                        yyt1 = -1;
                        yyt2 = -1;
                        yycursor += 1;
                        yystate = 177
                        continue yyl
                    case 0x20:
                        yyt2 = yycursor;
                        yycursor += 1;
                        yystate = 178
                        continue yyl
                    case 0x5B:
                        yyt2 = yycursor;
                        yycursor += 1;
                        yystate = 179
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 110:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x00:
                        yyt1 = -1;
                        yyt2 = -1;
                        yycursor += 1;
                        yystate = 180
                        continue yyl
                    case 0x20:
                        yyt2 = yycursor;
                        yycursor += 1;
                        yystate = 181
                        continue yyl
                    case 0x5B:
                        yyt2 = yycursor;
                        yycursor += 1;
                        yystate = 182
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 111:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x44:
                    case 0x64:
                        yycursor += 1;
                        yystate = 183
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 112:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x59:
                    case 0x79:
                        yycursor += 1;
                        yystate = 184
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 113:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x52:
                    case 0x72:
                        yycursor += 1;
                        yystate = 185
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 114:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x41:
                    case 0x61:
                        yycursor += 1;
                        yystate = 186
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 115:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x00:
                        yyt1 = -1;
                        yyt2 = -1;
                        yycursor += 1;
                        yystate = 187
                        continue yyl
                    case 0x20:
                        yyt2 = yycursor;
                        yycursor += 1;
                        yystate = 188
                        continue yyl
                    case 0x5B:
                        yyt2 = yycursor;
                        yycursor += 1;
                        yystate = 189
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 116:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x54:
                    case 0x74:
                        yycursor += 1;
                        yystate = 190
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 117:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x47:
                    case 0x67:
                        yycursor += 1;
                        yystate = 191
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 118:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x00:
                        yyt1 = -1;
                        yyt2 = -1;
                        yycursor += 1;
                        yystate = 192
                        continue yyl
                    case 0x20:
                        yyt2 = yycursor;
                        yycursor += 1;
                        yystate = 193
                        continue yyl
                    case 0x5B:
                        yyt2 = yycursor;
                        yycursor += 1;
                        yystate = 194
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 119:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x41:
                    case 0x61:
                        yycursor += 1;
                        yystate = 195
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 120:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x4C:
                    case 0x6C:
                        yycursor += 1;
                        yystate = 196
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 121:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x00:
                        yyt1 = -1;
                        yyt2 = -1;
                        yycursor += 1;
                        yystate = 197
                        continue yyl
                    case 0x20:
                        yyt2 = yycursor;
                        yycursor += 1;
                        yystate = 198
                        continue yyl
                    case 0x5B:
                        yyt2 = yycursor;
                        yycursor += 1;
                        yystate = 199
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 122:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x00:
                        yyt1 = -1;
                        yyt2 = -1;
                        yyt3 = -1;
                        yycursor += 1;
                        yystate = 200
                        continue yyl
                    case 0x20:
                        yyt2 = -1;
                        yyt3 = yycursor;
                        yyt4 = -1;
                        yycursor += 1;
                        yystate = 201
                        continue yyl
                    case 0x53:
                    case 0x73:
                        yycursor += 1;
                        yystate = 204
                        continue yyl
                    case 0x57:
                    case 0x77:
                        yyt2 = -1;
                        yyt4 = -1;
                        yycursor += 1;
                        yystate = 205
                        continue yyl
                    case 0x5B:
                        yyt2 = -1;
                        yyt3 = yycursor;
                        yycursor += 1;
                        yystate = 206
                        continue yyl
                    default:
                        yystate = 202
                        continue yyl
                }
            case 123:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x4E:
                    case 0x6E:
                        yycursor += 1;
                        yystate = 207
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 124:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x52:
                    case 0x72:
                        yycursor += 1;
                        yystate = 208
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 125:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x43:
                    case 0x63:
                        yycursor += 1;
                        yystate = 209
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 126:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x00:
                        yyt1 = -1;
                        yyt2 = -1;
                        yycursor += 1;
                        yystate = 210
                        continue yyl
                    case 0x20:
                        yyt2 = yycursor;
                        yycursor += 1;
                        yystate = 211
                        continue yyl
                    case 0x5B:
                        yyt2 = yycursor;
                        yycursor += 1;
                        yystate = 212
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 127:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x49:
                    case 0x69:
                        yycursor += 1;
                        yystate = 213
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 128:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x48:
                    case 0x68:
                        yycursor += 1;
                        yystate = 214
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 129:
                ab = yyt2;
                ae = yyt1;
                { return ret({ kind: 'xml' } as T.PsqlXml, ab,ae); }
            case 130:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x20:
                        yycursor += 1;
                        yystate = 130
                        continue yyl
                    case 0x5B:
                        yycursor += 1;
                        yystate = 131
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 131:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x30:
                    case 0x31:
                    case 0x32:
                    case 0x33:
                    case 0x34:
                    case 0x35:
                    case 0x36:
                    case 0x37:
                    case 0x38:
                    case 0x39:
                        yycursor += 1;
                        yystate = 131
                        continue yyl
                    case 0x5D:
                        yycursor += 1;
                        yystate = 215
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 132:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x22:
                        yycursor += 1;
                        yystate = 216
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 133:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x54:
                    case 0x74:
                        yycursor += 1;
                        yystate = 217
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 134:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x52:
                    case 0x72:
                        yycursor += 1;
                        yystate = 218
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 135:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x41:
                    case 0x61:
                        yycursor += 1;
                        yystate = 219
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 136:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x20:
                        yyt3 = yycursor;
                        yycursor += 1;
                        yystate = 220
                        continue yyl
                    case 0x29:
                        yyt3 = yycursor;
                        yycursor += 1;
                        yystate = 221
                        continue yyl
                    case 0x30:
                    case 0x31:
                    case 0x32:
                    case 0x33:
                    case 0x34:
                    case 0x35:
                    case 0x36:
                    case 0x37:
                    case 0x38:
                    case 0x39:
                        yycursor += 1;
                        yystate = 136
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 137:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x00:
                        yyt1 = yycursor;
                        yycursor += 1;
                        yystate = 87
                        continue yyl
                    case 0x20:
                        yycursor += 1;
                        yystate = 222
                        continue yyl
                    case 0x5B:
                        yycursor += 1;
                        yystate = 90
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 138:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x41:
                    case 0x61:
                        yycursor += 1;
                        yystate = 223
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 139:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x00:
                        yyt1 = yycursor;
                        yycursor += 1;
                        yystate = 92
                        continue yyl
                    case 0x20:
                        yycursor += 1;
                        yystate = 93
                        continue yyl
                    case 0x5B:
                        yycursor += 1;
                        yystate = 94
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 140:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x52:
                    case 0x72:
                        yycursor += 1;
                        yystate = 224
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 141:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x00:
                        yyt1 = -1;
                        yyt2 = -1;
                        yycursor += 1;
                        yystate = 225
                        continue yyl
                    case 0x20:
                        yyt2 = yycursor;
                        yycursor += 1;
                        yystate = 226
                        continue yyl
                    case 0x5B:
                        yyt2 = yycursor;
                        yycursor += 1;
                        yystate = 227
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 142:
                t1 = yyt2;
                t2 = yyt3;
                ab = yyt4;
                ae = yyt1;
                {
        if (t1 === -1) {
          return ret({ kind: 'character', size: 1 } as T.PsqlCharacter, ab,ae);
        } else {
          return ret({ kind: 'character', size: num(t1!,t2!) } as T.PsqlCharacter, ab,ae);
	}
      }
            case 143:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x20:
                        yycursor += 1;
                        yystate = 143
                        continue yyl
                    case 0x28:
                        yycursor += 1;
                        yystate = 144
                        continue yyl
                    case 0x56:
                    case 0x76:
                        yycursor += 1;
                        yystate = 228
                        continue yyl
                    case 0x5B:
                        yycursor += 1;
                        yystate = 146
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 144:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x20:
                        yycursor += 1;
                        yystate = 144
                        continue yyl
                    case 0x31:
                    case 0x32:
                    case 0x33:
                    case 0x34:
                    case 0x35:
                    case 0x36:
                    case 0x37:
                    case 0x38:
                    case 0x39:
                        yyt2 = yycursor;
                        yycursor += 1;
                        yystate = 229
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 145:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x43:
                    case 0x63:
                        yycursor += 1;
                        yystate = 230
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 146:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x30:
                    case 0x31:
                    case 0x32:
                    case 0x33:
                    case 0x34:
                    case 0x35:
                    case 0x36:
                    case 0x37:
                    case 0x38:
                    case 0x39:
                        yycursor += 1;
                        yystate = 146
                        continue yyl
                    case 0x5D:
                        yycursor += 1;
                        yystate = 231
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 147:
                ab = yyt2;
                ae = yyt1;
                { return ret({ kind: 'cidr' } as T.PsqlCidr, ab,ae); }
            case 148:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x20:
                        yycursor += 1;
                        yystate = 148
                        continue yyl
                    case 0x5B:
                        yycursor += 1;
                        yystate = 149
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 149:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x30:
                    case 0x31:
                    case 0x32:
                    case 0x33:
                    case 0x34:
                    case 0x35:
                    case 0x36:
                    case 0x37:
                    case 0x38:
                    case 0x39:
                        yycursor += 1;
                        yystate = 149
                        continue yyl
                    case 0x5D:
                        yycursor += 1;
                        yystate = 232
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 150:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x45:
                    case 0x65:
                        yycursor += 1;
                        yystate = 233
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 151:
                ab = yyt2;
                ae = yyt1;
                { return ret({ kind: 'date' } as T.PsqlDate, ab,ae); }
            case 152:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x20:
                        yycursor += 1;
                        yystate = 152
                        continue yyl
                    case 0x5B:
                        yycursor += 1;
                        yystate = 154
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 153:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x41:
                    case 0x61:
                        yycursor += 1;
                        yystate = 234
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 154:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x30:
                    case 0x31:
                    case 0x32:
                    case 0x33:
                    case 0x34:
                    case 0x35:
                    case 0x36:
                    case 0x37:
                    case 0x38:
                    case 0x39:
                        yycursor += 1;
                        yystate = 154
                        continue yyl
                    case 0x5D:
                        yycursor += 1;
                        yystate = 235
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 155:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x45:
                    case 0x65:
                        yycursor += 1;
                        yystate = 236
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 156:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x00:
                        yyt1 = -1;
                        yyt2 = -1;
                        yycursor += 1;
                        yystate = 237
                        continue yyl
                    case 0x20:
                        yyt2 = yycursor;
                        yycursor += 1;
                        yystate = 238
                        continue yyl
                    case 0x34:
                        yycursor += 1;
                        yystate = 118
                        continue yyl
                    case 0x38:
                        yycursor += 1;
                        yystate = 241
                        continue yyl
                    case 0x5B:
                        yyt2 = yycursor;
                        yycursor += 1;
                        yystate = 242
                        continue yyl
                    default:
                        yystate = 239
                        continue yyl
                }
            case 157:
                ab = yyt2;
                ae = yyt1;
                { return ret({ kind: 'inet' } as T.PsqlInet, ab,ae); }
            case 158:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x20:
                        yycursor += 1;
                        yystate = 158
                        continue yyl
                    case 0x5B:
                        yycursor += 1;
                        yystate = 159
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 159:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x30:
                    case 0x31:
                    case 0x32:
                    case 0x33:
                    case 0x34:
                    case 0x35:
                    case 0x36:
                    case 0x37:
                    case 0x38:
                    case 0x39:
                        yycursor += 1;
                        yystate = 159
                        continue yyl
                    case 0x5D:
                        yycursor += 1;
                        yystate = 243
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 160:
                ab = yyt2;
                ae = yyt1;
                { return ret({ kind: 'int', size: 2 } as T.PsqlInt, ab, ae); }
            case 161:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x20:
                        yycursor += 1;
                        yystate = 161
                        continue yyl
                    case 0x5B:
                        yycursor += 1;
                        yystate = 162
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 162:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x30:
                    case 0x31:
                    case 0x32:
                    case 0x33:
                    case 0x34:
                    case 0x35:
                    case 0x36:
                    case 0x37:
                    case 0x38:
                    case 0x39:
                        yycursor += 1;
                        yystate = 162
                        continue yyl
                    case 0x5D:
                        yycursor += 1;
                        yystate = 244
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 163:
                ab = yyt2;
                ae = yyt1;
                { return ret({ kind: 'int', size: 4 } as T.PsqlInt, ab, ae); }
            case 164:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x20:
                        yycursor += 1;
                        yystate = 164
                        continue yyl
                    case 0x5B:
                        yycursor += 1;
                        yystate = 166
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 165:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x41:
                    case 0x61:
                        yycursor += 1;
                        yystate = 245
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 166:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x30:
                    case 0x31:
                    case 0x32:
                    case 0x33:
                    case 0x34:
                    case 0x35:
                    case 0x36:
                    case 0x37:
                    case 0x38:
                    case 0x39:
                        yycursor += 1;
                        yystate = 166
                        continue yyl
                    case 0x5D:
                        yycursor += 1;
                        yystate = 246
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 167:
                ab = yyt2;
                ae = yyt1;
                { return ret({ kind: 'int', size: 8 } as T.PsqlInt, ab, ae); }
            case 168:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x20:
                        yycursor += 1;
                        yystate = 168
                        continue yyl
                    case 0x5B:
                        yycursor += 1;
                        yystate = 170
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 169:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x41:
                    case 0x61:
                        yycursor += 1;
                        yystate = 247
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 170:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x30:
                    case 0x31:
                    case 0x32:
                    case 0x33:
                    case 0x34:
                    case 0x35:
                    case 0x36:
                    case 0x37:
                    case 0x38:
                    case 0x39:
                        yycursor += 1;
                        yystate = 170
                        continue yyl
                    case 0x5D:
                        yycursor += 1;
                        yystate = 248
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 171:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x45:
                    case 0x65:
                        yycursor += 1;
                        yystate = 249
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 172:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x56:
                    case 0x76:
                        yycursor += 1;
                        yystate = 250
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 173:
                ab = yyt2;
                ae = yyt1;
                { return ret({ kind: 'json'} as T.PsqlJson, ab,ae); }
            case 174:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x20:
                        yycursor += 1;
                        yystate = 174
                        continue yyl
                    case 0x5B:
                        yycursor += 1;
                        yystate = 176
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 175:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x00:
                        yyt1 = -1;
                        yyt2 = -1;
                        yycursor += 1;
                        yystate = 251
                        continue yyl
                    case 0x20:
                        yyt2 = yycursor;
                        yycursor += 1;
                        yystate = 252
                        continue yyl
                    case 0x5B:
                        yyt2 = yycursor;
                        yycursor += 1;
                        yystate = 253
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 176:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x30:
                    case 0x31:
                    case 0x32:
                    case 0x33:
                    case 0x34:
                    case 0x35:
                    case 0x36:
                    case 0x37:
                    case 0x38:
                    case 0x39:
                        yycursor += 1;
                        yystate = 176
                        continue yyl
                    case 0x5D:
                        yycursor += 1;
                        yystate = 254
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 177:
                ab = yyt2;
                ae = yyt1;
                { return ret({ kind: 'line' } as T.PsqlLine, ab,ae); }
            case 178:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x20:
                        yycursor += 1;
                        yystate = 178
                        continue yyl
                    case 0x5B:
                        yycursor += 1;
                        yystate = 179
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 179:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x30:
                    case 0x31:
                    case 0x32:
                    case 0x33:
                    case 0x34:
                    case 0x35:
                    case 0x36:
                    case 0x37:
                    case 0x38:
                    case 0x39:
                        yycursor += 1;
                        yystate = 179
                        continue yyl
                    case 0x5D:
                        yycursor += 1;
                        yystate = 255
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 180:
                ab = yyt2;
                ae = yyt1;
                { return ret({ kind: 'lseg' } as T.PsqlLseg, ab,ae); }
            case 181:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x20:
                        yycursor += 1;
                        yystate = 181
                        continue yyl
                    case 0x5B:
                        yycursor += 1;
                        yystate = 182
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 182:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x30:
                    case 0x31:
                    case 0x32:
                    case 0x33:
                    case 0x34:
                    case 0x35:
                    case 0x36:
                    case 0x37:
                    case 0x38:
                    case 0x39:
                        yycursor += 1;
                        yystate = 182
                        continue yyl
                    case 0x5D:
                        yycursor += 1;
                        yystate = 256
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 183:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x44:
                    case 0x64:
                        yycursor += 1;
                        yystate = 257
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 184:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x00:
                        yyt1 = -1;
                        yyt2 = -1;
                        yycursor += 1;
                        yystate = 258
                        continue yyl
                    case 0x20:
                        yyt2 = yycursor;
                        yycursor += 1;
                        yystate = 259
                        continue yyl
                    case 0x5B:
                        yyt2 = yycursor;
                        yycursor += 1;
                        yystate = 260
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 185:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x49:
                    case 0x69:
                        yycursor += 1;
                        yystate = 261
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 186:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x4E:
                    case 0x6E:
                        yycursor += 1;
                        yystate = 262
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 187:
                ab = yyt2;
                ae = yyt1;
                { return ret({ kind: 'path' } as T.PsqlPath, ab,ae); }
            case 188:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x20:
                        yycursor += 1;
                        yystate = 188
                        continue yyl
                    case 0x5B:
                        yycursor += 1;
                        yystate = 189
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 189:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x30:
                    case 0x31:
                    case 0x32:
                    case 0x33:
                    case 0x34:
                    case 0x35:
                    case 0x36:
                    case 0x37:
                    case 0x38:
                    case 0x39:
                        yycursor += 1;
                        yystate = 189
                        continue yyl
                    case 0x5D:
                        yycursor += 1;
                        yystate = 263
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 190:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x00:
                        yyt1 = -1;
                        yyt2 = -1;
                        yycursor += 1;
                        yystate = 264
                        continue yyl
                    case 0x20:
                        yyt2 = yycursor;
                        yycursor += 1;
                        yystate = 265
                        continue yyl
                    case 0x5B:
                        yyt2 = yycursor;
                        yycursor += 1;
                        yystate = 266
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 191:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x4F:
                    case 0x6F:
                        yycursor += 1;
                        yystate = 267
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 192:
                ab = yyt2;
                ae = yyt1;
                { return ret({ kind: 'float', size: 4 } as T.PsqlFloat, ab, ae); }
            case 193:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x20:
                        yycursor += 1;
                        yystate = 193
                        continue yyl
                    case 0x5B:
                        yycursor += 1;
                        yystate = 194
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 194:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x30:
                    case 0x31:
                    case 0x32:
                    case 0x33:
                    case 0x34:
                    case 0x35:
                    case 0x36:
                    case 0x37:
                    case 0x38:
                    case 0x39:
                        yycursor += 1;
                        yystate = 194
                        continue yyl
                    case 0x5D:
                        yycursor += 1;
                        yystate = 268
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 195:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x4C:
                    case 0x6C:
                        yycursor += 1;
                        yystate = 269
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 196:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x49:
                    case 0x69:
                        yycursor += 1;
                        yystate = 270
                        continue yyl
                    case 0x53:
                    case 0x73:
                        yycursor += 1;
                        yystate = 271
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 197:
                ab = yyt2;
                ae = yyt1;
                { return ret({ kind: 'text' } as T.PsqlText, ab,ae); }
            case 198:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x20:
                        yycursor += 1;
                        yystate = 198
                        continue yyl
                    case 0x5B:
                        yycursor += 1;
                        yystate = 199
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 199:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x30:
                    case 0x31:
                    case 0x32:
                    case 0x33:
                    case 0x34:
                    case 0x35:
                    case 0x36:
                    case 0x37:
                    case 0x38:
                    case 0x39:
                        yycursor += 1;
                        yystate = 199
                        continue yyl
                    case 0x5D:
                        yycursor += 1;
                        yystate = 272
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 200:
                t = yyt2;
                ab = yyt3;
                ae = yyt1;
                {
         return ret({ kind: 'time', precision: t === -1 ? -1 : sec_prec(t!), withTimezone: false } as T.PsqlTime, ab,ae);
      }
            case 201:
                yych = yyinput.charCodeAt(yycursor)
                yystate = 202
                continue yyl
            case 202:
                switch (yych) {
                    case 0x20:
                        yycursor += 1;
                        yystate = 201
                        continue yyl
                    case 0x28:
                        yycursor += 1;
                        yystate = 203
                        continue yyl
                    case 0x57:
                    case 0x77:
                        yycursor += 1;
                        yystate = 205
                        continue yyl
                    case 0x5B:
                        yycursor += 1;
                        yystate = 206
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 203:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x20:
                        yycursor += 1;
                        yystate = 203
                        continue yyl
                    case 0x30:
                    case 0x31:
                    case 0x32:
                    case 0x33:
                    case 0x34:
                    case 0x35:
                    case 0x36:
                        yyt2 = yycursor;
                        yyt4 = yycursor;
                        yycursor += 1;
                        yystate = 273
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 204:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x54:
                    case 0x74:
                        yycursor += 1;
                        yystate = 274
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 205:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x49:
                    case 0x69:
                        yycursor += 1;
                        yystate = 275
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 206:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x30:
                    case 0x31:
                    case 0x32:
                    case 0x33:
                    case 0x34:
                    case 0x35:
                    case 0x36:
                    case 0x37:
                    case 0x38:
                    case 0x39:
                        yycursor += 1;
                        yystate = 206
                        continue yyl
                    case 0x5D:
                        yycursor += 1;
                        yystate = 276
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 207:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x47:
                    case 0x67:
                        yycursor += 1;
                        yystate = 277
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 208:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x41:
                    case 0x61:
                        yycursor += 1;
                        yystate = 278
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 209:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x54:
                    case 0x74:
                        yycursor += 1;
                        yystate = 279
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 210:
                ab = yyt2;
                ae = yyt1;
                { return ret({ kind: 'uuid' } as T.PsqlUuid, ab,ae); }
            case 211:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x20:
                        yycursor += 1;
                        yystate = 211
                        continue yyl
                    case 0x5B:
                        yycursor += 1;
                        yystate = 212
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 212:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x30:
                    case 0x31:
                    case 0x32:
                    case 0x33:
                    case 0x34:
                    case 0x35:
                    case 0x36:
                    case 0x37:
                    case 0x38:
                    case 0x39:
                        yycursor += 1;
                        yystate = 212
                        continue yyl
                    case 0x5D:
                        yycursor += 1;
                        yystate = 280
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 213:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x54:
                    case 0x74:
                        yycursor += 1;
                        yystate = 281
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 214:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x41:
                    case 0x61:
                        yycursor += 1;
                        yystate = 282
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 215:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x00:
                        yyt1 = yycursor;
                        yycursor += 1;
                        yystate = 129
                        continue yyl
                    case 0x20:
                        yycursor += 1;
                        yystate = 130
                        continue yyl
                    case 0x5B:
                        yycursor += 1;
                        yystate = 131
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 216:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x00:
                        yyt1 = -1;
                        yyt2 = -1;
                        yycursor += 1;
                        yystate = 283
                        continue yyl
                    case 0x20:
                        yyt2 = yycursor;
                        yycursor += 1;
                        yystate = 284
                        continue yyl
                    case 0x5B:
                        yyt2 = yycursor;
                        yycursor += 1;
                        yystate = 285
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 217:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x00:
                        yyt1 = -1;
                        yyt2 = -1;
                        yycursor += 1;
                        yystate = 167
                        continue yyl
                    case 0x20:
                        yyt2 = yycursor;
                        yycursor += 1;
                        yystate = 168
                        continue yyl
                    case 0x5B:
                        yyt2 = yycursor;
                        yycursor += 1;
                        yystate = 170
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 218:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x49:
                    case 0x69:
                        yycursor += 1;
                        yystate = 286
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 219:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x52:
                    case 0x72:
                        yycursor += 1;
                        yystate = 287
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 220:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x20:
                        yycursor += 1;
                        yystate = 220
                        continue yyl
                    case 0x29:
                        yycursor += 1;
                        yystate = 221
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 221:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x00:
                        yyt1 = -1;
                        yyt4 = -1;
                        yycursor += 1;
                        yystate = 87
                        continue yyl
                    case 0x20:
                        yyt4 = yycursor;
                        yycursor += 1;
                        yystate = 222
                        continue yyl
                    case 0x5B:
                        yyt4 = yycursor;
                        yycursor += 1;
                        yystate = 90
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 222:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x20:
                        yycursor += 1;
                        yystate = 222
                        continue yyl
                    case 0x5B:
                        yycursor += 1;
                        yystate = 90
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 223:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x4E:
                    case 0x6E:
                        yycursor += 1;
                        yystate = 288
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 224:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x00:
                        yyt1 = -1;
                        yyt2 = -1;
                        yyt3 = -1;
                        yyt4 = -1;
                        yycursor += 1;
                        yystate = 289
                        continue yyl
                    case 0x20:
                        yyt2 = -1;
                        yyt3 = -1;
                        yyt4 = yycursor;
                        yycursor += 1;
                        yystate = 290
                        continue yyl
                    case 0x5B:
                        yyt2 = -1;
                        yyt3 = -1;
                        yyt4 = yycursor;
                        yycursor += 1;
                        yystate = 293
                        continue yyl
                    default:
                        yystate = 291
                        continue yyl
                }
            case 225:
                ab = yyt2;
                ae = yyt1;
                { return ret({ kind: 'bytea' } as T.PsqlBytea, ab,ae); }
            case 226:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x20:
                        yycursor += 1;
                        yystate = 226
                        continue yyl
                    case 0x5B:
                        yycursor += 1;
                        yystate = 227
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 227:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x30:
                    case 0x31:
                    case 0x32:
                    case 0x33:
                    case 0x34:
                    case 0x35:
                    case 0x36:
                    case 0x37:
                    case 0x38:
                    case 0x39:
                        yycursor += 1;
                        yystate = 227
                        continue yyl
                    case 0x5D:
                        yycursor += 1;
                        yystate = 294
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 228:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x41:
                    case 0x61:
                        yycursor += 1;
                        yystate = 295
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 229:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x20:
                        yyt3 = yycursor;
                        yycursor += 1;
                        yystate = 296
                        continue yyl
                    case 0x29:
                        yyt3 = yycursor;
                        yycursor += 1;
                        yystate = 297
                        continue yyl
                    case 0x30:
                    case 0x31:
                    case 0x32:
                    case 0x33:
                    case 0x34:
                    case 0x35:
                    case 0x36:
                    case 0x37:
                    case 0x38:
                    case 0x39:
                        yycursor += 1;
                        yystate = 229
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 230:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x54:
                    case 0x74:
                        yycursor += 1;
                        yystate = 298
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 231:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x00:
                        yyt1 = yycursor;
                        yycursor += 1;
                        yystate = 142
                        continue yyl
                    case 0x20:
                        yycursor += 1;
                        yystate = 299
                        continue yyl
                    case 0x5B:
                        yycursor += 1;
                        yystate = 146
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 232:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x00:
                        yyt1 = yycursor;
                        yycursor += 1;
                        yystate = 147
                        continue yyl
                    case 0x20:
                        yycursor += 1;
                        yystate = 148
                        continue yyl
                    case 0x5B:
                        yycursor += 1;
                        yystate = 149
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 233:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x00:
                        yyt1 = -1;
                        yyt2 = -1;
                        yycursor += 1;
                        yystate = 300
                        continue yyl
                    case 0x20:
                        yyt2 = yycursor;
                        yycursor += 1;
                        yystate = 301
                        continue yyl
                    case 0x5B:
                        yyt2 = yycursor;
                        yycursor += 1;
                        yystate = 302
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 234:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x4E:
                    case 0x6E:
                        yycursor += 1;
                        yystate = 303
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 235:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x00:
                        yyt1 = yycursor;
                        yycursor += 1;
                        yystate = 151
                        continue yyl
                    case 0x20:
                        yycursor += 1;
                        yystate = 152
                        continue yyl
                    case 0x5B:
                        yycursor += 1;
                        yystate = 154
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 236:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x20:
                        yycursor += 1;
                        yystate = 304
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 237:
                ab = yyt2;
                ae = yyt1;
                { return ret({ kind: 'float', size: 8 } as T.PsqlFloat, ab, ae); }
            case 238:
                yych = yyinput.charCodeAt(yycursor)
                yystate = 239
                continue yyl
            case 239:
                switch (yych) {
                    case 0x20:
                        yycursor += 1;
                        yystate = 238
                        continue yyl
                    case 0x28:
                        yycursor += 1;
                        yystate = 240
                        continue yyl
                    case 0x5B:
                        yycursor += 1;
                        yystate = 242
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 240:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x20:
                        yycursor += 1;
                        yystate = 240
                        continue yyl
                    case 0x31:
                    case 0x32:
                    case 0x33:
                    case 0x34:
                    case 0x35:
                    case 0x36:
                    case 0x37:
                    case 0x38:
                    case 0x39:
                        yyt1 = yycursor;
                        yycursor += 1;
                        yystate = 305
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 241:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x00:
                        yyt1 = -1;
                        yyt2 = -1;
                        yycursor += 1;
                        yystate = 237
                        continue yyl
                    case 0x20:
                        yyt2 = yycursor;
                        yycursor += 1;
                        yystate = 306
                        continue yyl
                    case 0x5B:
                        yyt2 = yycursor;
                        yycursor += 1;
                        yystate = 242
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 242:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x30:
                    case 0x31:
                    case 0x32:
                    case 0x33:
                    case 0x34:
                    case 0x35:
                    case 0x36:
                    case 0x37:
                    case 0x38:
                    case 0x39:
                        yycursor += 1;
                        yystate = 242
                        continue yyl
                    case 0x5D:
                        yycursor += 1;
                        yystate = 307
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 243:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x00:
                        yyt1 = yycursor;
                        yycursor += 1;
                        yystate = 157
                        continue yyl
                    case 0x20:
                        yycursor += 1;
                        yystate = 158
                        continue yyl
                    case 0x5B:
                        yycursor += 1;
                        yystate = 159
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 244:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x00:
                        yyt1 = yycursor;
                        yycursor += 1;
                        yystate = 160
                        continue yyl
                    case 0x20:
                        yycursor += 1;
                        yystate = 161
                        continue yyl
                    case 0x5B:
                        yycursor += 1;
                        yystate = 162
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 245:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x4E:
                    case 0x6E:
                        yycursor += 1;
                        yystate = 308
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 246:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x00:
                        yyt1 = yycursor;
                        yycursor += 1;
                        yystate = 163
                        continue yyl
                    case 0x20:
                        yycursor += 1;
                        yystate = 164
                        continue yyl
                    case 0x5B:
                        yycursor += 1;
                        yystate = 166
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 247:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x4E:
                    case 0x6E:
                        yycursor += 1;
                        yystate = 309
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 248:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x00:
                        yyt1 = yycursor;
                        yycursor += 1;
                        yystate = 167
                        continue yyl
                    case 0x20:
                        yycursor += 1;
                        yystate = 168
                        continue yyl
                    case 0x5B:
                        yycursor += 1;
                        yystate = 170
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 249:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x52:
                    case 0x72:
                        yycursor += 1;
                        yystate = 310
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 250:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x41:
                    case 0x61:
                        yycursor += 1;
                        yystate = 311
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 251:
                ab = yyt2;
                ae = yyt1;
                { return ret({ kind: 'jsonb' } as T.PsqlJsonb, ab,ae); }
            case 252:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x20:
                        yycursor += 1;
                        yystate = 252
                        continue yyl
                    case 0x5B:
                        yycursor += 1;
                        yystate = 253
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 253:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x30:
                    case 0x31:
                    case 0x32:
                    case 0x33:
                    case 0x34:
                    case 0x35:
                    case 0x36:
                    case 0x37:
                    case 0x38:
                    case 0x39:
                        yycursor += 1;
                        yystate = 253
                        continue yyl
                    case 0x5D:
                        yycursor += 1;
                        yystate = 312
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 254:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x00:
                        yyt1 = yycursor;
                        yycursor += 1;
                        yystate = 173
                        continue yyl
                    case 0x20:
                        yycursor += 1;
                        yystate = 174
                        continue yyl
                    case 0x5B:
                        yycursor += 1;
                        yystate = 176
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 255:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x00:
                        yyt1 = yycursor;
                        yycursor += 1;
                        yystate = 177
                        continue yyl
                    case 0x20:
                        yycursor += 1;
                        yystate = 178
                        continue yyl
                    case 0x5B:
                        yycursor += 1;
                        yystate = 179
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 256:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x00:
                        yyt1 = yycursor;
                        yycursor += 1;
                        yystate = 180
                        continue yyl
                    case 0x20:
                        yycursor += 1;
                        yystate = 181
                        continue yyl
                    case 0x5B:
                        yycursor += 1;
                        yystate = 182
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 257:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x52:
                    case 0x72:
                        yycursor += 1;
                        yystate = 313
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 258:
                ab = yyt2;
                ae = yyt1;
                { return ret({ kind: 'money' } as T.PsqlMoney, ab,ae); }
            case 259:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x20:
                        yycursor += 1;
                        yystate = 259
                        continue yyl
                    case 0x5B:
                        yycursor += 1;
                        yystate = 260
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 260:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x30:
                    case 0x31:
                    case 0x32:
                    case 0x33:
                    case 0x34:
                    case 0x35:
                    case 0x36:
                    case 0x37:
                    case 0x38:
                    case 0x39:
                        yycursor += 1;
                        yystate = 260
                        continue yyl
                    case 0x5D:
                        yycursor += 1;
                        yystate = 314
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 261:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x43:
                    case 0x63:
                        yycursor += 1;
                        yystate = 315
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 262:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x47:
                    case 0x67:
                        yycursor += 1;
                        yystate = 316
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 263:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x00:
                        yyt1 = yycursor;
                        yycursor += 1;
                        yystate = 187
                        continue yyl
                    case 0x20:
                        yycursor += 1;
                        yystate = 188
                        continue yyl
                    case 0x5B:
                        yycursor += 1;
                        yystate = 189
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 264:
                ab = yyt2;
                ae = yyt1;
                { return ret({ kind: 'point' } as T.PsqlPoint, ab,ae); }
            case 265:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x20:
                        yycursor += 1;
                        yystate = 265
                        continue yyl
                    case 0x5B:
                        yycursor += 1;
                        yystate = 266
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 266:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x30:
                    case 0x31:
                    case 0x32:
                    case 0x33:
                    case 0x34:
                    case 0x35:
                    case 0x36:
                    case 0x37:
                    case 0x38:
                    case 0x39:
                        yycursor += 1;
                        yystate = 266
                        continue yyl
                    case 0x5D:
                        yycursor += 1;
                        yystate = 317
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 267:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x4E:
                    case 0x6E:
                        yycursor += 1;
                        yystate = 318
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 268:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x00:
                        yyt1 = yycursor;
                        yycursor += 1;
                        yystate = 192
                        continue yyl
                    case 0x20:
                        yycursor += 1;
                        yystate = 193
                        continue yyl
                    case 0x5B:
                        yycursor += 1;
                        yystate = 194
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 269:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x00:
                        yyt1 = -1;
                        yyt2 = -1;
                        yycursor += 1;
                        yystate = 319
                        continue yyl
                    case 0x20:
                        yyt2 = yycursor;
                        yycursor += 1;
                        yystate = 320
                        continue yyl
                    case 0x32:
                        yycursor += 1;
                        yystate = 321
                        continue yyl
                    case 0x34:
                        yycursor += 1;
                        yystate = 322
                        continue yyl
                    case 0x38:
                        yycursor += 1;
                        yystate = 323
                        continue yyl
                    case 0x5B:
                        yyt2 = yycursor;
                        yycursor += 1;
                        yystate = 324
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 270:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x4E:
                    case 0x6E:
                        yycursor += 1;
                        yystate = 325
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 271:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x45:
                    case 0x65:
                        yycursor += 1;
                        yystate = 326
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 272:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x00:
                        yyt1 = yycursor;
                        yycursor += 1;
                        yystate = 197
                        continue yyl
                    case 0x20:
                        yycursor += 1;
                        yystate = 198
                        continue yyl
                    case 0x5B:
                        yycursor += 1;
                        yystate = 199
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 273:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x20:
                        yycursor += 1;
                        yystate = 273
                        continue yyl
                    case 0x29:
                        yycursor += 1;
                        yystate = 327
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 274:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x41:
                    case 0x61:
                        yycursor += 1;
                        yystate = 328
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 275:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x54:
                    case 0x74:
                        yycursor += 1;
                        yystate = 329
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 276:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x00:
                        yyt1 = yycursor;
                        yycursor += 1;
                        yystate = 200
                        continue yyl
                    case 0x20:
                        yycursor += 1;
                        yystate = 330
                        continue yyl
                    case 0x5B:
                        yycursor += 1;
                        yystate = 206
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 277:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x45:
                    case 0x65:
                        yycursor += 1;
                        yystate = 331
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 278:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x4E:
                    case 0x6E:
                        yycursor += 1;
                        yystate = 332
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 279:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x4F:
                    case 0x6F:
                        yycursor += 1;
                        yystate = 333
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 280:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x00:
                        yyt1 = yycursor;
                        yycursor += 1;
                        yystate = 210
                        continue yyl
                    case 0x20:
                        yycursor += 1;
                        yystate = 211
                        continue yyl
                    case 0x5B:
                        yycursor += 1;
                        yystate = 212
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 281:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x00:
                        yyt1 = -1;
                        yyt2 = -1;
                        yyt3 = -1;
                        yyt4 = -1;
                        yycursor += 1;
                        yystate = 334
                        continue yyl
                    case 0x20:
                        yyt2 = -1;
                        yyt3 = -1;
                        yyt4 = yycursor;
                        yycursor += 1;
                        yystate = 335
                        continue yyl
                    case 0x5B:
                        yyt2 = -1;
                        yyt3 = -1;
                        yyt4 = yycursor;
                        yycursor += 1;
                        yystate = 338
                        continue yyl
                    default:
                        yystate = 336
                        continue yyl
                }
            case 282:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x52:
                    case 0x72:
                        yycursor += 1;
                        yystate = 339
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 283:
                ab = yyt2;
                ae = yyt1;
                { return ret({ kind: 'char' } as T.PsqlChar, ab,ae); }
            case 284:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x20:
                        yycursor += 1;
                        yystate = 284
                        continue yyl
                    case 0x5B:
                        yycursor += 1;
                        yystate = 285
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 285:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x30:
                    case 0x31:
                    case 0x32:
                    case 0x33:
                    case 0x34:
                    case 0x35:
                    case 0x36:
                    case 0x37:
                    case 0x38:
                    case 0x39:
                        yycursor += 1;
                        yystate = 285
                        continue yyl
                    case 0x5D:
                        yycursor += 1;
                        yystate = 340
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 286:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x41:
                    case 0x61:
                        yycursor += 1;
                        yystate = 341
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 287:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x59:
                    case 0x79:
                        yycursor += 1;
                        yystate = 342
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 288:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x00:
                        yyt1 = -1;
                        yyt2 = -1;
                        yycursor += 1;
                        yystate = 343
                        continue yyl
                    case 0x20:
                        yyt2 = yycursor;
                        yycursor += 1;
                        yystate = 344
                        continue yyl
                    case 0x5B:
                        yyt2 = yycursor;
                        yycursor += 1;
                        yystate = 345
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 289:
                t1 = yyt2;
                t2 = yyt3;
                ab = yyt4;
                ae = yyt1;
                {
        if (t1 === -1) {
          return ret({ kind: 'bpchar' } as T.PsqlBpchar, ab,ae);
        } else {
          return ret({ kind: 'bpchar', size: num(t1!,t2!) } as T.PsqlBpchar, ab,ae);
	}
      }
            case 290:
                yych = yyinput.charCodeAt(yycursor)
                yystate = 291
                continue yyl
            case 291:
                switch (yych) {
                    case 0x20:
                        yycursor += 1;
                        yystate = 290
                        continue yyl
                    case 0x28:
                        yycursor += 1;
                        yystate = 292
                        continue yyl
                    case 0x5B:
                        yycursor += 1;
                        yystate = 293
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 292:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x20:
                        yycursor += 1;
                        yystate = 292
                        continue yyl
                    case 0x31:
                    case 0x32:
                    case 0x33:
                    case 0x34:
                    case 0x35:
                    case 0x36:
                    case 0x37:
                    case 0x38:
                    case 0x39:
                        yyt2 = yycursor;
                        yycursor += 1;
                        yystate = 346
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 293:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x30:
                    case 0x31:
                    case 0x32:
                    case 0x33:
                    case 0x34:
                    case 0x35:
                    case 0x36:
                    case 0x37:
                    case 0x38:
                    case 0x39:
                        yycursor += 1;
                        yystate = 293
                        continue yyl
                    case 0x5D:
                        yycursor += 1;
                        yystate = 347
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 294:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x00:
                        yyt1 = yycursor;
                        yycursor += 1;
                        yystate = 225
                        continue yyl
                    case 0x20:
                        yycursor += 1;
                        yystate = 226
                        continue yyl
                    case 0x5B:
                        yycursor += 1;
                        yystate = 227
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 295:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x52:
                    case 0x72:
                        yycursor += 1;
                        yystate = 348
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 296:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x20:
                        yycursor += 1;
                        yystate = 296
                        continue yyl
                    case 0x29:
                        yycursor += 1;
                        yystate = 297
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 297:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x00:
                        yyt1 = -1;
                        yyt4 = -1;
                        yycursor += 1;
                        yystate = 142
                        continue yyl
                    case 0x20:
                        yyt4 = yycursor;
                        yycursor += 1;
                        yystate = 299
                        continue yyl
                    case 0x5B:
                        yyt4 = yycursor;
                        yycursor += 1;
                        yystate = 146
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 298:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x45:
                    case 0x65:
                        yycursor += 1;
                        yystate = 349
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 299:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x20:
                        yycursor += 1;
                        yystate = 299
                        continue yyl
                    case 0x5B:
                        yycursor += 1;
                        yystate = 146
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 300:
                ab = yyt2;
                ae = yyt1;
                { return ret({ kind: 'circle' } as T.PsqlCircle, ab,ae); }
            case 301:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x20:
                        yycursor += 1;
                        yystate = 301
                        continue yyl
                    case 0x5B:
                        yycursor += 1;
                        yystate = 302
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 302:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x30:
                    case 0x31:
                    case 0x32:
                    case 0x33:
                    case 0x34:
                    case 0x35:
                    case 0x36:
                    case 0x37:
                    case 0x38:
                    case 0x39:
                        yycursor += 1;
                        yystate = 302
                        continue yyl
                    case 0x5D:
                        yycursor += 1;
                        yystate = 350
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 303:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x47:
                    case 0x67:
                        yycursor += 1;
                        yystate = 351
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 304:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x50:
                    case 0x70:
                        yycursor += 1;
                        yystate = 352
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 305:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x20:
                        yyt2 = yycursor;
                        yycursor += 1;
                        yystate = 353
                        continue yyl
                    case 0x29:
                        yyt2 = yycursor;
                        yycursor += 1;
                        yystate = 354
                        continue yyl
                    case 0x30:
                    case 0x31:
                    case 0x32:
                    case 0x33:
                    case 0x34:
                    case 0x35:
                    case 0x36:
                    case 0x37:
                    case 0x38:
                    case 0x39:
                        yycursor += 1;
                        yystate = 305
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 306:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x20:
                        yycursor += 1;
                        yystate = 306
                        continue yyl
                    case 0x5B:
                        yycursor += 1;
                        yystate = 242
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 307:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x00:
                        yyt1 = yycursor;
                        yycursor += 1;
                        yystate = 237
                        continue yyl
                    case 0x20:
                        yycursor += 1;
                        yystate = 306
                        continue yyl
                    case 0x5B:
                        yycursor += 1;
                        yystate = 242
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 308:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x47:
                    case 0x67:
                        yycursor += 1;
                        yystate = 355
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 309:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x47:
                    case 0x67:
                        yycursor += 1;
                        yystate = 356
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 310:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x00:
                        yyt1 = -1;
                        yyt2 = -1;
                        yycursor += 1;
                        yystate = 163
                        continue yyl
                    case 0x20:
                        yyt2 = yycursor;
                        yycursor += 1;
                        yystate = 164
                        continue yyl
                    case 0x5B:
                        yyt2 = yycursor;
                        yycursor += 1;
                        yystate = 166
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 311:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x4C:
                    case 0x6C:
                        yycursor += 1;
                        yystate = 357
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 312:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x00:
                        yyt1 = yycursor;
                        yycursor += 1;
                        yystate = 251
                        continue yyl
                    case 0x20:
                        yycursor += 1;
                        yystate = 252
                        continue yyl
                    case 0x5B:
                        yycursor += 1;
                        yystate = 253
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 313:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x00:
                        yyt1 = -1;
                        yyt2 = -1;
                        yycursor += 1;
                        yystate = 358
                        continue yyl
                    case 0x20:
                        yyt2 = yycursor;
                        yycursor += 1;
                        yystate = 359
                        continue yyl
                    case 0x38:
                        yycursor += 1;
                        yystate = 360
                        continue yyl
                    case 0x5B:
                        yyt2 = yycursor;
                        yycursor += 1;
                        yystate = 361
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 314:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x00:
                        yyt1 = yycursor;
                        yycursor += 1;
                        yystate = 258
                        continue yyl
                    case 0x20:
                        yycursor += 1;
                        yystate = 259
                        continue yyl
                    case 0x5B:
                        yycursor += 1;
                        yystate = 260
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 315:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x00:
                        yyt1 = -1;
                        yyt2 = -1;
                        yyt3 = -1;
                        yyt4 = -1;
                        yyt5 = -1;
                        yyt6 = -1;
                        yycursor += 1;
                        yystate = 362
                        continue yyl
                    case 0x20:
                        yyt2 = -1;
                        yyt3 = -1;
                        yyt4 = -1;
                        yyt5 = -1;
                        yyt6 = yycursor;
                        yycursor += 1;
                        yystate = 363
                        continue yyl
                    case 0x5B:
                        yyt2 = -1;
                        yyt3 = -1;
                        yyt4 = -1;
                        yyt5 = -1;
                        yyt6 = yycursor;
                        yycursor += 1;
                        yystate = 366
                        continue yyl
                    default:
                        yystate = 364
                        continue yyl
                }
            case 316:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x45:
                    case 0x65:
                        yycursor += 1;
                        yystate = 367
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 317:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x00:
                        yyt1 = yycursor;
                        yycursor += 1;
                        yystate = 264
                        continue yyl
                    case 0x20:
                        yycursor += 1;
                        yystate = 265
                        continue yyl
                    case 0x5B:
                        yycursor += 1;
                        yystate = 266
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 318:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x00:
                        yyt1 = -1;
                        yyt2 = -1;
                        yycursor += 1;
                        yystate = 368
                        continue yyl
                    case 0x20:
                        yyt2 = yycursor;
                        yycursor += 1;
                        yystate = 369
                        continue yyl
                    case 0x5B:
                        yyt2 = yycursor;
                        yycursor += 1;
                        yystate = 370
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 319:
                ab = yyt2;
                ae = yyt1;
                { return ret({ kind: 'serial', size: 4 } as T.PsqlSerial, ab,ae); }
            case 320:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x20:
                        yycursor += 1;
                        yystate = 320
                        continue yyl
                    case 0x5B:
                        yycursor += 1;
                        yystate = 324
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 321:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x00:
                        yyt1 = -1;
                        yyt2 = -1;
                        yycursor += 1;
                        yystate = 371
                        continue yyl
                    case 0x20:
                        yyt2 = yycursor;
                        yycursor += 1;
                        yystate = 372
                        continue yyl
                    case 0x5B:
                        yyt2 = yycursor;
                        yycursor += 1;
                        yystate = 373
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 322:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x00:
                        yyt1 = -1;
                        yyt2 = -1;
                        yycursor += 1;
                        yystate = 319
                        continue yyl
                    case 0x20:
                        yyt2 = yycursor;
                        yycursor += 1;
                        yystate = 320
                        continue yyl
                    case 0x5B:
                        yyt2 = yycursor;
                        yycursor += 1;
                        yystate = 324
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 323:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x00:
                        yyt1 = -1;
                        yyt2 = -1;
                        yycursor += 1;
                        yystate = 374
                        continue yyl
                    case 0x20:
                        yyt2 = yycursor;
                        yycursor += 1;
                        yystate = 375
                        continue yyl
                    case 0x5B:
                        yyt2 = yycursor;
                        yycursor += 1;
                        yystate = 376
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 324:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x30:
                    case 0x31:
                    case 0x32:
                    case 0x33:
                    case 0x34:
                    case 0x35:
                    case 0x36:
                    case 0x37:
                    case 0x38:
                    case 0x39:
                        yycursor += 1;
                        yystate = 324
                        continue yyl
                    case 0x5D:
                        yycursor += 1;
                        yystate = 377
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 325:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x54:
                    case 0x74:
                        yycursor += 1;
                        yystate = 104
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 326:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x52:
                    case 0x72:
                        yycursor += 1;
                        yystate = 378
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 327:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x00:
                        yyt1 = -1;
                        yyt3 = -1;
                        yycursor += 1;
                        yystate = 200
                        continue yyl
                    case 0x20:
                        yyt3 = yycursor;
                        yycursor += 1;
                        yystate = 379
                        continue yyl
                    case 0x5B:
                        yyt3 = yycursor;
                        yycursor += 1;
                        yystate = 206
                        continue yyl
                    default:
                        yystate = 380
                        continue yyl
                }
            case 328:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x4D:
                    case 0x6D:
                        yycursor += 1;
                        yystate = 381
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 329:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x48:
                    case 0x68:
                        yycursor += 1;
                        yystate = 382
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 330:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x20:
                        yycursor += 1;
                        yystate = 330
                        continue yyl
                    case 0x5B:
                        yycursor += 1;
                        yystate = 206
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 331:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x00:
                        yyt1 = -1;
                        yyt2 = -1;
                        yycursor += 1;
                        yystate = 383
                        continue yyl
                    case 0x20:
                        yyt2 = yycursor;
                        yycursor += 1;
                        yystate = 384
                        continue yyl
                    case 0x5B:
                        yyt2 = yycursor;
                        yycursor += 1;
                        yystate = 385
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 332:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x47:
                    case 0x67:
                        yycursor += 1;
                        yystate = 386
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 333:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x52:
                    case 0x72:
                        yycursor += 1;
                        yystate = 387
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 334:
                t1 = yyt2;
                t2 = yyt3;
                ab = yyt4;
                ae = yyt1;
                {
        if (t1 === -1) {
          return ret({ kind: 'varbits', size: -1 } as T.PsqlVarbits, ab,ae);
        } else {
          return ret({ kind: 'varbits', size: num(t1!,t2!) } as T.PsqlVarbits, ab,ae);
	}
      }
            case 335:
                yych = yyinput.charCodeAt(yycursor)
                yystate = 336
                continue yyl
            case 336:
                switch (yych) {
                    case 0x20:
                        yycursor += 1;
                        yystate = 335
                        continue yyl
                    case 0x28:
                        yycursor += 1;
                        yystate = 337
                        continue yyl
                    case 0x5B:
                        yycursor += 1;
                        yystate = 338
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 337:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x20:
                        yycursor += 1;
                        yystate = 337
                        continue yyl
                    case 0x31:
                    case 0x32:
                    case 0x33:
                    case 0x34:
                    case 0x35:
                    case 0x36:
                    case 0x37:
                    case 0x38:
                    case 0x39:
                        yyt2 = yycursor;
                        yycursor += 1;
                        yystate = 388
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 338:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x30:
                    case 0x31:
                    case 0x32:
                    case 0x33:
                    case 0x34:
                    case 0x35:
                    case 0x36:
                    case 0x37:
                    case 0x38:
                    case 0x39:
                        yycursor += 1;
                        yystate = 338
                        continue yyl
                    case 0x5D:
                        yycursor += 1;
                        yystate = 389
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 339:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x00:
                        yyt1 = -1;
                        yyt2 = -1;
                        yyt3 = -1;
                        yyt4 = -1;
                        yycursor += 1;
                        yystate = 390
                        continue yyl
                    case 0x20:
                        yyt2 = -1;
                        yyt3 = -1;
                        yyt4 = yycursor;
                        yycursor += 1;
                        yystate = 391
                        continue yyl
                    case 0x5B:
                        yyt2 = -1;
                        yyt3 = -1;
                        yyt4 = yycursor;
                        yycursor += 1;
                        yystate = 394
                        continue yyl
                    default:
                        yystate = 392
                        continue yyl
                }
            case 340:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x00:
                        yyt1 = yycursor;
                        yycursor += 1;
                        yystate = 283
                        continue yyl
                    case 0x20:
                        yycursor += 1;
                        yystate = 284
                        continue yyl
                    case 0x5B:
                        yycursor += 1;
                        yystate = 285
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 341:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x4C:
                    case 0x6C:
                        yycursor += 1;
                        yystate = 323
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 342:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x49:
                    case 0x69:
                        yycursor += 1;
                        yystate = 395
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 343:
                ab = yyt2;
                ae = yyt1;
                { return ret({ kind: 'boolean' } as T.PsqlBoolean, ab,ae); }
            case 344:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x20:
                        yycursor += 1;
                        yystate = 344
                        continue yyl
                    case 0x5B:
                        yycursor += 1;
                        yystate = 345
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 345:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x30:
                    case 0x31:
                    case 0x32:
                    case 0x33:
                    case 0x34:
                    case 0x35:
                    case 0x36:
                    case 0x37:
                    case 0x38:
                    case 0x39:
                        yycursor += 1;
                        yystate = 345
                        continue yyl
                    case 0x5D:
                        yycursor += 1;
                        yystate = 396
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 346:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x20:
                        yyt3 = yycursor;
                        yycursor += 1;
                        yystate = 397
                        continue yyl
                    case 0x29:
                        yyt3 = yycursor;
                        yycursor += 1;
                        yystate = 398
                        continue yyl
                    case 0x30:
                    case 0x31:
                    case 0x32:
                    case 0x33:
                    case 0x34:
                    case 0x35:
                    case 0x36:
                    case 0x37:
                    case 0x38:
                    case 0x39:
                        yycursor += 1;
                        yystate = 346
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 347:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x00:
                        yyt1 = yycursor;
                        yycursor += 1;
                        yystate = 289
                        continue yyl
                    case 0x20:
                        yycursor += 1;
                        yystate = 399
                        continue yyl
                    case 0x5B:
                        yycursor += 1;
                        yystate = 293
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 348:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x49:
                    case 0x69:
                        yycursor += 1;
                        yystate = 400
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 349:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x52:
                    case 0x72:
                        yycursor += 1;
                        yystate = 401
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 350:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x00:
                        yyt1 = yycursor;
                        yycursor += 1;
                        yystate = 300
                        continue yyl
                    case 0x20:
                        yycursor += 1;
                        yystate = 301
                        continue yyl
                    case 0x5B:
                        yycursor += 1;
                        yystate = 302
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 351:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x45:
                    case 0x65:
                        yycursor += 1;
                        yystate = 402
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 352:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x52:
                    case 0x72:
                        yycursor += 1;
                        yystate = 403
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 353:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x20:
                        yycursor += 1;
                        yystate = 353
                        continue yyl
                    case 0x29:
                        yycursor += 1;
                        yystate = 354
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 354:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x00:
                        yyt3 = -1;
                        yyt4 = -1;
                        yycursor += 1;
                        yystate = 404
                        continue yyl
                    case 0x20:
                        yyt4 = yycursor;
                        yycursor += 1;
                        yystate = 405
                        continue yyl
                    case 0x5B:
                        yyt4 = yycursor;
                        yycursor += 1;
                        yystate = 406
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 355:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x45:
                    case 0x65:
                        yycursor += 1;
                        yystate = 407
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 356:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x45:
                    case 0x65:
                        yycursor += 1;
                        yystate = 408
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 357:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x00:
                        yyt1 = -1;
                        yyt2 = -1;
                        yyt3 = -1;
                        yyt4 = -1;
                        yyt5 = -1;
                        yyt6 = -1;
                        yyt7 = -1;
                        yyt8 = -1;
                        yyt9 = -1;
                        yyt10 = -1;
                        yyt11 = -1;
                        yyt12 = -1;
                        yyt13 = -1;
                        yyt14 = -1;
                        yyt15 = -1;
                        yyt16 = -1;
                        yycursor += 1;
                        yystate = 409
                        continue yyl
                    case 0x20:
                        yyt2 = -1;
                        yyt3 = -1;
                        yyt4 = -1;
                        yyt5 = -1;
                        yyt6 = -1;
                        yyt7 = -1;
                        yyt8 = -1;
                        yyt9 = -1;
                        yyt10 = -1;
                        yyt11 = -1;
                        yyt12 = -1;
                        yyt13 = -1;
                        yyt14 = -1;
                        yyt15 = -1;
                        yyt16 = yycursor;
                        yycursor += 1;
                        yystate = 410
                        continue yyl
                    case 0x28:
                        yyt2 = -1;
                        yyt3 = -1;
                        yyt4 = -1;
                        yyt5 = -1;
                        yyt6 = -1;
                        yyt7 = -1;
                        yyt8 = -1;
                        yyt9 = -1;
                        yyt10 = -1;
                        yyt11 = -1;
                        yyt12 = -1;
                        yyt13 = -1;
                        yyt14 = -1;
                        yycursor += 1;
                        yystate = 411
                        continue yyl
                    case 0x5B:
                        yyt2 = -1;
                        yyt3 = -1;
                        yyt4 = -1;
                        yyt5 = -1;
                        yyt6 = -1;
                        yyt7 = -1;
                        yyt8 = -1;
                        yyt9 = -1;
                        yyt10 = -1;
                        yyt11 = -1;
                        yyt12 = -1;
                        yyt13 = -1;
                        yyt14 = -1;
                        yyt15 = -1;
                        yyt16 = yycursor;
                        yycursor += 1;
                        yystate = 412
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 358:
                ab = yyt2;
                ae = yyt1;
                { return ret({ kind: 'macaddr' } as T.PsqlMacaddr, ab,ae); }
            case 359:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x20:
                        yycursor += 1;
                        yystate = 359
                        continue yyl
                    case 0x5B:
                        yycursor += 1;
                        yystate = 361
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 360:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x00:
                        yyt1 = -1;
                        yyt2 = -1;
                        yycursor += 1;
                        yystate = 413
                        continue yyl
                    case 0x20:
                        yyt2 = yycursor;
                        yycursor += 1;
                        yystate = 414
                        continue yyl
                    case 0x5B:
                        yyt2 = yycursor;
                        yycursor += 1;
                        yystate = 415
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 361:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x30:
                    case 0x31:
                    case 0x32:
                    case 0x33:
                    case 0x34:
                    case 0x35:
                    case 0x36:
                    case 0x37:
                    case 0x38:
                    case 0x39:
                        yycursor += 1;
                        yystate = 361
                        continue yyl
                    case 0x5D:
                        yycursor += 1;
                        yystate = 416
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 362:
                t1 = yyt2;
                t2 = yyt3;
                t3 = yyt4;
                t4 = yyt5;
                ab = yyt6;
                ae = yyt1;
                {
         if(t1 === -1) {
	   return ret({ kind: 'numeric', precision: 0, scale: 0 } as T.PsqlNumeric, ab,ae);
	 } else if(t3 === -1) {
	   return ret({ kind: 'numeric', precision: num(t1!,t2!), scale: 0 } as T.PsqlNumeric, ab,ae);
	 } else {
	   return ret({ kind: 'numeric', precision: num(t1!,t2!), scale: num(t3!,t4!) } as T.PsqlNumeric, ab,ae);
	 }
      }
            case 363:
                yych = yyinput.charCodeAt(yycursor)
                yystate = 364
                continue yyl
            case 364:
                switch (yych) {
                    case 0x20:
                        yycursor += 1;
                        yystate = 363
                        continue yyl
                    case 0x28:
                        yycursor += 1;
                        yystate = 365
                        continue yyl
                    case 0x5B:
                        yycursor += 1;
                        yystate = 366
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 365:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x31:
                    case 0x32:
                    case 0x33:
                    case 0x34:
                    case 0x35:
                    case 0x36:
                    case 0x37:
                    case 0x38:
                    case 0x39:
                        yyt2 = yycursor;
                        yycursor += 1;
                        yystate = 417
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 366:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x30:
                    case 0x31:
                    case 0x32:
                    case 0x33:
                    case 0x34:
                    case 0x35:
                    case 0x36:
                    case 0x37:
                    case 0x38:
                    case 0x39:
                        yycursor += 1;
                        yystate = 366
                        continue yyl
                    case 0x5D:
                        yycursor += 1;
                        yystate = 418
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 367:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x00:
                        yyt1 = -1;
                        yyt2 = -1;
                        yycursor += 1;
                        yystate = 419
                        continue yyl
                    case 0x20:
                        yyt2 = yycursor;
                        yycursor += 1;
                        yystate = 420
                        continue yyl
                    case 0x5B:
                        yyt2 = yycursor;
                        yycursor += 1;
                        yystate = 421
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 368:
                ab = yyt2;
                ae = yyt1;
                { return ret({ kind: 'polygon' } as T.PsqlPolygon, ab,ae); }
            case 369:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x20:
                        yycursor += 1;
                        yystate = 369
                        continue yyl
                    case 0x5B:
                        yycursor += 1;
                        yystate = 370
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 370:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x30:
                    case 0x31:
                    case 0x32:
                    case 0x33:
                    case 0x34:
                    case 0x35:
                    case 0x36:
                    case 0x37:
                    case 0x38:
                    case 0x39:
                        yycursor += 1;
                        yystate = 370
                        continue yyl
                    case 0x5D:
                        yycursor += 1;
                        yystate = 422
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 371:
                ab = yyt2;
                ae = yyt1;
                { return ret({ kind: 'serial', size: 2 } as T.PsqlSerial, ab,ae); }
            case 372:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x20:
                        yycursor += 1;
                        yystate = 372
                        continue yyl
                    case 0x5B:
                        yycursor += 1;
                        yystate = 373
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 373:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x30:
                    case 0x31:
                    case 0x32:
                    case 0x33:
                    case 0x34:
                    case 0x35:
                    case 0x36:
                    case 0x37:
                    case 0x38:
                    case 0x39:
                        yycursor += 1;
                        yystate = 373
                        continue yyl
                    case 0x5D:
                        yycursor += 1;
                        yystate = 423
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 374:
                ab = yyt2;
                ae = yyt1;
                { return ret({ kind: 'serial', size: 8 } as T.PsqlSerial, ab,ae); }
            case 375:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x20:
                        yycursor += 1;
                        yystate = 375
                        continue yyl
                    case 0x5B:
                        yycursor += 1;
                        yystate = 376
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 376:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x30:
                    case 0x31:
                    case 0x32:
                    case 0x33:
                    case 0x34:
                    case 0x35:
                    case 0x36:
                    case 0x37:
                    case 0x38:
                    case 0x39:
                        yycursor += 1;
                        yystate = 376
                        continue yyl
                    case 0x5D:
                        yycursor += 1;
                        yystate = 424
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 377:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x00:
                        yyt1 = yycursor;
                        yycursor += 1;
                        yystate = 319
                        continue yyl
                    case 0x20:
                        yycursor += 1;
                        yystate = 320
                        continue yyl
                    case 0x5B:
                        yycursor += 1;
                        yystate = 324
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 378:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x49:
                    case 0x69:
                        yycursor += 1;
                        yystate = 425
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 379:
                yych = yyinput.charCodeAt(yycursor)
                yystate = 380
                continue yyl
            case 380:
                switch (yych) {
                    case 0x20:
                        yycursor += 1;
                        yystate = 379
                        continue yyl
                    case 0x57:
                    case 0x77:
                        yycursor += 1;
                        yystate = 205
                        continue yyl
                    case 0x5B:
                        yycursor += 1;
                        yystate = 206
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 381:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x50:
                    case 0x70:
                        yycursor += 1;
                        yystate = 426
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 382:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x20:
                        yycursor += 1;
                        yystate = 427
                        continue yyl
                    case 0x4F:
                    case 0x6F:
                        yycursor += 1;
                        yystate = 428
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 383:
                ab = yyt2;
                ae = yyt1;
                { return ret({ kind: 'tsrange' } as T.PsqlTsrange, ab,ae); }
            case 384:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x20:
                        yycursor += 1;
                        yystate = 384
                        continue yyl
                    case 0x5B:
                        yycursor += 1;
                        yystate = 385
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 385:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x30:
                    case 0x31:
                    case 0x32:
                    case 0x33:
                    case 0x34:
                    case 0x35:
                    case 0x36:
                    case 0x37:
                    case 0x38:
                    case 0x39:
                        yycursor += 1;
                        yystate = 385
                        continue yyl
                    case 0x5D:
                        yycursor += 1;
                        yystate = 429
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 386:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x45:
                    case 0x65:
                        yycursor += 1;
                        yystate = 430
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 387:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x00:
                        yyt1 = -1;
                        yyt2 = -1;
                        yycursor += 1;
                        yystate = 431
                        continue yyl
                    case 0x20:
                        yyt2 = yycursor;
                        yycursor += 1;
                        yystate = 432
                        continue yyl
                    case 0x5B:
                        yyt2 = yycursor;
                        yycursor += 1;
                        yystate = 433
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 388:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x20:
                        yyt3 = yycursor;
                        yycursor += 1;
                        yystate = 434
                        continue yyl
                    case 0x29:
                        yyt3 = yycursor;
                        yycursor += 1;
                        yystate = 435
                        continue yyl
                    case 0x30:
                    case 0x31:
                    case 0x32:
                    case 0x33:
                    case 0x34:
                    case 0x35:
                    case 0x36:
                    case 0x37:
                    case 0x38:
                    case 0x39:
                        yycursor += 1;
                        yystate = 388
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 389:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x00:
                        yyt1 = yycursor;
                        yycursor += 1;
                        yystate = 334
                        continue yyl
                    case 0x20:
                        yycursor += 1;
                        yystate = 436
                        continue yyl
                    case 0x5B:
                        yycursor += 1;
                        yystate = 338
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 390:
                t1 = yyt2;
                t2 = yyt3;
                ab = yyt4;
                ae = yyt1;
                {
        if (t1 === -1) {
          return ret({ kind: 'varchar' } as T.PsqlVarchar, ab,ae);
        } else {
          return ret({ kind: 'varchar', size: num(t1!,t2!) } as T.PsqlVarchar, ab,ae);
	}
      }
            case 391:
                yych = yyinput.charCodeAt(yycursor)
                yystate = 392
                continue yyl
            case 392:
                switch (yych) {
                    case 0x20:
                        yycursor += 1;
                        yystate = 391
                        continue yyl
                    case 0x28:
                        yycursor += 1;
                        yystate = 393
                        continue yyl
                    case 0x5B:
                        yycursor += 1;
                        yystate = 394
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 393:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x20:
                        yycursor += 1;
                        yystate = 393
                        continue yyl
                    case 0x31:
                    case 0x32:
                    case 0x33:
                    case 0x34:
                    case 0x35:
                    case 0x36:
                    case 0x37:
                    case 0x38:
                    case 0x39:
                        yyt2 = yycursor;
                        yycursor += 1;
                        yystate = 437
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 394:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x30:
                    case 0x31:
                    case 0x32:
                    case 0x33:
                    case 0x34:
                    case 0x35:
                    case 0x36:
                    case 0x37:
                    case 0x38:
                    case 0x39:
                        yycursor += 1;
                        yystate = 394
                        continue yyl
                    case 0x5D:
                        yycursor += 1;
                        yystate = 438
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 395:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x4E:
                    case 0x6E:
                        yycursor += 1;
                        yystate = 439
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 396:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x00:
                        yyt1 = yycursor;
                        yycursor += 1;
                        yystate = 343
                        continue yyl
                    case 0x20:
                        yycursor += 1;
                        yystate = 344
                        continue yyl
                    case 0x5B:
                        yycursor += 1;
                        yystate = 345
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 397:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x20:
                        yycursor += 1;
                        yystate = 397
                        continue yyl
                    case 0x29:
                        yycursor += 1;
                        yystate = 398
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 398:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x00:
                        yyt1 = -1;
                        yyt4 = -1;
                        yycursor += 1;
                        yystate = 289
                        continue yyl
                    case 0x20:
                        yyt4 = yycursor;
                        yycursor += 1;
                        yystate = 399
                        continue yyl
                    case 0x5B:
                        yyt4 = yycursor;
                        yycursor += 1;
                        yystate = 293
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 399:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x20:
                        yycursor += 1;
                        yystate = 399
                        continue yyl
                    case 0x5B:
                        yycursor += 1;
                        yystate = 293
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 400:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x41:
                    case 0x61:
                        yycursor += 1;
                        yystate = 440
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 401:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x00:
                        yyt1 = -1;
                        yyt2 = -1;
                        yyt3 = -1;
                        yyt4 = -1;
                        yycursor += 1;
                        yystate = 142
                        continue yyl
                    case 0x20:
                        yyt2 = -1;
                        yyt3 = -1;
                        yyt4 = yycursor;
                        yycursor += 1;
                        yystate = 143
                        continue yyl
                    case 0x28:
                        yycursor += 1;
                        yystate = 144
                        continue yyl
                    case 0x5B:
                        yyt2 = -1;
                        yyt3 = -1;
                        yyt4 = yycursor;
                        yycursor += 1;
                        yystate = 146
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 402:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x00:
                        yyt1 = -1;
                        yyt2 = -1;
                        yycursor += 1;
                        yystate = 441
                        continue yyl
                    case 0x20:
                        yyt2 = yycursor;
                        yycursor += 1;
                        yystate = 442
                        continue yyl
                    case 0x5B:
                        yyt2 = yycursor;
                        yycursor += 1;
                        yystate = 443
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 403:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x45:
                    case 0x65:
                        yycursor += 1;
                        yystate = 444
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 404:
                t1 = yyt1;
                t2 = yyt2;
                ab = yyt4;
                ae = yyt3;
                {
         let n = num(t1!, t2!);
	 if(n < 25) {
	   return ret({ kind: 'float', size: 4 } as T.PsqlFloat, ab,ae);
	 } else {
	   return ret({ kind: 'float', size: 8 } as T.PsqlFloat, ab, ae);
	 }
      }
            case 405:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x20:
                        yycursor += 1;
                        yystate = 405
                        continue yyl
                    case 0x5B:
                        yycursor += 1;
                        yystate = 406
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 406:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x30:
                    case 0x31:
                    case 0x32:
                    case 0x33:
                    case 0x34:
                    case 0x35:
                    case 0x36:
                    case 0x37:
                    case 0x38:
                    case 0x39:
                        yycursor += 1;
                        yystate = 406
                        continue yyl
                    case 0x5D:
                        yycursor += 1;
                        yystate = 445
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 407:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x00:
                        yyt1 = -1;
                        yyt2 = -1;
                        yycursor += 1;
                        yystate = 446
                        continue yyl
                    case 0x20:
                        yyt2 = yycursor;
                        yycursor += 1;
                        yystate = 447
                        continue yyl
                    case 0x5B:
                        yyt2 = yycursor;
                        yycursor += 1;
                        yystate = 448
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 408:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x00:
                        yyt1 = -1;
                        yyt2 = -1;
                        yycursor += 1;
                        yystate = 449
                        continue yyl
                    case 0x20:
                        yyt2 = yycursor;
                        yycursor += 1;
                        yystate = 450
                        continue yyl
                    case 0x5B:
                        yyt2 = yycursor;
                        yycursor += 1;
                        yystate = 451
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 409:
                y = yyt2;
                m = yyt3;
                d = yyt4;
                h = yyt5;
                mm = yyt6;
                s = yyt7;
                ym = yyt8;
                dh = yyt9;
                dm = yyt10;
                ds = yyt11;
                hm = yyt12;
                hs = yyt13;
                ms = yyt14;
                t = yyt15;
                ab = yyt16;
                ae = yyt1;
                {
         let r: T.PsqlInterval = { kind: 'interval', precision: t === -1 ? 6 : sec_prec(t!) };
	 if (y! >= 0)     {
	 if(t !== -1) return null;
	   r.precision = 0;
	   r.fields = 'YEAR'
	 } else if(m! >=0)  {
	   if(t !== -1) return null;
	   r.precision = 0;
	   r.fields = 'MONTH'
	 } else if(d! >=0)  {
	   if(t !== -1) return null;
	   r.precision = 0;
	   r.fields = 'DAY'
	 } else if(h! >=0)  {
	   r.precision = 0;
	   if(t !== -1) return null;
	   r.fields = 'HOUR'
	 } else if(mm! >=0) {
	   if(t !== -1) return null;
	   r.precision = 0;
	   r.fields = 'MINUTE'
	 } else if(s! >=0)  {
	   r.fields = 'SECOND'
	 } else if(ym! >=0) {
	   if(t !== -1) return null;
	   r.precision = 0;
	   r.fields = 'YEAR TO MONTH'
	 } else if(dh! >=0) {
	   if(t !== -1) return null;
	   r.precision = 0;
	   r.fields = 'DAY TO HOUR'
	 } else if(dm! >=0) {
	   if(t !== -1) return null;
	   r.fields = 'DAY TO MINUTE'
	 } else if(ds! >=0) {
	   r.fields = 'DAY TO SECOND'
	 } else if(hm! >=0) {
	   if(t !== -1) return null;
	   r.precision = 0;
	   r.fields = 'HOUR TO MINUTE'
	 } else if(hs! >=0) {
	   r.fields = 'HOUR TO SECOND'
	 } else if(ms! >=0) {
	   r.fields = 'MINUTE TO SECOND'
	 };
	 return ret(r, ab,ae);
      }
            case 410:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x20:
                        yycursor += 1;
                        yystate = 410
                        continue yyl
                    case 0x28:
                        yycursor += 1;
                        yystate = 411
                        continue yyl
                    case 0x44:
                    case 0x64:
                        yyt1 = yycursor;
                        yyt4 = yycursor;
                        yycursor += 1;
                        yystate = 452
                        continue yyl
                    case 0x48:
                    case 0x68:
                        yyt1 = yycursor;
                        yyt5 = yycursor;
                        yycursor += 1;
                        yystate = 453
                        continue yyl
                    case 0x4D:
                    case 0x6D:
                        yyt1 = yycursor;
                        yyt3 = yycursor;
                        yyt6 = yycursor;
                        yycursor += 1;
                        yystate = 454
                        continue yyl
                    case 0x53:
                    case 0x73:
                        yyt7 = yycursor;
                        yycursor += 1;
                        yystate = 455
                        continue yyl
                    case 0x59:
                    case 0x79:
                        yyt1 = yycursor;
                        yyt2 = yycursor;
                        yycursor += 1;
                        yystate = 456
                        continue yyl
                    case 0x5B:
                        yycursor += 1;
                        yystate = 412
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 411:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x30:
                    case 0x31:
                    case 0x32:
                    case 0x33:
                    case 0x34:
                    case 0x35:
                    case 0x36:
                        yyt15 = yycursor;
                        yycursor += 1;
                        yystate = 457
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 412:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x30:
                    case 0x31:
                    case 0x32:
                    case 0x33:
                    case 0x34:
                    case 0x35:
                    case 0x36:
                    case 0x37:
                    case 0x38:
                    case 0x39:
                        yycursor += 1;
                        yystate = 412
                        continue yyl
                    case 0x5D:
                        yycursor += 1;
                        yystate = 458
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 413:
                ab = yyt2;
                ae = yyt1;
                { return ret({ kind: 'macaddr8' } as T.PsqlMacaddr8, ab,ae); }
            case 414:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x20:
                        yycursor += 1;
                        yystate = 414
                        continue yyl
                    case 0x5B:
                        yycursor += 1;
                        yystate = 415
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 415:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x30:
                    case 0x31:
                    case 0x32:
                    case 0x33:
                    case 0x34:
                    case 0x35:
                    case 0x36:
                    case 0x37:
                    case 0x38:
                    case 0x39:
                        yycursor += 1;
                        yystate = 415
                        continue yyl
                    case 0x5D:
                        yycursor += 1;
                        yystate = 459
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 416:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x00:
                        yyt1 = yycursor;
                        yycursor += 1;
                        yystate = 358
                        continue yyl
                    case 0x20:
                        yycursor += 1;
                        yystate = 359
                        continue yyl
                    case 0x5B:
                        yycursor += 1;
                        yystate = 361
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 417:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x20:
                        yyt3 = yycursor;
                        yyt4 = -1;
                        yyt5 = -1;
                        yycursor += 1;
                        yystate = 460
                        continue yyl
                    case 0x29:
                        yyt3 = yycursor;
                        yyt4 = -1;
                        yyt5 = -1;
                        yycursor += 1;
                        yystate = 461
                        continue yyl
                    case 0x2C:
                        yyt3 = yycursor;
                        yycursor += 1;
                        yystate = 462
                        continue yyl
                    case 0x30:
                    case 0x31:
                    case 0x32:
                    case 0x33:
                    case 0x34:
                    case 0x35:
                    case 0x36:
                    case 0x37:
                    case 0x38:
                    case 0x39:
                        yycursor += 1;
                        yystate = 417
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 418:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x00:
                        yyt1 = yycursor;
                        yycursor += 1;
                        yystate = 362
                        continue yyl
                    case 0x20:
                        yycursor += 1;
                        yystate = 463
                        continue yyl
                    case 0x5B:
                        yycursor += 1;
                        yystate = 366
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 419:
                ab = yyt2;
                ae = yyt1;
                { return ret({ kind: 'numrange' } as T.PsqlNumrange, ab,ae); }
            case 420:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x20:
                        yycursor += 1;
                        yystate = 420
                        continue yyl
                    case 0x5B:
                        yycursor += 1;
                        yystate = 421
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 421:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x30:
                    case 0x31:
                    case 0x32:
                    case 0x33:
                    case 0x34:
                    case 0x35:
                    case 0x36:
                    case 0x37:
                    case 0x38:
                    case 0x39:
                        yycursor += 1;
                        yystate = 421
                        continue yyl
                    case 0x5D:
                        yycursor += 1;
                        yystate = 464
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 422:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x00:
                        yyt1 = yycursor;
                        yycursor += 1;
                        yystate = 368
                        continue yyl
                    case 0x20:
                        yycursor += 1;
                        yystate = 369
                        continue yyl
                    case 0x5B:
                        yycursor += 1;
                        yystate = 370
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 423:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x00:
                        yyt1 = yycursor;
                        yycursor += 1;
                        yystate = 371
                        continue yyl
                    case 0x20:
                        yycursor += 1;
                        yystate = 372
                        continue yyl
                    case 0x5B:
                        yycursor += 1;
                        yystate = 373
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 424:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x00:
                        yyt1 = yycursor;
                        yycursor += 1;
                        yystate = 374
                        continue yyl
                    case 0x20:
                        yycursor += 1;
                        yystate = 375
                        continue yyl
                    case 0x5B:
                        yycursor += 1;
                        yystate = 376
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 425:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x41:
                    case 0x61:
                        yycursor += 1;
                        yystate = 465
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 426:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x00:
                        yyt1 = -1;
                        yyt2 = -1;
                        yyt3 = -1;
                        yycursor += 1;
                        yystate = 466
                        continue yyl
                    case 0x20:
                        yyt2 = -1;
                        yyt3 = yycursor;
                        yyt4 = -1;
                        yycursor += 1;
                        yystate = 467
                        continue yyl
                    case 0x57:
                    case 0x77:
                        yyt2 = -1;
                        yyt4 = -1;
                        yycursor += 1;
                        yystate = 470
                        continue yyl
                    case 0x5A:
                    case 0x7A:
                        yycursor += 1;
                        yystate = 471
                        continue yyl
                    case 0x5B:
                        yyt2 = -1;
                        yyt3 = yycursor;
                        yycursor += 1;
                        yystate = 472
                        continue yyl
                    default:
                        yystate = 468
                        continue yyl
                }
            case 427:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x20:
                        yycursor += 1;
                        yystate = 427
                        continue yyl
                    case 0x54:
                    case 0x74:
                        yycursor += 1;
                        yystate = 473
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 428:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x55:
                    case 0x75:
                        yycursor += 1;
                        yystate = 474
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 429:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x00:
                        yyt1 = yycursor;
                        yycursor += 1;
                        yystate = 383
                        continue yyl
                    case 0x20:
                        yycursor += 1;
                        yystate = 384
                        continue yyl
                    case 0x5B:
                        yycursor += 1;
                        yystate = 385
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 430:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x00:
                        yyt1 = -1;
                        yyt2 = -1;
                        yycursor += 1;
                        yystate = 475
                        continue yyl
                    case 0x20:
                        yyt2 = yycursor;
                        yycursor += 1;
                        yystate = 476
                        continue yyl
                    case 0x5B:
                        yyt2 = yycursor;
                        yycursor += 1;
                        yystate = 477
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 431:
                ab = yyt2;
                ae = yyt1;
                { return ret({ kind: 'tsvector' } as T.PsqlTsvector, ab,ae); }
            case 432:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x20:
                        yycursor += 1;
                        yystate = 432
                        continue yyl
                    case 0x5B:
                        yycursor += 1;
                        yystate = 433
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 433:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x30:
                    case 0x31:
                    case 0x32:
                    case 0x33:
                    case 0x34:
                    case 0x35:
                    case 0x36:
                    case 0x37:
                    case 0x38:
                    case 0x39:
                        yycursor += 1;
                        yystate = 433
                        continue yyl
                    case 0x5D:
                        yycursor += 1;
                        yystate = 478
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 434:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x20:
                        yycursor += 1;
                        yystate = 434
                        continue yyl
                    case 0x29:
                        yycursor += 1;
                        yystate = 435
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 435:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x00:
                        yyt1 = -1;
                        yyt4 = -1;
                        yycursor += 1;
                        yystate = 334
                        continue yyl
                    case 0x20:
                        yyt4 = yycursor;
                        yycursor += 1;
                        yystate = 436
                        continue yyl
                    case 0x5B:
                        yyt4 = yycursor;
                        yycursor += 1;
                        yystate = 338
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 436:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x20:
                        yycursor += 1;
                        yystate = 436
                        continue yyl
                    case 0x5B:
                        yycursor += 1;
                        yystate = 338
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 437:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x20:
                        yyt3 = yycursor;
                        yycursor += 1;
                        yystate = 479
                        continue yyl
                    case 0x29:
                        yyt3 = yycursor;
                        yycursor += 1;
                        yystate = 480
                        continue yyl
                    case 0x30:
                    case 0x31:
                    case 0x32:
                    case 0x33:
                    case 0x34:
                    case 0x35:
                    case 0x36:
                    case 0x37:
                    case 0x38:
                    case 0x39:
                        yycursor += 1;
                        yystate = 437
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 438:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x00:
                        yyt1 = yycursor;
                        yycursor += 1;
                        yystate = 390
                        continue yyl
                    case 0x20:
                        yycursor += 1;
                        yystate = 481
                        continue yyl
                    case 0x5B:
                        yycursor += 1;
                        yystate = 394
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 439:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x47:
                    case 0x67:
                        yycursor += 1;
                        yystate = 281
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 440:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x42:
                    case 0x62:
                        yycursor += 1;
                        yystate = 482
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 441:
                ab = yyt2;
                ae = yyt1;
                { return ret({ kind: 'daterange' } as T.PsqlDaterange, ab,ae); }
            case 442:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x20:
                        yycursor += 1;
                        yystate = 442
                        continue yyl
                    case 0x5B:
                        yycursor += 1;
                        yystate = 443
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 443:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x30:
                    case 0x31:
                    case 0x32:
                    case 0x33:
                    case 0x34:
                    case 0x35:
                    case 0x36:
                    case 0x37:
                    case 0x38:
                    case 0x39:
                        yycursor += 1;
                        yystate = 443
                        continue yyl
                    case 0x5D:
                        yycursor += 1;
                        yystate = 483
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 444:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x43:
                    case 0x63:
                        yycursor += 1;
                        yystate = 484
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 445:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x00:
                        yyt3 = yycursor;
                        yycursor += 1;
                        yystate = 404
                        continue yyl
                    case 0x20:
                        yycursor += 1;
                        yystate = 405
                        continue yyl
                    case 0x5B:
                        yycursor += 1;
                        yystate = 406
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 446:
                ab = yyt2;
                ae = yyt1;
                { return ret({ kind: 'int4range' } as T.PsqlInt4range, ab,ae); }
            case 447:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x20:
                        yycursor += 1;
                        yystate = 447
                        continue yyl
                    case 0x5B:
                        yycursor += 1;
                        yystate = 448
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 448:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x30:
                    case 0x31:
                    case 0x32:
                    case 0x33:
                    case 0x34:
                    case 0x35:
                    case 0x36:
                    case 0x37:
                    case 0x38:
                    case 0x39:
                        yycursor += 1;
                        yystate = 448
                        continue yyl
                    case 0x5D:
                        yycursor += 1;
                        yystate = 485
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 449:
                ab = yyt2;
                ae = yyt1;
                { return ret({ kind: 'int8range' } as T.PsqlInt8range, ab,ae); }
            case 450:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x20:
                        yycursor += 1;
                        yystate = 450
                        continue yyl
                    case 0x5B:
                        yycursor += 1;
                        yystate = 451
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 451:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x30:
                    case 0x31:
                    case 0x32:
                    case 0x33:
                    case 0x34:
                    case 0x35:
                    case 0x36:
                    case 0x37:
                    case 0x38:
                    case 0x39:
                        yycursor += 1;
                        yystate = 451
                        continue yyl
                    case 0x5D:
                        yycursor += 1;
                        yystate = 486
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 452:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x41:
                    case 0x61:
                        yycursor += 1;
                        yystate = 487
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 453:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x4F:
                    case 0x6F:
                        yycursor += 1;
                        yystate = 488
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 454:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x49:
                    case 0x69:
                        yycursor += 1;
                        yystate = 489
                        continue yyl
                    case 0x4F:
                    case 0x6F:
                        yycursor += 1;
                        yystate = 490
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 455:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x45:
                    case 0x65:
                        yycursor += 1;
                        yystate = 491
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 456:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x45:
                    case 0x65:
                        yycursor += 1;
                        yystate = 492
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 457:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x20:
                        yycursor += 1;
                        yystate = 457
                        continue yyl
                    case 0x29:
                        yycursor += 1;
                        yystate = 493
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 458:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x00:
                        yyt1 = yycursor;
                        yycursor += 1;
                        yystate = 409
                        continue yyl
                    case 0x20:
                        yycursor += 1;
                        yystate = 494
                        continue yyl
                    case 0x5B:
                        yycursor += 1;
                        yystate = 412
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 459:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x00:
                        yyt1 = yycursor;
                        yycursor += 1;
                        yystate = 413
                        continue yyl
                    case 0x20:
                        yycursor += 1;
                        yystate = 414
                        continue yyl
                    case 0x5B:
                        yycursor += 1;
                        yystate = 415
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 460:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x20:
                        yycursor += 1;
                        yystate = 460
                        continue yyl
                    case 0x29:
                        yycursor += 1;
                        yystate = 461
                        continue yyl
                    case 0x2C:
                        yycursor += 1;
                        yystate = 462
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 461:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x00:
                        yyt1 = -1;
                        yyt6 = -1;
                        yycursor += 1;
                        yystate = 362
                        continue yyl
                    case 0x20:
                        yyt6 = yycursor;
                        yycursor += 1;
                        yystate = 463
                        continue yyl
                    case 0x5B:
                        yyt6 = yycursor;
                        yycursor += 1;
                        yystate = 366
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 462:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x20:
                        yycursor += 1;
                        yystate = 462
                        continue yyl
                    case 0x2D:
                        yyt4 = yycursor;
                        yycursor += 1;
                        yystate = 495
                        continue yyl
                    case 0x31:
                    case 0x32:
                    case 0x33:
                    case 0x34:
                    case 0x35:
                    case 0x36:
                    case 0x37:
                    case 0x38:
                    case 0x39:
                        yyt4 = yycursor;
                        yycursor += 1;
                        yystate = 496
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 463:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x20:
                        yycursor += 1;
                        yystate = 463
                        continue yyl
                    case 0x5B:
                        yycursor += 1;
                        yystate = 366
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 464:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x00:
                        yyt1 = yycursor;
                        yycursor += 1;
                        yystate = 419
                        continue yyl
                    case 0x20:
                        yycursor += 1;
                        yystate = 420
                        continue yyl
                    case 0x5B:
                        yycursor += 1;
                        yystate = 421
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 465:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x4C:
                    case 0x6C:
                        yycursor += 1;
                        yystate = 321
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 466:
                t = yyt2;
                ab = yyt3;
                ae = yyt1;
                {
         return ret({ kind: 'timestamp', precision: t === -1 ? 6 : sec_prec(t!), withTimezone: false } as T.PsqlTimestamp, ab,ae);
      }
            case 467:
                yych = yyinput.charCodeAt(yycursor)
                yystate = 468
                continue yyl
            case 468:
                switch (yych) {
                    case 0x20:
                        yycursor += 1;
                        yystate = 467
                        continue yyl
                    case 0x28:
                        yycursor += 1;
                        yystate = 469
                        continue yyl
                    case 0x57:
                    case 0x77:
                        yycursor += 1;
                        yystate = 470
                        continue yyl
                    case 0x5B:
                        yycursor += 1;
                        yystate = 472
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 469:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x20:
                        yycursor += 1;
                        yystate = 469
                        continue yyl
                    case 0x30:
                    case 0x31:
                    case 0x32:
                    case 0x33:
                    case 0x34:
                    case 0x35:
                    case 0x36:
                        yyt2 = yycursor;
                        yyt4 = yycursor;
                        yycursor += 1;
                        yystate = 497
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 470:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x49:
                    case 0x69:
                        yycursor += 1;
                        yystate = 498
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 471:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x00:
                        yyt1 = -1;
                        yyt2 = -1;
                        yyt3 = -1;
                        yycursor += 1;
                        yystate = 499
                        continue yyl
                    case 0x20:
                        yyt2 = -1;
                        yyt3 = yycursor;
                        yycursor += 1;
                        yystate = 500
                        continue yyl
                    case 0x5B:
                        yyt2 = -1;
                        yyt3 = yycursor;
                        yycursor += 1;
                        yystate = 503
                        continue yyl
                    default:
                        yystate = 501
                        continue yyl
                }
            case 472:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x30:
                    case 0x31:
                    case 0x32:
                    case 0x33:
                    case 0x34:
                    case 0x35:
                    case 0x36:
                    case 0x37:
                    case 0x38:
                    case 0x39:
                        yycursor += 1;
                        yystate = 472
                        continue yyl
                    case 0x5D:
                        yycursor += 1;
                        yystate = 504
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 473:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x49:
                    case 0x69:
                        yycursor += 1;
                        yystate = 505
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 474:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x54:
                    case 0x74:
                        yycursor += 1;
                        yystate = 506
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 475:
                ab = yyt2;
                ae = yyt1;
                { return ret({ kind: 'tstzrange' } as T.PsqlTstzrange, ab,ae); }
            case 476:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x20:
                        yycursor += 1;
                        yystate = 476
                        continue yyl
                    case 0x5B:
                        yycursor += 1;
                        yystate = 477
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 477:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x30:
                    case 0x31:
                    case 0x32:
                    case 0x33:
                    case 0x34:
                    case 0x35:
                    case 0x36:
                    case 0x37:
                    case 0x38:
                    case 0x39:
                        yycursor += 1;
                        yystate = 477
                        continue yyl
                    case 0x5D:
                        yycursor += 1;
                        yystate = 507
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 478:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x00:
                        yyt1 = yycursor;
                        yycursor += 1;
                        yystate = 431
                        continue yyl
                    case 0x20:
                        yycursor += 1;
                        yystate = 432
                        continue yyl
                    case 0x5B:
                        yycursor += 1;
                        yystate = 433
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 479:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x20:
                        yycursor += 1;
                        yystate = 479
                        continue yyl
                    case 0x29:
                        yycursor += 1;
                        yystate = 480
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 480:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x00:
                        yyt1 = -1;
                        yyt4 = -1;
                        yycursor += 1;
                        yystate = 390
                        continue yyl
                    case 0x20:
                        yyt4 = yycursor;
                        yycursor += 1;
                        yystate = 481
                        continue yyl
                    case 0x5B:
                        yyt4 = yycursor;
                        yycursor += 1;
                        yystate = 394
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 481:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x20:
                        yycursor += 1;
                        yystate = 481
                        continue yyl
                    case 0x5B:
                        yycursor += 1;
                        yystate = 394
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 482:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x4C:
                    case 0x6C:
                        yycursor += 1;
                        yystate = 508
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 483:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x00:
                        yyt1 = yycursor;
                        yycursor += 1;
                        yystate = 441
                        continue yyl
                    case 0x20:
                        yycursor += 1;
                        yystate = 442
                        continue yyl
                    case 0x5B:
                        yycursor += 1;
                        yystate = 443
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 484:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x49:
                    case 0x69:
                        yycursor += 1;
                        yystate = 509
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 485:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x00:
                        yyt1 = yycursor;
                        yycursor += 1;
                        yystate = 446
                        continue yyl
                    case 0x20:
                        yycursor += 1;
                        yystate = 447
                        continue yyl
                    case 0x5B:
                        yycursor += 1;
                        yystate = 448
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 486:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x00:
                        yyt1 = yycursor;
                        yycursor += 1;
                        yystate = 449
                        continue yyl
                    case 0x20:
                        yycursor += 1;
                        yystate = 450
                        continue yyl
                    case 0x5B:
                        yycursor += 1;
                        yystate = 451
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 487:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x59:
                    case 0x79:
                        yycursor += 1;
                        yystate = 510
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 488:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x55:
                    case 0x75:
                        yycursor += 1;
                        yystate = 511
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 489:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x4E:
                    case 0x6E:
                        yycursor += 1;
                        yystate = 512
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 490:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x4E:
                    case 0x6E:
                        yycursor += 1;
                        yystate = 513
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 491:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x43:
                    case 0x63:
                        yycursor += 1;
                        yystate = 514
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 492:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x41:
                    case 0x61:
                        yycursor += 1;
                        yystate = 515
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 493:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x00:
                        yyt1 = -1;
                        yyt16 = -1;
                        yycursor += 1;
                        yystate = 409
                        continue yyl
                    case 0x20:
                        yyt16 = yycursor;
                        yycursor += 1;
                        yystate = 494
                        continue yyl
                    case 0x5B:
                        yyt16 = yycursor;
                        yycursor += 1;
                        yystate = 412
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 494:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x20:
                        yycursor += 1;
                        yystate = 494
                        continue yyl
                    case 0x5B:
                        yycursor += 1;
                        yystate = 412
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 495:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x31:
                    case 0x32:
                    case 0x33:
                    case 0x34:
                    case 0x35:
                    case 0x36:
                    case 0x37:
                    case 0x38:
                    case 0x39:
                        yycursor += 1;
                        yystate = 496
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 496:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x20:
                        yyt5 = yycursor;
                        yycursor += 1;
                        yystate = 516
                        continue yyl
                    case 0x29:
                        yyt5 = yycursor;
                        yycursor += 1;
                        yystate = 461
                        continue yyl
                    case 0x31:
                    case 0x32:
                    case 0x33:
                    case 0x34:
                    case 0x35:
                    case 0x36:
                    case 0x37:
                    case 0x38:
                    case 0x39:
                        yycursor += 1;
                        yystate = 496
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 497:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x20:
                        yycursor += 1;
                        yystate = 497
                        continue yyl
                    case 0x29:
                        yycursor += 1;
                        yystate = 517
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 498:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x54:
                    case 0x74:
                        yycursor += 1;
                        yystate = 518
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 499:
                t = yyt2;
                ab = yyt3;
                ae = yyt1;
                {
         return ret({ kind: 'timestamp', precision: t === -1 ? 6 : sec_prec(t!), withTimezone: true } as T.PsqlTimestamp, ab,ae);
      }
            case 500:
                yych = yyinput.charCodeAt(yycursor)
                yystate = 501
                continue yyl
            case 501:
                switch (yych) {
                    case 0x20:
                        yycursor += 1;
                        yystate = 500
                        continue yyl
                    case 0x28:
                        yycursor += 1;
                        yystate = 502
                        continue yyl
                    case 0x5B:
                        yycursor += 1;
                        yystate = 503
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 502:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x20:
                        yycursor += 1;
                        yystate = 502
                        continue yyl
                    case 0x30:
                    case 0x31:
                    case 0x32:
                    case 0x33:
                    case 0x34:
                    case 0x35:
                    case 0x36:
                        yyt2 = yycursor;
                        yycursor += 1;
                        yystate = 519
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 503:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x30:
                    case 0x31:
                    case 0x32:
                    case 0x33:
                    case 0x34:
                    case 0x35:
                    case 0x36:
                    case 0x37:
                    case 0x38:
                    case 0x39:
                        yycursor += 1;
                        yystate = 503
                        continue yyl
                    case 0x5D:
                        yycursor += 1;
                        yystate = 520
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 504:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x00:
                        yyt1 = yycursor;
                        yycursor += 1;
                        yystate = 466
                        continue yyl
                    case 0x20:
                        yycursor += 1;
                        yystate = 521
                        continue yyl
                    case 0x5B:
                        yycursor += 1;
                        yystate = 472
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 505:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x4D:
                    case 0x6D:
                        yycursor += 1;
                        yystate = 522
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 506:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x20:
                        yycursor += 1;
                        yystate = 523
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 507:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x00:
                        yyt1 = yycursor;
                        yycursor += 1;
                        yystate = 475
                        continue yyl
                    case 0x20:
                        yycursor += 1;
                        yystate = 476
                        continue yyl
                    case 0x5B:
                        yycursor += 1;
                        yystate = 477
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 508:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x45:
                    case 0x65:
                        yycursor += 1;
                        yystate = 339
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 509:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x53:
                    case 0x73:
                        yycursor += 1;
                        yystate = 524
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 510:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x00:
                        yyt1 = -1;
                        yyt2 = -1;
                        yyt3 = -1;
                        yyt5 = -1;
                        yyt6 = -1;
                        yyt7 = -1;
                        yyt8 = -1;
                        yyt9 = -1;
                        yyt10 = -1;
                        yyt11 = -1;
                        yyt12 = -1;
                        yyt13 = -1;
                        yyt14 = -1;
                        yyt15 = -1;
                        yyt16 = -1;
                        yycursor += 1;
                        yystate = 409
                        continue yyl
                    case 0x20:
                        yyt2 = -1;
                        yyt3 = -1;
                        yyt5 = -1;
                        yyt6 = -1;
                        yyt7 = -1;
                        yyt8 = -1;
                        yyt9 = -1;
                        yyt10 = -1;
                        yyt11 = -1;
                        yyt12 = -1;
                        yyt13 = -1;
                        yyt14 = -1;
                        yyt15 = -1;
                        yyt16 = yycursor;
                        yycursor += 1;
                        yystate = 525
                        continue yyl
                    case 0x28:
                        yyt2 = -1;
                        yyt3 = -1;
                        yyt5 = -1;
                        yyt6 = -1;
                        yyt7 = -1;
                        yyt8 = -1;
                        yyt9 = -1;
                        yyt10 = -1;
                        yyt11 = -1;
                        yyt12 = -1;
                        yyt13 = -1;
                        yyt14 = -1;
                        yycursor += 1;
                        yystate = 411
                        continue yyl
                    case 0x5B:
                        yyt2 = -1;
                        yyt3 = -1;
                        yyt5 = -1;
                        yyt6 = -1;
                        yyt7 = -1;
                        yyt8 = -1;
                        yyt9 = -1;
                        yyt10 = -1;
                        yyt11 = -1;
                        yyt12 = -1;
                        yyt13 = -1;
                        yyt14 = -1;
                        yyt15 = -1;
                        yyt16 = yycursor;
                        yycursor += 1;
                        yystate = 412
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 511:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x52:
                    case 0x72:
                        yycursor += 1;
                        yystate = 526
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 512:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x55:
                    case 0x75:
                        yycursor += 1;
                        yystate = 527
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 513:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x54:
                    case 0x74:
                        yycursor += 1;
                        yystate = 528
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 514:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x4F:
                    case 0x6F:
                        yycursor += 1;
                        yystate = 529
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 515:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x52:
                    case 0x72:
                        yycursor += 1;
                        yystate = 530
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 516:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x20:
                        yycursor += 1;
                        yystate = 516
                        continue yyl
                    case 0x29:
                        yycursor += 1;
                        yystate = 461
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 517:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x00:
                        yyt1 = -1;
                        yyt3 = -1;
                        yycursor += 1;
                        yystate = 466
                        continue yyl
                    case 0x20:
                        yyt3 = yycursor;
                        yycursor += 1;
                        yystate = 531
                        continue yyl
                    case 0x5B:
                        yyt3 = yycursor;
                        yycursor += 1;
                        yystate = 472
                        continue yyl
                    default:
                        yystate = 532
                        continue yyl
                }
            case 518:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x48:
                    case 0x68:
                        yycursor += 1;
                        yystate = 533
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 519:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x20:
                        yycursor += 1;
                        yystate = 519
                        continue yyl
                    case 0x29:
                        yycursor += 1;
                        yystate = 534
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 520:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x00:
                        yyt1 = yycursor;
                        yycursor += 1;
                        yystate = 499
                        continue yyl
                    case 0x20:
                        yycursor += 1;
                        yystate = 535
                        continue yyl
                    case 0x5B:
                        yycursor += 1;
                        yystate = 503
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 521:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x20:
                        yycursor += 1;
                        yystate = 521
                        continue yyl
                    case 0x5B:
                        yycursor += 1;
                        yystate = 472
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 522:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x45:
                    case 0x65:
                        yycursor += 1;
                        yystate = 536
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 523:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x20:
                        yycursor += 1;
                        yystate = 523
                        continue yyl
                    case 0x54:
                    case 0x74:
                        yycursor += 1;
                        yystate = 537
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 524:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x49:
                    case 0x69:
                        yycursor += 1;
                        yystate = 538
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 525:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x20:
                        yycursor += 1;
                        yystate = 525
                        continue yyl
                    case 0x28:
                        yycursor += 1;
                        yystate = 411
                        continue yyl
                    case 0x54:
                    case 0x74:
                        yycursor += 1;
                        yystate = 539
                        continue yyl
                    case 0x5B:
                        yycursor += 1;
                        yystate = 412
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 526:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x00:
                        yyt1 = -1;
                        yyt2 = -1;
                        yyt3 = -1;
                        yyt4 = -1;
                        yyt6 = -1;
                        yyt7 = -1;
                        yyt8 = -1;
                        yyt9 = -1;
                        yyt10 = -1;
                        yyt11 = -1;
                        yyt12 = -1;
                        yyt13 = -1;
                        yyt14 = -1;
                        yyt15 = -1;
                        yyt16 = -1;
                        yycursor += 1;
                        yystate = 409
                        continue yyl
                    case 0x20:
                        yyt2 = -1;
                        yyt3 = -1;
                        yyt4 = -1;
                        yyt6 = -1;
                        yyt7 = -1;
                        yyt8 = -1;
                        yyt9 = -1;
                        yyt10 = -1;
                        yyt11 = -1;
                        yyt12 = -1;
                        yyt13 = -1;
                        yyt14 = -1;
                        yyt15 = -1;
                        yyt16 = yycursor;
                        yycursor += 1;
                        yystate = 540
                        continue yyl
                    case 0x28:
                        yyt2 = -1;
                        yyt3 = -1;
                        yyt4 = -1;
                        yyt6 = -1;
                        yyt7 = -1;
                        yyt8 = -1;
                        yyt9 = -1;
                        yyt10 = -1;
                        yyt11 = -1;
                        yyt12 = -1;
                        yyt13 = -1;
                        yyt14 = -1;
                        yycursor += 1;
                        yystate = 411
                        continue yyl
                    case 0x5B:
                        yyt2 = -1;
                        yyt3 = -1;
                        yyt4 = -1;
                        yyt6 = -1;
                        yyt7 = -1;
                        yyt8 = -1;
                        yyt9 = -1;
                        yyt10 = -1;
                        yyt11 = -1;
                        yyt12 = -1;
                        yyt13 = -1;
                        yyt14 = -1;
                        yyt15 = -1;
                        yyt16 = yycursor;
                        yycursor += 1;
                        yystate = 412
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 527:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x54:
                    case 0x74:
                        yycursor += 1;
                        yystate = 541
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 528:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x48:
                    case 0x68:
                        yycursor += 1;
                        yystate = 542
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 529:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x4E:
                    case 0x6E:
                        yycursor += 1;
                        yystate = 543
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 530:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x00:
                        yyt1 = -1;
                        yyt3 = -1;
                        yyt4 = -1;
                        yyt5 = -1;
                        yyt6 = -1;
                        yyt7 = -1;
                        yyt8 = -1;
                        yyt9 = -1;
                        yyt10 = -1;
                        yyt11 = -1;
                        yyt12 = -1;
                        yyt13 = -1;
                        yyt14 = -1;
                        yyt15 = -1;
                        yyt16 = -1;
                        yycursor += 1;
                        yystate = 409
                        continue yyl
                    case 0x20:
                        yyt3 = -1;
                        yyt4 = -1;
                        yyt5 = -1;
                        yyt6 = -1;
                        yyt7 = -1;
                        yyt8 = -1;
                        yyt9 = -1;
                        yyt10 = -1;
                        yyt11 = -1;
                        yyt12 = -1;
                        yyt13 = -1;
                        yyt14 = -1;
                        yyt15 = -1;
                        yyt16 = yycursor;
                        yycursor += 1;
                        yystate = 544
                        continue yyl
                    case 0x28:
                        yyt3 = -1;
                        yyt4 = -1;
                        yyt5 = -1;
                        yyt6 = -1;
                        yyt7 = -1;
                        yyt8 = -1;
                        yyt9 = -1;
                        yyt10 = -1;
                        yyt11 = -1;
                        yyt12 = -1;
                        yyt13 = -1;
                        yyt14 = -1;
                        yycursor += 1;
                        yystate = 411
                        continue yyl
                    case 0x5B:
                        yyt3 = -1;
                        yyt4 = -1;
                        yyt5 = -1;
                        yyt6 = -1;
                        yyt7 = -1;
                        yyt8 = -1;
                        yyt9 = -1;
                        yyt10 = -1;
                        yyt11 = -1;
                        yyt12 = -1;
                        yyt13 = -1;
                        yyt14 = -1;
                        yyt15 = -1;
                        yyt16 = yycursor;
                        yycursor += 1;
                        yystate = 412
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 531:
                yych = yyinput.charCodeAt(yycursor)
                yystate = 532
                continue yyl
            case 532:
                switch (yych) {
                    case 0x20:
                        yycursor += 1;
                        yystate = 531
                        continue yyl
                    case 0x57:
                    case 0x77:
                        yycursor += 1;
                        yystate = 470
                        continue yyl
                    case 0x5B:
                        yycursor += 1;
                        yystate = 472
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 533:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x20:
                        yycursor += 1;
                        yystate = 545
                        continue yyl
                    case 0x4F:
                    case 0x6F:
                        yycursor += 1;
                        yystate = 546
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 534:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x00:
                        yyt1 = -1;
                        yyt3 = -1;
                        yycursor += 1;
                        yystate = 499
                        continue yyl
                    case 0x20:
                        yyt3 = yycursor;
                        yycursor += 1;
                        yystate = 535
                        continue yyl
                    case 0x5B:
                        yyt3 = yycursor;
                        yycursor += 1;
                        yystate = 503
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 535:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x20:
                        yycursor += 1;
                        yystate = 535
                        continue yyl
                    case 0x5B:
                        yycursor += 1;
                        yystate = 503
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 536:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x20:
                        yycursor += 1;
                        yystate = 547
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 537:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x49:
                    case 0x69:
                        yycursor += 1;
                        yystate = 548
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 538:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x4F:
                    case 0x6F:
                        yycursor += 1;
                        yystate = 549
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 539:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x4F:
                    case 0x6F:
                        yycursor += 1;
                        yystate = 550
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 540:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x20:
                        yycursor += 1;
                        yystate = 540
                        continue yyl
                    case 0x28:
                        yycursor += 1;
                        yystate = 411
                        continue yyl
                    case 0x54:
                    case 0x74:
                        yycursor += 1;
                        yystate = 551
                        continue yyl
                    case 0x5B:
                        yycursor += 1;
                        yystate = 412
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 541:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x45:
                    case 0x65:
                        yycursor += 1;
                        yystate = 552
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 542:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x00:
                        yyt1 = -1;
                        yyt2 = -1;
                        yyt4 = -1;
                        yyt5 = -1;
                        yyt6 = -1;
                        yyt7 = -1;
                        yyt8 = -1;
                        yyt9 = -1;
                        yyt10 = -1;
                        yyt11 = -1;
                        yyt12 = -1;
                        yyt13 = -1;
                        yyt14 = -1;
                        yyt15 = -1;
                        yyt16 = -1;
                        yycursor += 1;
                        yystate = 409
                        continue yyl
                    case 0x20:
                        yyt2 = -1;
                        yyt4 = -1;
                        yyt5 = -1;
                        yyt6 = -1;
                        yyt7 = -1;
                        yyt8 = -1;
                        yyt9 = -1;
                        yyt10 = -1;
                        yyt11 = -1;
                        yyt12 = -1;
                        yyt13 = -1;
                        yyt14 = -1;
                        yyt15 = -1;
                        yyt16 = yycursor;
                        yycursor += 1;
                        yystate = 553
                        continue yyl
                    case 0x28:
                        yyt2 = -1;
                        yyt4 = -1;
                        yyt5 = -1;
                        yyt6 = -1;
                        yyt7 = -1;
                        yyt8 = -1;
                        yyt9 = -1;
                        yyt10 = -1;
                        yyt11 = -1;
                        yyt12 = -1;
                        yyt13 = -1;
                        yyt14 = -1;
                        yycursor += 1;
                        yystate = 411
                        continue yyl
                    case 0x5B:
                        yyt2 = -1;
                        yyt4 = -1;
                        yyt5 = -1;
                        yyt6 = -1;
                        yyt7 = -1;
                        yyt8 = -1;
                        yyt9 = -1;
                        yyt10 = -1;
                        yyt11 = -1;
                        yyt12 = -1;
                        yyt13 = -1;
                        yyt14 = -1;
                        yyt15 = -1;
                        yyt16 = yycursor;
                        yycursor += 1;
                        yystate = 412
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 543:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x44:
                    case 0x64:
                        yycursor += 1;
                        yystate = 554
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 544:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x20:
                        yycursor += 1;
                        yystate = 544
                        continue yyl
                    case 0x28:
                        yycursor += 1;
                        yystate = 411
                        continue yyl
                    case 0x54:
                    case 0x74:
                        yycursor += 1;
                        yystate = 555
                        continue yyl
                    case 0x5B:
                        yycursor += 1;
                        yystate = 412
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 545:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x20:
                        yycursor += 1;
                        yystate = 545
                        continue yyl
                    case 0x54:
                    case 0x74:
                        yycursor += 1;
                        yystate = 556
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 546:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x55:
                    case 0x75:
                        yycursor += 1;
                        yystate = 557
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 547:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x20:
                        yycursor += 1;
                        yystate = 547
                        continue yyl
                    case 0x5A:
                    case 0x7A:
                        yycursor += 1;
                        yystate = 558
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 548:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x4D:
                    case 0x6D:
                        yycursor += 1;
                        yystate = 559
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 549:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x4E:
                    case 0x6E:
                        yycursor += 1;
                        yystate = 241
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 550:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x20:
                        yycursor += 1;
                        yystate = 560
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 551:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x4F:
                    case 0x6F:
                        yycursor += 1;
                        yystate = 561
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 552:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x00:
                        yyt1 = -1;
                        yyt2 = -1;
                        yyt3 = -1;
                        yyt4 = -1;
                        yyt5 = -1;
                        yyt7 = -1;
                        yyt8 = -1;
                        yyt9 = -1;
                        yyt10 = -1;
                        yyt11 = -1;
                        yyt12 = -1;
                        yyt13 = -1;
                        yyt14 = -1;
                        yyt15 = -1;
                        yyt16 = -1;
                        yycursor += 1;
                        yystate = 409
                        continue yyl
                    case 0x20:
                        yyt2 = -1;
                        yyt3 = -1;
                        yyt4 = -1;
                        yyt5 = -1;
                        yyt7 = -1;
                        yyt8 = -1;
                        yyt9 = -1;
                        yyt10 = -1;
                        yyt11 = -1;
                        yyt12 = -1;
                        yyt13 = -1;
                        yyt14 = -1;
                        yyt15 = -1;
                        yyt16 = yycursor;
                        yycursor += 1;
                        yystate = 562
                        continue yyl
                    case 0x28:
                        yyt2 = -1;
                        yyt3 = -1;
                        yyt4 = -1;
                        yyt5 = -1;
                        yyt7 = -1;
                        yyt8 = -1;
                        yyt9 = -1;
                        yyt10 = -1;
                        yyt11 = -1;
                        yyt12 = -1;
                        yyt13 = -1;
                        yyt14 = -1;
                        yycursor += 1;
                        yystate = 411
                        continue yyl
                    case 0x5B:
                        yyt2 = -1;
                        yyt3 = -1;
                        yyt4 = -1;
                        yyt5 = -1;
                        yyt7 = -1;
                        yyt8 = -1;
                        yyt9 = -1;
                        yyt10 = -1;
                        yyt11 = -1;
                        yyt12 = -1;
                        yyt13 = -1;
                        yyt14 = -1;
                        yyt15 = -1;
                        yyt16 = yycursor;
                        yycursor += 1;
                        yystate = 412
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 553:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x20:
                        yycursor += 1;
                        yystate = 553
                        continue yyl
                    case 0x28:
                        yycursor += 1;
                        yystate = 411
                        continue yyl
                    case 0x5B:
                        yycursor += 1;
                        yystate = 412
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 554:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x00:
                        yyt1 = -1;
                        yyt2 = -1;
                        yyt3 = -1;
                        yyt4 = -1;
                        yyt5 = -1;
                        yyt6 = -1;
                        yyt8 = -1;
                        yyt9 = -1;
                        yyt10 = -1;
                        yyt11 = -1;
                        yyt12 = -1;
                        yyt13 = -1;
                        yyt14 = -1;
                        yyt15 = -1;
                        yyt16 = -1;
                        yycursor += 1;
                        yystate = 409
                        continue yyl
                    case 0x20:
                        yyt2 = -1;
                        yyt3 = -1;
                        yyt4 = -1;
                        yyt5 = -1;
                        yyt6 = -1;
                        yyt8 = -1;
                        yyt9 = -1;
                        yyt10 = -1;
                        yyt11 = -1;
                        yyt12 = -1;
                        yyt13 = -1;
                        yyt14 = -1;
                        yyt15 = -1;
                        yyt16 = yycursor;
                        yycursor += 1;
                        yystate = 553
                        continue yyl
                    case 0x28:
                        yyt2 = -1;
                        yyt3 = -1;
                        yyt4 = -1;
                        yyt5 = -1;
                        yyt6 = -1;
                        yyt8 = -1;
                        yyt9 = -1;
                        yyt10 = -1;
                        yyt11 = -1;
                        yyt12 = -1;
                        yyt13 = -1;
                        yyt14 = -1;
                        yycursor += 1;
                        yystate = 411
                        continue yyl
                    case 0x5B:
                        yyt2 = -1;
                        yyt3 = -1;
                        yyt4 = -1;
                        yyt5 = -1;
                        yyt6 = -1;
                        yyt8 = -1;
                        yyt9 = -1;
                        yyt10 = -1;
                        yyt11 = -1;
                        yyt12 = -1;
                        yyt13 = -1;
                        yyt14 = -1;
                        yyt15 = -1;
                        yyt16 = yycursor;
                        yycursor += 1;
                        yystate = 412
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 555:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x4F:
                    case 0x6F:
                        yycursor += 1;
                        yystate = 563
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 556:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x49:
                    case 0x69:
                        yycursor += 1;
                        yystate = 564
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 557:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x54:
                    case 0x74:
                        yycursor += 1;
                        yystate = 565
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 558:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x4F:
                    case 0x6F:
                        yycursor += 1;
                        yystate = 566
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 559:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x45:
                    case 0x65:
                        yycursor += 1;
                        yystate = 567
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 560:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x20:
                        yycursor += 1;
                        yystate = 560
                        continue yyl
                    case 0x48:
                    case 0x68:
                        yycursor += 1;
                        yystate = 568
                        continue yyl
                    case 0x4D:
                    case 0x6D:
                        yycursor += 1;
                        yystate = 569
                        continue yyl
                    case 0x53:
                    case 0x73:
                        yycursor += 1;
                        yystate = 570
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 561:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x20:
                        yycursor += 1;
                        yystate = 571
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 562:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x20:
                        yycursor += 1;
                        yystate = 562
                        continue yyl
                    case 0x28:
                        yycursor += 1;
                        yystate = 411
                        continue yyl
                    case 0x54:
                    case 0x74:
                        yycursor += 1;
                        yystate = 572
                        continue yyl
                    case 0x5B:
                        yycursor += 1;
                        yystate = 412
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 563:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x20:
                        yycursor += 1;
                        yystate = 573
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 564:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x4D:
                    case 0x6D:
                        yycursor += 1;
                        yystate = 574
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 565:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x20:
                        yycursor += 1;
                        yystate = 575
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 566:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x4E:
                    case 0x6E:
                        yycursor += 1;
                        yystate = 576
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 567:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x20:
                        yycursor += 1;
                        yystate = 577
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 568:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x4F:
                    case 0x6F:
                        yycursor += 1;
                        yystate = 578
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 569:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x49:
                    case 0x69:
                        yycursor += 1;
                        yystate = 579
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 570:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x45:
                    case 0x65:
                        yycursor += 1;
                        yystate = 580
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 571:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x20:
                        yycursor += 1;
                        yystate = 571
                        continue yyl
                    case 0x4D:
                    case 0x6D:
                        yycursor += 1;
                        yystate = 581
                        continue yyl
                    case 0x53:
                    case 0x73:
                        yycursor += 1;
                        yystate = 582
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 572:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x4F:
                    case 0x6F:
                        yycursor += 1;
                        yystate = 583
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 573:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x20:
                        yycursor += 1;
                        yystate = 573
                        continue yyl
                    case 0x4D:
                    case 0x6D:
                        yycursor += 1;
                        yystate = 584
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 574:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x45:
                    case 0x65:
                        yycursor += 1;
                        yystate = 585
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 575:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x20:
                        yycursor += 1;
                        yystate = 575
                        continue yyl
                    case 0x54:
                    case 0x74:
                        yycursor += 1;
                        yystate = 586
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 576:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x45:
                    case 0x65:
                        yycursor += 1;
                        yystate = 587
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 577:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x20:
                        yycursor += 1;
                        yystate = 577
                        continue yyl
                    case 0x5A:
                    case 0x7A:
                        yycursor += 1;
                        yystate = 588
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 578:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x55:
                    case 0x75:
                        yycursor += 1;
                        yystate = 589
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 579:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x4E:
                    case 0x6E:
                        yycursor += 1;
                        yystate = 590
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 580:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x43:
                    case 0x63:
                        yycursor += 1;
                        yystate = 591
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 581:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x49:
                    case 0x69:
                        yycursor += 1;
                        yystate = 592
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 582:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x45:
                    case 0x65:
                        yycursor += 1;
                        yystate = 593
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 583:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x20:
                        yycursor += 1;
                        yystate = 594
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 584:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x4F:
                    case 0x6F:
                        yycursor += 1;
                        yystate = 595
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 585:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x20:
                        yycursor += 1;
                        yystate = 596
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 586:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x49:
                    case 0x69:
                        yycursor += 1;
                        yystate = 597
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 587:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x00:
                        yyt1 = -1;
                        yyt2 = -1;
                        yycursor += 1;
                        yystate = 598
                        continue yyl
                    case 0x20:
                        yyt2 = yycursor;
                        yycursor += 1;
                        yystate = 599
                        continue yyl
                    case 0x5B:
                        yyt2 = yycursor;
                        yycursor += 1;
                        yystate = 600
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 588:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x4F:
                    case 0x6F:
                        yycursor += 1;
                        yystate = 601
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 589:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x52:
                    case 0x72:
                        yycursor += 1;
                        yystate = 602
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 590:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x55:
                    case 0x75:
                        yycursor += 1;
                        yystate = 603
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 591:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x4F:
                    case 0x6F:
                        yycursor += 1;
                        yystate = 604
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 592:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x4E:
                    case 0x6E:
                        yycursor += 1;
                        yystate = 605
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 593:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x43:
                    case 0x63:
                        yycursor += 1;
                        yystate = 606
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 594:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x20:
                        yycursor += 1;
                        yystate = 594
                        continue yyl
                    case 0x53:
                    case 0x73:
                        yycursor += 1;
                        yystate = 607
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 595:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x4E:
                    case 0x6E:
                        yycursor += 1;
                        yystate = 608
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 596:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x20:
                        yycursor += 1;
                        yystate = 596
                        continue yyl
                    case 0x5A:
                    case 0x7A:
                        yycursor += 1;
                        yystate = 609
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 597:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x4D:
                    case 0x6D:
                        yycursor += 1;
                        yystate = 610
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 598:
                t = yyt4;
                ab = yyt2;
                ae = yyt1;
                {
         return ret({ kind: 'time', precision: t === -1 ? 6 : sec_prec(t!), withTimezone: true } as T.PsqlTime, ab,ae);
      }
            case 599:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x20:
                        yycursor += 1;
                        yystate = 599
                        continue yyl
                    case 0x5B:
                        yycursor += 1;
                        yystate = 600
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 600:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x30:
                    case 0x31:
                    case 0x32:
                    case 0x33:
                    case 0x34:
                    case 0x35:
                    case 0x36:
                    case 0x37:
                    case 0x38:
                    case 0x39:
                        yycursor += 1;
                        yystate = 600
                        continue yyl
                    case 0x5D:
                        yycursor += 1;
                        yystate = 611
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 601:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x4E:
                    case 0x6E:
                        yycursor += 1;
                        yystate = 612
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 602:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x00:
                        yyt9 = yyt1;
                        yyt1 = -1;
                        yyt2 = -1;
                        yyt3 = -1;
                        yyt4 = -1;
                        yyt5 = -1;
                        yyt6 = -1;
                        yyt7 = -1;
                        yyt8 = -1;
                        yyt10 = -1;
                        yyt11 = -1;
                        yyt12 = -1;
                        yyt13 = -1;
                        yyt14 = -1;
                        yyt15 = -1;
                        yyt16 = -1;
                        yycursor += 1;
                        yystate = 409
                        continue yyl
                    case 0x20:
                        yyt9 = yyt1;
                        yyt2 = -1;
                        yyt3 = -1;
                        yyt4 = -1;
                        yyt5 = -1;
                        yyt6 = -1;
                        yyt7 = -1;
                        yyt8 = -1;
                        yyt10 = -1;
                        yyt11 = -1;
                        yyt12 = -1;
                        yyt13 = -1;
                        yyt14 = -1;
                        yyt15 = -1;
                        yyt16 = yycursor;
                        yycursor += 1;
                        yystate = 553
                        continue yyl
                    case 0x28:
                        yyt9 = yyt1;
                        yyt2 = -1;
                        yyt3 = -1;
                        yyt4 = -1;
                        yyt5 = -1;
                        yyt6 = -1;
                        yyt7 = -1;
                        yyt8 = -1;
                        yyt10 = -1;
                        yyt11 = -1;
                        yyt12 = -1;
                        yyt13 = -1;
                        yyt14 = -1;
                        yycursor += 1;
                        yystate = 411
                        continue yyl
                    case 0x5B:
                        yyt9 = yyt1;
                        yyt2 = -1;
                        yyt3 = -1;
                        yyt4 = -1;
                        yyt5 = -1;
                        yyt6 = -1;
                        yyt7 = -1;
                        yyt8 = -1;
                        yyt10 = -1;
                        yyt11 = -1;
                        yyt12 = -1;
                        yyt13 = -1;
                        yyt14 = -1;
                        yyt15 = -1;
                        yyt16 = yycursor;
                        yycursor += 1;
                        yystate = 412
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 603:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x54:
                    case 0x74:
                        yycursor += 1;
                        yystate = 613
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 604:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x4E:
                    case 0x6E:
                        yycursor += 1;
                        yystate = 614
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 605:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x55:
                    case 0x75:
                        yycursor += 1;
                        yystate = 615
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 606:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x4F:
                    case 0x6F:
                        yycursor += 1;
                        yystate = 616
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 607:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x45:
                    case 0x65:
                        yycursor += 1;
                        yystate = 617
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 608:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x54:
                    case 0x74:
                        yycursor += 1;
                        yystate = 618
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 609:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x4F:
                    case 0x6F:
                        yycursor += 1;
                        yystate = 619
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 610:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x45:
                    case 0x65:
                        yycursor += 1;
                        yystate = 620
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 611:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x00:
                        yyt1 = yycursor;
                        yycursor += 1;
                        yystate = 598
                        continue yyl
                    case 0x20:
                        yycursor += 1;
                        yystate = 599
                        continue yyl
                    case 0x5B:
                        yycursor += 1;
                        yystate = 600
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 612:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x45:
                    case 0x65:
                        yycursor += 1;
                        yystate = 621
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 613:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x45:
                    case 0x65:
                        yycursor += 1;
                        yystate = 622
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 614:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x44:
                    case 0x64:
                        yycursor += 1;
                        yystate = 623
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 615:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x54:
                    case 0x74:
                        yycursor += 1;
                        yystate = 624
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 616:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x4E:
                    case 0x6E:
                        yycursor += 1;
                        yystate = 625
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 617:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x43:
                    case 0x63:
                        yycursor += 1;
                        yystate = 626
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 618:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x48:
                    case 0x68:
                        yycursor += 1;
                        yystate = 627
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 619:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x4E:
                    case 0x6E:
                        yycursor += 1;
                        yystate = 628
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 620:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x20:
                        yycursor += 1;
                        yystate = 629
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 621:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x00:
                        yyt1 = -1;
                        yyt3 = -1;
                        yycursor += 1;
                        yystate = 200
                        continue yyl
                    case 0x20:
                        yyt3 = yycursor;
                        yycursor += 1;
                        yystate = 330
                        continue yyl
                    case 0x5B:
                        yyt3 = yycursor;
                        yycursor += 1;
                        yystate = 206
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 622:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x00:
                        yyt10 = yyt1;
                        yyt1 = -1;
                        yyt2 = -1;
                        yyt3 = -1;
                        yyt4 = -1;
                        yyt5 = -1;
                        yyt6 = -1;
                        yyt7 = -1;
                        yyt8 = -1;
                        yyt9 = -1;
                        yyt11 = -1;
                        yyt12 = -1;
                        yyt13 = -1;
                        yyt14 = -1;
                        yyt15 = -1;
                        yyt16 = -1;
                        yycursor += 1;
                        yystate = 409
                        continue yyl
                    case 0x20:
                        yyt10 = yyt1;
                        yyt2 = -1;
                        yyt3 = -1;
                        yyt4 = -1;
                        yyt5 = -1;
                        yyt6 = -1;
                        yyt7 = -1;
                        yyt8 = -1;
                        yyt9 = -1;
                        yyt11 = -1;
                        yyt12 = -1;
                        yyt13 = -1;
                        yyt14 = -1;
                        yyt15 = -1;
                        yyt16 = yycursor;
                        yycursor += 1;
                        yystate = 553
                        continue yyl
                    case 0x28:
                        yyt10 = yyt1;
                        yyt2 = -1;
                        yyt3 = -1;
                        yyt4 = -1;
                        yyt5 = -1;
                        yyt6 = -1;
                        yyt7 = -1;
                        yyt8 = -1;
                        yyt9 = -1;
                        yyt11 = -1;
                        yyt12 = -1;
                        yyt13 = -1;
                        yyt14 = -1;
                        yycursor += 1;
                        yystate = 411
                        continue yyl
                    case 0x5B:
                        yyt10 = yyt1;
                        yyt2 = -1;
                        yyt3 = -1;
                        yyt4 = -1;
                        yyt5 = -1;
                        yyt6 = -1;
                        yyt7 = -1;
                        yyt8 = -1;
                        yyt9 = -1;
                        yyt11 = -1;
                        yyt12 = -1;
                        yyt13 = -1;
                        yyt14 = -1;
                        yyt15 = -1;
                        yyt16 = yycursor;
                        yycursor += 1;
                        yystate = 412
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 623:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x00:
                        yyt11 = yyt1;
                        yyt1 = -1;
                        yyt2 = -1;
                        yyt3 = -1;
                        yyt4 = -1;
                        yyt5 = -1;
                        yyt6 = -1;
                        yyt7 = -1;
                        yyt8 = -1;
                        yyt9 = -1;
                        yyt10 = -1;
                        yyt12 = -1;
                        yyt13 = -1;
                        yyt14 = -1;
                        yyt15 = -1;
                        yyt16 = -1;
                        yycursor += 1;
                        yystate = 409
                        continue yyl
                    case 0x20:
                        yyt11 = yyt1;
                        yyt2 = -1;
                        yyt3 = -1;
                        yyt4 = -1;
                        yyt5 = -1;
                        yyt6 = -1;
                        yyt7 = -1;
                        yyt8 = -1;
                        yyt9 = -1;
                        yyt10 = -1;
                        yyt12 = -1;
                        yyt13 = -1;
                        yyt14 = -1;
                        yyt15 = -1;
                        yyt16 = yycursor;
                        yycursor += 1;
                        yystate = 553
                        continue yyl
                    case 0x28:
                        yyt11 = yyt1;
                        yyt2 = -1;
                        yyt3 = -1;
                        yyt4 = -1;
                        yyt5 = -1;
                        yyt6 = -1;
                        yyt7 = -1;
                        yyt8 = -1;
                        yyt9 = -1;
                        yyt10 = -1;
                        yyt12 = -1;
                        yyt13 = -1;
                        yyt14 = -1;
                        yycursor += 1;
                        yystate = 411
                        continue yyl
                    case 0x5B:
                        yyt11 = yyt1;
                        yyt2 = -1;
                        yyt3 = -1;
                        yyt4 = -1;
                        yyt5 = -1;
                        yyt6 = -1;
                        yyt7 = -1;
                        yyt8 = -1;
                        yyt9 = -1;
                        yyt10 = -1;
                        yyt12 = -1;
                        yyt13 = -1;
                        yyt14 = -1;
                        yyt15 = -1;
                        yyt16 = yycursor;
                        yycursor += 1;
                        yystate = 412
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 624:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x45:
                    case 0x65:
                        yycursor += 1;
                        yystate = 630
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 625:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x44:
                    case 0x64:
                        yycursor += 1;
                        yystate = 631
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 626:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x4F:
                    case 0x6F:
                        yycursor += 1;
                        yystate = 632
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 627:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x00:
                        yyt8 = yyt1;
                        yyt1 = -1;
                        yyt2 = -1;
                        yyt3 = -1;
                        yyt4 = -1;
                        yyt5 = -1;
                        yyt6 = -1;
                        yyt7 = -1;
                        yyt9 = -1;
                        yyt10 = -1;
                        yyt11 = -1;
                        yyt12 = -1;
                        yyt13 = -1;
                        yyt14 = -1;
                        yyt15 = -1;
                        yyt16 = -1;
                        yycursor += 1;
                        yystate = 409
                        continue yyl
                    case 0x20:
                        yyt8 = yyt1;
                        yyt2 = -1;
                        yyt3 = -1;
                        yyt4 = -1;
                        yyt5 = -1;
                        yyt6 = -1;
                        yyt7 = -1;
                        yyt9 = -1;
                        yyt10 = -1;
                        yyt11 = -1;
                        yyt12 = -1;
                        yyt13 = -1;
                        yyt14 = -1;
                        yyt15 = -1;
                        yyt16 = yycursor;
                        yycursor += 1;
                        yystate = 553
                        continue yyl
                    case 0x28:
                        yyt8 = yyt1;
                        yyt2 = -1;
                        yyt3 = -1;
                        yyt4 = -1;
                        yyt5 = -1;
                        yyt6 = -1;
                        yyt7 = -1;
                        yyt9 = -1;
                        yyt10 = -1;
                        yyt11 = -1;
                        yyt12 = -1;
                        yyt13 = -1;
                        yyt14 = -1;
                        yycursor += 1;
                        yystate = 411
                        continue yyl
                    case 0x5B:
                        yyt8 = yyt1;
                        yyt2 = -1;
                        yyt3 = -1;
                        yyt4 = -1;
                        yyt5 = -1;
                        yyt6 = -1;
                        yyt7 = -1;
                        yyt9 = -1;
                        yyt10 = -1;
                        yyt11 = -1;
                        yyt12 = -1;
                        yyt13 = -1;
                        yyt14 = -1;
                        yyt15 = -1;
                        yyt16 = yycursor;
                        yycursor += 1;
                        yystate = 412
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 628:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x45:
                    case 0x65:
                        yycursor += 1;
                        yystate = 633
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 629:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x20:
                        yycursor += 1;
                        yystate = 629
                        continue yyl
                    case 0x5A:
                    case 0x7A:
                        yycursor += 1;
                        yystate = 634
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 630:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x00:
                        yyt12 = yyt1;
                        yyt1 = -1;
                        yyt2 = -1;
                        yyt3 = -1;
                        yyt4 = -1;
                        yyt5 = -1;
                        yyt6 = -1;
                        yyt7 = -1;
                        yyt8 = -1;
                        yyt9 = -1;
                        yyt10 = -1;
                        yyt11 = -1;
                        yyt13 = -1;
                        yyt14 = -1;
                        yyt15 = -1;
                        yyt16 = -1;
                        yycursor += 1;
                        yystate = 409
                        continue yyl
                    case 0x20:
                        yyt12 = yyt1;
                        yyt2 = -1;
                        yyt3 = -1;
                        yyt4 = -1;
                        yyt5 = -1;
                        yyt6 = -1;
                        yyt7 = -1;
                        yyt8 = -1;
                        yyt9 = -1;
                        yyt10 = -1;
                        yyt11 = -1;
                        yyt13 = -1;
                        yyt14 = -1;
                        yyt15 = -1;
                        yyt16 = yycursor;
                        yycursor += 1;
                        yystate = 553
                        continue yyl
                    case 0x28:
                        yyt12 = yyt1;
                        yyt2 = -1;
                        yyt3 = -1;
                        yyt4 = -1;
                        yyt5 = -1;
                        yyt6 = -1;
                        yyt7 = -1;
                        yyt8 = -1;
                        yyt9 = -1;
                        yyt10 = -1;
                        yyt11 = -1;
                        yyt13 = -1;
                        yyt14 = -1;
                        yycursor += 1;
                        yystate = 411
                        continue yyl
                    case 0x5B:
                        yyt12 = yyt1;
                        yyt2 = -1;
                        yyt3 = -1;
                        yyt4 = -1;
                        yyt5 = -1;
                        yyt6 = -1;
                        yyt7 = -1;
                        yyt8 = -1;
                        yyt9 = -1;
                        yyt10 = -1;
                        yyt11 = -1;
                        yyt13 = -1;
                        yyt14 = -1;
                        yyt15 = -1;
                        yyt16 = yycursor;
                        yycursor += 1;
                        yystate = 412
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 631:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x00:
                        yyt13 = yyt1;
                        yyt1 = -1;
                        yyt2 = -1;
                        yyt3 = -1;
                        yyt4 = -1;
                        yyt5 = -1;
                        yyt6 = -1;
                        yyt7 = -1;
                        yyt8 = -1;
                        yyt9 = -1;
                        yyt10 = -1;
                        yyt11 = -1;
                        yyt12 = -1;
                        yyt14 = -1;
                        yyt15 = -1;
                        yyt16 = -1;
                        yycursor += 1;
                        yystate = 409
                        continue yyl
                    case 0x20:
                        yyt13 = yyt1;
                        yyt2 = -1;
                        yyt3 = -1;
                        yyt4 = -1;
                        yyt5 = -1;
                        yyt6 = -1;
                        yyt7 = -1;
                        yyt8 = -1;
                        yyt9 = -1;
                        yyt10 = -1;
                        yyt11 = -1;
                        yyt12 = -1;
                        yyt14 = -1;
                        yyt15 = -1;
                        yyt16 = yycursor;
                        yycursor += 1;
                        yystate = 553
                        continue yyl
                    case 0x28:
                        yyt13 = yyt1;
                        yyt2 = -1;
                        yyt3 = -1;
                        yyt4 = -1;
                        yyt5 = -1;
                        yyt6 = -1;
                        yyt7 = -1;
                        yyt8 = -1;
                        yyt9 = -1;
                        yyt10 = -1;
                        yyt11 = -1;
                        yyt12 = -1;
                        yyt14 = -1;
                        yycursor += 1;
                        yystate = 411
                        continue yyl
                    case 0x5B:
                        yyt13 = yyt1;
                        yyt2 = -1;
                        yyt3 = -1;
                        yyt4 = -1;
                        yyt5 = -1;
                        yyt6 = -1;
                        yyt7 = -1;
                        yyt8 = -1;
                        yyt9 = -1;
                        yyt10 = -1;
                        yyt11 = -1;
                        yyt12 = -1;
                        yyt14 = -1;
                        yyt15 = -1;
                        yyt16 = yycursor;
                        yycursor += 1;
                        yystate = 412
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 632:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x4E:
                    case 0x6E:
                        yycursor += 1;
                        yystate = 635
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 633:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x00:
                        yyt1 = -1;
                        yyt2 = -1;
                        yycursor += 1;
                        yystate = 636
                        continue yyl
                    case 0x20:
                        yyt2 = yycursor;
                        yycursor += 1;
                        yystate = 637
                        continue yyl
                    case 0x5B:
                        yyt2 = yycursor;
                        yycursor += 1;
                        yystate = 638
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 634:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x4F:
                    case 0x6F:
                        yycursor += 1;
                        yystate = 639
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 635:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x44:
                    case 0x64:
                        yycursor += 1;
                        yystate = 640
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 636:
                t = yyt4;
                ab = yyt2;
                ae = yyt1;
                {
         return ret({ kind: 'timestamp', precision: t === -1 ? 6 : sec_prec(t!), withTimezone: true } as T.PsqlTimestamp, ab,ae);
      }
            case 637:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x20:
                        yycursor += 1;
                        yystate = 637
                        continue yyl
                    case 0x5B:
                        yycursor += 1;
                        yystate = 638
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 638:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x30:
                    case 0x31:
                    case 0x32:
                    case 0x33:
                    case 0x34:
                    case 0x35:
                    case 0x36:
                    case 0x37:
                    case 0x38:
                    case 0x39:
                        yycursor += 1;
                        yystate = 638
                        continue yyl
                    case 0x5D:
                        yycursor += 1;
                        yystate = 641
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 639:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x4E:
                    case 0x6E:
                        yycursor += 1;
                        yystate = 642
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 640:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x00:
                        yyt14 = yyt1;
                        yyt1 = -1;
                        yyt2 = -1;
                        yyt3 = -1;
                        yyt4 = -1;
                        yyt5 = -1;
                        yyt6 = -1;
                        yyt7 = -1;
                        yyt8 = -1;
                        yyt9 = -1;
                        yyt10 = -1;
                        yyt11 = -1;
                        yyt12 = -1;
                        yyt13 = -1;
                        yyt15 = -1;
                        yyt16 = -1;
                        yycursor += 1;
                        yystate = 409
                        continue yyl
                    case 0x20:
                        yyt14 = yyt1;
                        yyt2 = -1;
                        yyt3 = -1;
                        yyt4 = -1;
                        yyt5 = -1;
                        yyt6 = -1;
                        yyt7 = -1;
                        yyt8 = -1;
                        yyt9 = -1;
                        yyt10 = -1;
                        yyt11 = -1;
                        yyt12 = -1;
                        yyt13 = -1;
                        yyt15 = -1;
                        yyt16 = yycursor;
                        yycursor += 1;
                        yystate = 553
                        continue yyl
                    case 0x28:
                        yyt14 = yyt1;
                        yyt2 = -1;
                        yyt3 = -1;
                        yyt4 = -1;
                        yyt5 = -1;
                        yyt6 = -1;
                        yyt7 = -1;
                        yyt8 = -1;
                        yyt9 = -1;
                        yyt10 = -1;
                        yyt11 = -1;
                        yyt12 = -1;
                        yyt13 = -1;
                        yycursor += 1;
                        yystate = 411
                        continue yyl
                    case 0x5B:
                        yyt14 = yyt1;
                        yyt2 = -1;
                        yyt3 = -1;
                        yyt4 = -1;
                        yyt5 = -1;
                        yyt6 = -1;
                        yyt7 = -1;
                        yyt8 = -1;
                        yyt9 = -1;
                        yyt10 = -1;
                        yyt11 = -1;
                        yyt12 = -1;
                        yyt13 = -1;
                        yyt15 = -1;
                        yyt16 = yycursor;
                        yycursor += 1;
                        yystate = 412
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 641:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x00:
                        yyt1 = yycursor;
                        yycursor += 1;
                        yystate = 636
                        continue yyl
                    case 0x20:
                        yycursor += 1;
                        yystate = 637
                        continue yyl
                    case 0x5B:
                        yycursor += 1;
                        yystate = 638
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 642:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x45:
                    case 0x65:
                        yycursor += 1;
                        yystate = 643
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            case 643:
                yych = yyinput.charCodeAt(yycursor)
                switch (yych) {
                    case 0x00:
                        yyt1 = -1;
                        yyt3 = -1;
                        yycursor += 1;
                        yystate = 466
                        continue yyl
                    case 0x20:
                        yyt3 = yycursor;
                        yycursor += 1;
                        yystate = 521
                        continue yyl
                    case 0x5B:
                        yyt3 = yycursor;
                        yycursor += 1;
                        yystate = 472
                        continue yyl
                    default:
                        yystate = 21
                        continue yyl
                }
            default:
                throw "internal lexer error"
        }
    }
}

    return null; 
}
