// this file is automatically generated.
// !!! DO NOT EDIT !!!

import _ from 'lodash'



export let dirtyFlag = false
function doAlert(s) { console.log(s) }

export type ConnectionPortsType =
  | 'PostgreSQL_5432'
  | 'MySQL_3306'
  | 'MariaDB_3306'
  | 'SqlServer_1433'
  | 'OracleDB_1521'
  | 'DB2_50000'
  | 'MongoDB_27017'
  | 'Elasticsearch_9200'
  | 'S3_443'
  | 'SFTP_22'

export const ConnectionPortsType_as_strings = [ 'PostgreSQL_5432', 'MySQL_3306', 'MariaDB_3306', 'SqlServer_1433', 'OracleDB_1521', 'DB2_50000', 'MongoDB_27017', 'Elasticsearch_9200', 'S3_443', 'SFTP_22' ] as const;



export type ConnectionType =
  | 'PostgreSQL'
  | 'MySQL'
  | 'MariaDB'
  | 'SqlServer'
  | 'OracleDB'
  | 'DB2'
  | 'MongoDB'
  | 'Elasticsearch'
  | 'S3'
  | 'SFTP'

export const ConnectionType_as_strings = [ 'PostgreSQL', 'MySQL', 'MariaDB', 'SqlServer', 'OracleDB', 'DB2', 'MongoDB', 'Elasticsearch', 'S3', 'SFTP' ] as const;

export function humanReadableConnectionType(__a__ : ConnectionType) : string {
  switch(__a__) {
    case 'PostgreSQL': return 'PostgreSQL Database';
    case 'MySQL': return 'MySQL Database';
    case 'MariaDB': return 'MariaDB Database';
    case 'SqlServer': return 'Microsoft SQL Server';
    case 'OracleDB': return 'Oracle Database';
    case 'DB2': return 'IBM DB2 Database';
    case 'MongoDB': return 'MongoDB Database';
    case 'Elasticsearch': return 'Elasticsearch Database';
    case 'S3': return 'Parquet, CSV, JSON on S3';
    case 'SFTP': return 'Parquet, CSV, JSON via SFTP';
  }
  return '';
}

export type DataHandling =
  | 'allow'
  | 'block'
  | 'obfuscate'
  | 'redact'

export const DataHandling_as_strings = [ 'allow', 'block', 'obfuscate', 'redact' ] as const;

export function humanReadableDataHandling(__a__ : DataHandling) : string {
  switch(__a__) {
    case 'allow': return 'Allow';
    case 'block': return 'Block';
    case 'obfuscate': return 'Obfuscate';
    case 'redact': return 'Redact';
  }
  return '';
}

export type PIIDetectionType =
  | 'comprehend'
  | 'columnregexp'
  | 'contentregexp'

export const PIIDetectionType_as_strings = [ 'comprehend', 'columnregexp', 'contentregexp' ] as const;

export function humanReadablePIIDetectionType(__a__ : PIIDetectionType) : string {
  switch(__a__) {
    case 'comprehend': return 'Amazon Comprehend';
    case 'columnregexp': return 'Regexp for Table Columns';
    case 'contentregexp': return 'Regexp for Data';
  }
  return '';
}

export class AccessLevels {
  private '_levels': Array<DataAction>

  constructor() {
    this['_levels'] = []
  }
  get levels(): Array<DataAction> { return this['_levels'] }
  set levels(__a__: any) {
    setDirtyFlag()
    this['_levels'] = __a__
  }

  toJson(): string { return JSON.stringify(removeLeadingUnderscore(this)); }

  static fromJson(__a__: any): AccessLevels {
    disableDF()
    let cls = new AccessLevels()
    if(typeof __a__ === 'object' && __a__ != null) {
       cls.levels = array1Reader(DataAction.fromJson)(__a__['levels'])
    } else {
       doAlert(`AccessLevels: an attempt to initialize from ${__a__}`)
    }
    enableDF()
    return cls
  }
}

export class Auth0Connection {
  private '_id': string | null
  private '_issuer': string
  private '_clientid': string
  private '_secret': string

  constructor() {
    this['_id'] = null
    this['_issuer'] = ''
    this['_clientid'] = ''
    this['_secret'] = ''
  }
  get id(): string | null { return this['_id'] }
  set id(__a__: any) {
    if(__a__ == null) {
      if(this['_id'] == null) { return }
      setDirtyFlag()
      this['_id'] = null
      return
    } else {
      let __v__ = stringReader('')(__a__)
      if(!_.isEqual(__v__,this['_id'])) {
        setDirtyFlag()
        this['_id'] = __v__
      }
    }
  }
  get issuer(): string { return this['_issuer'] }
  set issuer(__a__: any) {
    let __v__ = stringReader('')(__a__)
    if(!_.isEqual(__v__,this['_issuer'])) {
      setDirtyFlag()
      this['_issuer'] = __v__
    }
  }
  get clientid(): string { return this['_clientid'] }
  set clientid(__a__: any) {
    let __v__ = stringReader('')(__a__)
    if(!_.isEqual(__v__,this['_clientid'])) {
      setDirtyFlag()
      this['_clientid'] = __v__
    }
  }
  get secret(): string { return this['_secret'] }
  set secret(__a__: any) {
    let __v__ = stringReader('')(__a__)
    if(!_.isEqual(__v__,this['_secret'])) {
      setDirtyFlag()
      this['_secret'] = __v__
    }
  }

  toJson(): string { return JSON.stringify(removeLeadingUnderscore(this)); }

  static fromJson(__a__: any): Auth0Connection {
    disableDF()
    let cls = new Auth0Connection()
    if(typeof __a__ === 'object' && __a__ != null) {
       cls.id = __a__['id'] == null ? null : __a__['id']
       cls.issuer = __a__['issuer']
       cls.clientid = __a__['clientid']
       cls.secret = __a__['secret']
    } else {
       doAlert(`Auth0Connection: an attempt to initialize from ${__a__}`)
    }
    enableDF()
    return cls
  }
}

export class AuthLogin {
  private '_domain': string
  private '_logo_url': string
  private '_primary': string
  private '_page_background': string

  constructor() {
    this['_domain'] = ''
    this['_logo_url'] = ''
    this['_primary'] = ''
    this['_page_background'] = ''
  }
  get domain(): string { return this['_domain'] }
  set domain(__a__: any) {
    let __v__ = stringReader('')(__a__)
    if(!_.isEqual(__v__,this['_domain'])) {
      setDirtyFlag()
      this['_domain'] = __v__
    }
  }
  get logo_url(): string { return this['_logo_url'] }
  set logo_url(__a__: any) {
    let __v__ = stringReader('')(__a__)
    if(!_.isEqual(__v__,this['_logo_url'])) {
      setDirtyFlag()
      this['_logo_url'] = __v__
    }
  }
  get primary(): string { return this['_primary'] }
  set primary(__a__: any) {
    let __v__ = stringReader('')(__a__)
    if(!_.isEqual(__v__,this['_primary'])) {
      setDirtyFlag()
      this['_primary'] = __v__
    }
  }
  get page_background(): string { return this['_page_background'] }
  set page_background(__a__: any) {
    let __v__ = stringReader('')(__a__)
    if(!_.isEqual(__v__,this['_page_background'])) {
      setDirtyFlag()
      this['_page_background'] = __v__
    }
  }

  toJson(): string { return JSON.stringify(removeLeadingUnderscore(this)); }

  static fromJson(__a__: any): AuthLogin {
    disableDF()
    let cls = new AuthLogin()
    if(typeof __a__ === 'object' && __a__ != null) {
       cls.domain = __a__['domain']
       cls.logo_url = __a__['logo_url']
       cls.primary = __a__['primary']
       cls.page_background = __a__['page_background']
    } else {
       doAlert(`AuthLogin: an attempt to initialize from ${__a__}`)
    }
    enableDF()
    return cls
  }
}

export class AuthStatus {
  private '_status': string
  private '_errormessage': string
  private '_token': string

  constructor() {
    this['_status'] = ''
    this['_errormessage'] = ''
    this['_token'] = ''
  }
  get status(): string { return this['_status'] }
  set status(__a__: any) {
    let __v__ = stringReader('')(__a__)
    if(!_.isEqual(__v__,this['_status'])) {
      setDirtyFlag()
      this['_status'] = __v__
    }
  }
  get errormessage(): string { return this['_errormessage'] }
  set errormessage(__a__: any) {
    let __v__ = stringReader('')(__a__)
    if(!_.isEqual(__v__,this['_errormessage'])) {
      setDirtyFlag()
      this['_errormessage'] = __v__
    }
  }
  get token(): string { return this['_token'] }
  set token(__a__: any) {
    let __v__ = stringReader('')(__a__)
    if(!_.isEqual(__v__,this['_token'])) {
      setDirtyFlag()
      this['_token'] = __v__
    }
  }

  toJson(): string { return JSON.stringify(removeLeadingUnderscore(this)); }

  static fromJson(__a__: any): AuthStatus {
    disableDF()
    let cls = new AuthStatus()
    if(typeof __a__ === 'object' && __a__ != null) {
       cls.status = __a__['status']
       cls.errormessage = __a__['errormessage']
       cls.token = __a__['token']
    } else {
       doAlert(`AuthStatus: an attempt to initialize from ${__a__}`)
    }
    enableDF()
    return cls
  }
}

export class ConnectionRecord {
  private '_name': string
  private '_dbtype': string
  private '_usesconnector': boolean
  private '_connectorname': string
  private '_connectorid': string
  private '_tunnelname': string
  private '_tunnelid': string
  private '_address': string
  private '_port': number
  private '_dbname': string
  private '_useTLS': boolean
  private '_description': string
  private '_username': string | null
  private '_password': string | null
  private '_id': string | null
  private '_credid': string | null

  constructor() {
    this['_name'] = ''
    this['_dbtype'] = ''
    this['_usesconnector'] = false
    this['_connectorname'] = ''
    this['_connectorid'] = ''
    this['_tunnelname'] = ''
    this['_tunnelid'] = ''
    this['_address'] = ''
    this['_port'] = 0
    this['_dbname'] = ''
    this['_useTLS'] = false
    this['_description'] = ''
    this['_username'] = null
    this['_password'] = null
    this['_id'] = null
    this['_credid'] = null
  }
  get name(): string { return this['_name'] }
  set name(__a__: any) {
    let __v__ = stringReader('')(__a__)
    if(!_.isEqual(__v__,this['_name'])) {
      setDirtyFlag()
      this['_name'] = __v__
    }
  }
  get dbtype(): string { return this['_dbtype'] }
  set dbtype(__a__: any) {
    let __v__ = stringReader('')(__a__)
    if(!_.isEqual(__v__,this['_dbtype'])) {
      setDirtyFlag()
      this['_dbtype'] = __v__
    }
  }
  get usesconnector(): boolean { return this['_usesconnector'] }
  set usesconnector(__a__: any) {
    let __v__ = boolReader(false)(__a__)
    if(!_.isEqual(__v__,this['_usesconnector'])) {
      setDirtyFlag()
      this['_usesconnector'] = __v__
    }
  }
  get connectorname(): string { return this['_connectorname'] }
  set connectorname(__a__: any) {
    let __v__ = stringReader('')(__a__)
    if(!_.isEqual(__v__,this['_connectorname'])) {
      setDirtyFlag()
      this['_connectorname'] = __v__
    }
  }
  get connectorid(): string { return this['_connectorid'] }
  set connectorid(__a__: any) {
    let __v__ = stringReader('')(__a__)
    if(!_.isEqual(__v__,this['_connectorid'])) {
      setDirtyFlag()
      this['_connectorid'] = __v__
    }
  }
  get tunnelname(): string { return this['_tunnelname'] }
  set tunnelname(__a__: any) {
    let __v__ = stringReader('')(__a__)
    if(!_.isEqual(__v__,this['_tunnelname'])) {
      setDirtyFlag()
      this['_tunnelname'] = __v__
    }
  }
  get tunnelid(): string { return this['_tunnelid'] }
  set tunnelid(__a__: any) {
    let __v__ = stringReader('')(__a__)
    if(!_.isEqual(__v__,this['_tunnelid'])) {
      setDirtyFlag()
      this['_tunnelid'] = __v__
    }
  }
  get address(): string { return this['_address'] }
  set address(__a__: any) {
    let __v__ = stringReader('')(__a__)
    if(!_.isEqual(__v__,this['_address'])) {
      setDirtyFlag()
      this['_address'] = __v__
    }
  }
  get port(): number { return this['_port'] }
  set port(__a__: any) {
    let __v__ = intReader(0)(__a__)
    if(!_.isEqual(__v__,this['_port'])) {
      setDirtyFlag()
      this['_port'] = __v__
    }
  }
  get dbname(): string { return this['_dbname'] }
  set dbname(__a__: any) {
    let __v__ = stringReader('')(__a__)
    if(!_.isEqual(__v__,this['_dbname'])) {
      setDirtyFlag()
      this['_dbname'] = __v__
    }
  }
  get useTLS(): boolean { return this['_useTLS'] }
  set useTLS(__a__: any) {
    let __v__ = boolReader(false)(__a__)
    if(!_.isEqual(__v__,this['_useTLS'])) {
      setDirtyFlag()
      this['_useTLS'] = __v__
    }
  }
  get description(): string { return this['_description'] }
  set description(__a__: any) {
    let __v__ = stringReader('')(__a__)
    if(!_.isEqual(__v__,this['_description'])) {
      setDirtyFlag()
      this['_description'] = __v__
    }
  }
  get username(): string | null { return this['_username'] }
  set username(__a__: any) {
    if(__a__ == null) {
      if(this['_username'] == null) { return }
      setDirtyFlag()
      this['_username'] = null
      return
    } else {
      let __v__ = stringReader('')(__a__)
      if(!_.isEqual(__v__,this['_username'])) {
        setDirtyFlag()
        this['_username'] = __v__
      }
    }
  }
  get password(): string | null { return this['_password'] }
  set password(__a__: any) {
    if(__a__ == null) {
      if(this['_password'] == null) { return }
      setDirtyFlag()
      this['_password'] = null
      return
    } else {
      let __v__ = stringReader('')(__a__)
      if(!_.isEqual(__v__,this['_password'])) {
        setDirtyFlag()
        this['_password'] = __v__
      }
    }
  }
  get id(): string | null { return this['_id'] }
  set id(__a__: any) {
    if(__a__ == null) {
      if(this['_id'] == null) { return }
      setDirtyFlag()
      this['_id'] = null
      return
    } else {
      let __v__ = stringReader('')(__a__)
      if(!_.isEqual(__v__,this['_id'])) {
        setDirtyFlag()
        this['_id'] = __v__
      }
    }
  }
  get credid(): string | null { return this['_credid'] }
  set credid(__a__: any) {
    if(__a__ == null) {
      if(this['_credid'] == null) { return }
      setDirtyFlag()
      this['_credid'] = null
      return
    } else {
      let __v__ = stringReader('')(__a__)
      if(!_.isEqual(__v__,this['_credid'])) {
        setDirtyFlag()
        this['_credid'] = __v__
      }
    }
  }

  toJson(): string { return JSON.stringify(removeLeadingUnderscore(this)); }

  static fromJson(__a__: any): ConnectionRecord {
    disableDF()
    let cls = new ConnectionRecord()
    if(typeof __a__ === 'object' && __a__ != null) {
       cls.name = __a__['name']
       cls.dbtype = __a__['dbtype']
       cls.usesconnector = __a__['usesconnector']
       cls.connectorname = __a__['connectorname']
       cls.connectorid = __a__['connectorid']
       cls.tunnelname = __a__['tunnelname']
       cls.tunnelid = __a__['tunnelid']
       cls.address = __a__['address']
       cls.port = __a__['port']
       cls.dbname = __a__['dbname']
       cls.useTLS = __a__['useTLS']
       cls.description = __a__['description']
       cls.username = __a__['username'] == null ? null : __a__['username']
       cls.password = __a__['password'] == null ? null : __a__['password']
       cls.id = __a__['id'] == null ? null : __a__['id']
       cls.credid = __a__['credid'] == null ? null : __a__['credid']
    } else {
       doAlert(`ConnectionRecord: an attempt to initialize from ${__a__}`)
    }
    enableDF()
    return cls
  }
}

export class ConnectionResponse {
  private '_status': string
  private '_errormessage': string
  private '_data': Array<ConnectionRecord>

  constructor() {
    this['_status'] = ''
    this['_errormessage'] = ''
    this['_data'] = []
  }
  get status(): string { return this['_status'] }
  set status(__a__: any) {
    let __v__ = stringReader('')(__a__)
    if(!_.isEqual(__v__,this['_status'])) {
      setDirtyFlag()
      this['_status'] = __v__
    }
  }
  get errormessage(): string { return this['_errormessage'] }
  set errormessage(__a__: any) {
    let __v__ = stringReader('')(__a__)
    if(!_.isEqual(__v__,this['_errormessage'])) {
      setDirtyFlag()
      this['_errormessage'] = __v__
    }
  }
  get data(): Array<ConnectionRecord> { return this['_data'] }
  set data(__a__: any) {
    setDirtyFlag()
    this['_data'] = __a__
  }

  toJson(): string { return JSON.stringify(removeLeadingUnderscore(this)); }

  static fromJson(__a__: any): ConnectionResponse {
    disableDF()
    let cls = new ConnectionResponse()
    if(typeof __a__ === 'object' && __a__ != null) {
       cls.status = __a__['status']
       cls.errormessage = __a__['errormessage']
       cls.data = array1Reader(ConnectionRecord.fromJson)(__a__['data'])
    } else {
       doAlert(`ConnectionResponse: an attempt to initialize from ${__a__}`)
    }
    enableDF()
    return cls
  }
}

export class ConnectionsQuery {
  private '_status': string
  private '_errormessage': string | null
  private '_records': Array<ConnectionRecord> | null

  constructor() {
    this['_status'] = ''
    this['_errormessage'] = null
    this['_records'] = null
  }
  get status(): string { return this['_status'] }
  set status(__a__: any) {
    let __v__ = stringReader('')(__a__)
    if(!_.isEqual(__v__,this['_status'])) {
      setDirtyFlag()
      this['_status'] = __v__
    }
  }
  get errormessage(): string | null { return this['_errormessage'] }
  set errormessage(__a__: any) {
    if(__a__ == null) {
      if(this['_errormessage'] == null) { return }
      setDirtyFlag()
      this['_errormessage'] = null
      return
    } else {
      let __v__ = stringReader('')(__a__)
      if(!_.isEqual(__v__,this['_errormessage'])) {
        setDirtyFlag()
        this['_errormessage'] = __v__
      }
    }
  }
  get records(): Array<ConnectionRecord> | null { return this['_records'] }
  set records(__a__: any) {
    setDirtyFlag()
    this['_records'] = __a__
  }

  toJson(): string { return JSON.stringify(removeLeadingUnderscore(this)); }

  static fromJson(__a__: any): ConnectionsQuery {
    disableDF()
    let cls = new ConnectionsQuery()
    if(typeof __a__ === 'object' && __a__ != null) {
       cls.status = __a__['status']
       cls.errormessage = __a__['errormessage'] == null ? null : __a__['errormessage']
       cls.records = __a__['records'] == null ? null : array1Reader(ConnectionRecord.fromJson)(__a__['records'])
    } else {
       doAlert(`ConnectionsQuery: an attempt to initialize from ${__a__}`)
    }
    enableDF()
    return cls
  }
}

export class DataAction {
  private '_role': string
  private '_index': number | null
  private '_handling': DataHandling

  constructor() {
    this['_role'] = ''
    this['_index'] = null
    this['_handling'] = 'allow'
  }
  get role(): string { return this['_role'] }
  set role(__a__: any) {
    let __v__ = stringReader('')(__a__)
    if(!_.isEqual(__v__,this['_role'])) {
      setDirtyFlag()
      this['_role'] = __v__
    }
  }
  get index(): number | null { return this['_index'] }
  set index(__a__: any) {
    if(__a__ == null) {
      if(this['_index'] == null) { return }
      setDirtyFlag()
      this['_index'] = null
      return
    } else {
      let __v__ = intReader(0)(__a__)
      if(!_.isEqual(__v__,this['_index'])) {
        setDirtyFlag()
        this['_index'] = __v__
      }
    }
  }
  get handling(): DataHandling { return this['_handling'] }
  set handling(__a__: any) {
    let __v__ = enumReader(['allow','block','obfuscate','redact'],'allow')(__a__)
    if(!_.isEqual(__v__,this['_handling'])) {
      setDirtyFlag()
      this['_handling'] = __v__
    }
  }

  toJson(): string { return JSON.stringify(removeLeadingUnderscore(this)); }

  static fromJson(__a__: any): DataAction {
    disableDF()
    let cls = new DataAction()
    if(typeof __a__ === 'object' && __a__ != null) {
       cls.role = __a__['role']
       cls.index = __a__['index'] == null ? null : __a__['index']
       cls.handling = __a__['handling']
    } else {
       doAlert(`DataAction: an attempt to initialize from ${__a__}`)
    }
    enableDF()
    return cls
  }
}

export class DataPolicy {
  private '_actions': Array<DataAction>
  private '_piisuggestions': Array<PIISuggestor>

  constructor() {
    this['_actions'] = []
    this['_piisuggestions'] = []
  }
  get actions(): Array<DataAction> { return this['_actions'] }
  set actions(__a__: any) {
    setDirtyFlag()
    this['_actions'] = __a__
  }
  get piisuggestions(): Array<PIISuggestor> { return this['_piisuggestions'] }
  set piisuggestions(__a__: any) {
    setDirtyFlag()
    this['_piisuggestions'] = __a__
  }

  toJson(): string { return JSON.stringify(removeLeadingUnderscore(this)); }

  static fromJson(__a__: any): DataPolicy {
    disableDF()
    let cls = new DataPolicy()
    if(typeof __a__ === 'object' && __a__ != null) {
       cls.actions = array1Reader(DataAction.fromJson)(__a__['actions'])
       cls.piisuggestions = array1Reader(PIISuggestor.fromJson)(__a__['piisuggestions'])
    } else {
       doAlert(`DataPolicy: an attempt to initialize from ${__a__}`)
    }
    enableDF()
    return cls
  }
}

export class Datascope {
  private '_name': string
  private '_id': string | null
  private '_groupsconfigured': boolean
  private '_description': string
  private '_tables': Array<DatascopeTable>
  private '_records': Array<DatascopeRecord>

  constructor() {
    this['_name'] = ''
    this['_id'] = null
    this['_groupsconfigured'] = false
    this['_description'] = ''
    this['_tables'] = []
    this['_records'] = []
  }
  get name(): string { return this['_name'] }
  set name(__a__: any) {
    let __v__ = stringReader('')(__a__)
    if(!_.isEqual(__v__,this['_name'])) {
      setDirtyFlag()
      this['_name'] = __v__
    }
  }
  get id(): string | null { return this['_id'] }
  set id(__a__: any) {
    if(__a__ == null) {
      if(this['_id'] == null) { return }
      setDirtyFlag()
      this['_id'] = null
      return
    } else {
      let __v__ = stringReader('')(__a__)
      if(!_.isEqual(__v__,this['_id'])) {
        setDirtyFlag()
        this['_id'] = __v__
      }
    }
  }
  get groupsconfigured(): boolean { return this['_groupsconfigured'] }
  set groupsconfigured(__a__: any) {
    let __v__ = boolReader(false)(__a__)
    if(!_.isEqual(__v__,this['_groupsconfigured'])) {
      setDirtyFlag()
      this['_groupsconfigured'] = __v__
    }
  }
  get description(): string { return this['_description'] }
  set description(__a__: any) {
    let __v__ = stringReader('')(__a__)
    if(!_.isEqual(__v__,this['_description'])) {
      setDirtyFlag()
      this['_description'] = __v__
    }
  }
  get tables(): Array<DatascopeTable> { return this['_tables'] }
  set tables(__a__: any) {
    setDirtyFlag()
    this['_tables'] = __a__
  }
  get records(): Array<DatascopeRecord> { return this['_records'] }
  set records(__a__: any) {
    setDirtyFlag()
    this['_records'] = __a__
  }

  toJson(): string { return JSON.stringify(removeLeadingUnderscore(this)); }

  static fromJson(__a__: any): Datascope {
    disableDF()
    let cls = new Datascope()
    if(typeof __a__ === 'object' && __a__ != null) {
       cls.name = __a__['name']
       cls.id = __a__['id'] == null ? null : __a__['id']
       cls.groupsconfigured = __a__['groupsconfigured']
       cls.description = __a__['description']
       cls.tables = array1Reader(DatascopeTable.fromJson)(__a__['tables'])
       cls.records = array1Reader(DatascopeRecord.fromJson)(__a__['records'])
    } else {
       doAlert(`Datascope: an attempt to initialize from ${__a__}`)
    }
    enableDF()
    return cls
  }
}

export class DatascopeAndGroups {
  private '_id': string
  private '_name': string
  private '_groupid': string
  private '_groupname': string

  constructor() {
    this['_id'] = ''
    this['_name'] = ''
    this['_groupid'] = ''
    this['_groupname'] = ''
  }
  get id(): string { return this['_id'] }
  set id(__a__: any) {
    let __v__ = stringReader('')(__a__)
    if(!_.isEqual(__v__,this['_id'])) {
      setDirtyFlag()
      this['_id'] = __v__
    }
  }
  get name(): string { return this['_name'] }
  set name(__a__: any) {
    let __v__ = stringReader('')(__a__)
    if(!_.isEqual(__v__,this['_name'])) {
      setDirtyFlag()
      this['_name'] = __v__
    }
  }
  get groupid(): string { return this['_groupid'] }
  set groupid(__a__: any) {
    let __v__ = stringReader('')(__a__)
    if(!_.isEqual(__v__,this['_groupid'])) {
      setDirtyFlag()
      this['_groupid'] = __v__
    }
  }
  get groupname(): string { return this['_groupname'] }
  set groupname(__a__: any) {
    let __v__ = stringReader('')(__a__)
    if(!_.isEqual(__v__,this['_groupname'])) {
      setDirtyFlag()
      this['_groupname'] = __v__
    }
  }

  toJson(): string { return JSON.stringify(removeLeadingUnderscore(this)); }

  static fromJson(__a__: any): DatascopeAndGroups {
    disableDF()
    let cls = new DatascopeAndGroups()
    if(typeof __a__ === 'object' && __a__ != null) {
       cls.id = __a__['id']
       cls.name = __a__['name']
       cls.groupid = __a__['groupid']
       cls.groupname = __a__['groupname']
    } else {
       doAlert(`DatascopeAndGroups: an attempt to initialize from ${__a__}`)
    }
    enableDF()
    return cls
  }
}

export class DatascopeId {
  private '_id': string

  constructor() {
    this['_id'] = ''
  }
  get id(): string { return this['_id'] }
  set id(__a__: any) {
    let __v__ = stringReader('')(__a__)
    if(!_.isEqual(__v__,this['_id'])) {
      setDirtyFlag()
      this['_id'] = __v__
    }
  }

  toJson(): string { return JSON.stringify(removeLeadingUnderscore(this)); }

  static fromJson(__a__: any): DatascopeId {
    disableDF()
    let cls = new DatascopeId()
    if(typeof __a__ === 'object' && __a__ != null) {
       cls.id = __a__['id']
    } else {
       doAlert(`DatascopeId: an attempt to initialize from ${__a__}`)
    }
    enableDF()
    return cls
  }
}

export class DatascopeIdName {
  private '_name': string
  private '_id': string
  private '_created': string
  private '_modified': string
  private '_description': string

  constructor() {
    this['_name'] = ''
    this['_id'] = ''
    this['_created'] = ''
    this['_modified'] = ''
    this['_description'] = ''
  }
  get name(): string { return this['_name'] }
  set name(__a__: any) {
    let __v__ = stringReader('')(__a__)
    if(!_.isEqual(__v__,this['_name'])) {
      setDirtyFlag()
      this['_name'] = __v__
    }
  }
  get id(): string { return this['_id'] }
  set id(__a__: any) {
    let __v__ = stringReader('')(__a__)
    if(!_.isEqual(__v__,this['_id'])) {
      setDirtyFlag()
      this['_id'] = __v__
    }
  }
  get created(): string { return this['_created'] }
  set created(__a__: any) {
    let __v__ = stringReader('')(__a__)
    if(!_.isEqual(__v__,this['_created'])) {
      setDirtyFlag()
      this['_created'] = __v__
    }
  }
  get modified(): string { return this['_modified'] }
  set modified(__a__: any) {
    let __v__ = stringReader('')(__a__)
    if(!_.isEqual(__v__,this['_modified'])) {
      setDirtyFlag()
      this['_modified'] = __v__
    }
  }
  get description(): string { return this['_description'] }
  set description(__a__: any) {
    let __v__ = stringReader('')(__a__)
    if(!_.isEqual(__v__,this['_description'])) {
      setDirtyFlag()
      this['_description'] = __v__
    }
  }

  toJson(): string { return JSON.stringify(removeLeadingUnderscore(this)); }

  static fromJson(__a__: any): DatascopeIdName {
    disableDF()
    let cls = new DatascopeIdName()
    if(typeof __a__ === 'object' && __a__ != null) {
       cls.name = __a__['name']
       cls.id = __a__['id']
       cls.created = __a__['created']
       cls.modified = __a__['modified']
       cls.description = __a__['description']
    } else {
       doAlert(`DatascopeIdName: an attempt to initialize from ${__a__}`)
    }
    enableDF()
    return cls
  }
}

export class DatascopeInfoStatus {
  private '_status': string
  private '_errormessage': string
  private '_record': Datascope | null

  constructor() {
    this['_status'] = ''
    this['_errormessage'] = ''
    this['_record'] = null
  }
  get status(): string { return this['_status'] }
  set status(__a__: any) {
    let __v__ = stringReader('')(__a__)
    if(!_.isEqual(__v__,this['_status'])) {
      setDirtyFlag()
      this['_status'] = __v__
    }
  }
  get errormessage(): string { return this['_errormessage'] }
  set errormessage(__a__: any) {
    let __v__ = stringReader('')(__a__)
    if(!_.isEqual(__v__,this['_errormessage'])) {
      setDirtyFlag()
      this['_errormessage'] = __v__
    }
  }
  get record(): Datascope | null { return this['_record'] }
  set record(__a__: any) {
    if(__a__ == null) {
      if(this['_record'] == null) { return }
      setDirtyFlag()
      this['_record'] = null
      return
    } else {
      setDirtyFlag()
      this['_record'] = __a__
    }
  }

  toJson(): string { return JSON.stringify(removeLeadingUnderscore(this)); }

  static fromJson(__a__: any): DatascopeInfoStatus {
    disableDF()
    let cls = new DatascopeInfoStatus()
    if(typeof __a__ === 'object' && __a__ != null) {
       cls.status = __a__['status']
       cls.errormessage = __a__['errormessage']
       cls.record = __a__['record'] == null ? null : Datascope.fromJson(__a__['record'])
    } else {
       doAlert(`DatascopeInfoStatus: an attempt to initialize from ${__a__}`)
    }
    enableDF()
    return cls
  }
}

export class DatascopeRecord {
  private '_id': string | null
  private '_tableId': string
  private '_connection': string
  private '_connectionId': string | null
  private '_schema': string
  private '_ghostSchema': string
  private '_table': string
  private '_ghostTable': string
  private '_typ': string
  private '_ghostTyp': string
  private '_position': number
  private '_reference': Reference | null
  private '_action': string
  private '_col': string
  private '_ghostCol': string
  private '_semantics': string
  private '_description': string
  private '_dflt': string | null
  private '_isnullable': boolean
  private '_possibleActions': Array<string>

  constructor() {
    this['_id'] = null
    this['_tableId'] = ''
    this['_connection'] = ''
    this['_connectionId'] = null
    this['_schema'] = ''
    this['_ghostSchema'] = ''
    this['_table'] = ''
    this['_ghostTable'] = ''
    this['_typ'] = ''
    this['_ghostTyp'] = ''
    this['_position'] = 0
    this['_reference'] = null
    this['_action'] = ''
    this['_col'] = ''
    this['_ghostCol'] = ''
    this['_semantics'] = ''
    this['_description'] = ''
    this['_dflt'] = null
    this['_isnullable'] = false
    this['_possibleActions'] = []
  }
  get id(): string | null { return this['_id'] }
  set id(__a__: any) {
    if(__a__ == null) {
      if(this['_id'] == null) { return }
      setDirtyFlag()
      this['_id'] = null
      return
    } else {
      let __v__ = stringReader('')(__a__)
      if(!_.isEqual(__v__,this['_id'])) {
        setDirtyFlag()
        this['_id'] = __v__
      }
    }
  }
  get tableId(): string { return this['_tableId'] }
  set tableId(__a__: any) {
    let __v__ = stringReader('')(__a__)
    if(!_.isEqual(__v__,this['_tableId'])) {
      setDirtyFlag()
      this['_tableId'] = __v__
    }
  }
  get connection(): string { return this['_connection'] }
  set connection(__a__: any) {
    let __v__ = stringReader('')(__a__)
    if(!_.isEqual(__v__,this['_connection'])) {
      setDirtyFlag()
      this['_connection'] = __v__
    }
  }
  get connectionId(): string | null { return this['_connectionId'] }
  set connectionId(__a__: any) {
    if(__a__ == null) {
      if(this['_connectionId'] == null) { return }
      setDirtyFlag()
      this['_connectionId'] = null
      return
    } else {
      let __v__ = stringReader('')(__a__)
      if(!_.isEqual(__v__,this['_connectionId'])) {
        setDirtyFlag()
        this['_connectionId'] = __v__
      }
    }
  }
  get schema(): string { return this['_schema'] }
  set schema(__a__: any) {
    let __v__ = stringReader('')(__a__)
    if(!_.isEqual(__v__,this['_schema'])) {
      setDirtyFlag()
      this['_schema'] = __v__
    }
  }
  get ghostSchema(): string { return this['_ghostSchema'] }
  set ghostSchema(__a__: any) {
    let __v__ = stringReader('')(__a__)
    if(!_.isEqual(__v__,this['_ghostSchema'])) {
      setDirtyFlag()
      this['_ghostSchema'] = __v__
    }
  }
  get table(): string { return this['_table'] }
  set table(__a__: any) {
    let __v__ = stringReader('')(__a__)
    if(!_.isEqual(__v__,this['_table'])) {
      setDirtyFlag()
      this['_table'] = __v__
    }
  }
  get ghostTable(): string { return this['_ghostTable'] }
  set ghostTable(__a__: any) {
    let __v__ = stringReader('')(__a__)
    if(!_.isEqual(__v__,this['_ghostTable'])) {
      setDirtyFlag()
      this['_ghostTable'] = __v__
    }
  }
  get typ(): string { return this['_typ'] }
  set typ(__a__: any) {
    let __v__ = stringReader('')(__a__)
    if(!_.isEqual(__v__,this['_typ'])) {
      setDirtyFlag()
      this['_typ'] = __v__
    }
  }
  get ghostTyp(): string { return this['_ghostTyp'] }
  set ghostTyp(__a__: any) {
    let __v__ = stringReader('')(__a__)
    if(!_.isEqual(__v__,this['_ghostTyp'])) {
      setDirtyFlag()
      this['_ghostTyp'] = __v__
    }
  }
  get position(): number { return this['_position'] }
  set position(__a__: any) {
    let __v__ = intReader(0)(__a__)
    if(!_.isEqual(__v__,this['_position'])) {
      setDirtyFlag()
      this['_position'] = __v__
    }
  }
  get reference(): Reference | null { return this['_reference'] }
  set reference(__a__: any) {
    if(__a__ == null) {
      if(this['_reference'] == null) { return }
      setDirtyFlag()
      this['_reference'] = null
      return
    } else {
      setDirtyFlag()
      this['_reference'] = __a__
    }
  }
  get action(): string { return this['_action'] }
  set action(__a__: any) {
    let __v__ = stringReader('')(__a__)
    if(!_.isEqual(__v__,this['_action'])) {
      setDirtyFlag()
      this['_action'] = __v__
    }
  }
  get col(): string { return this['_col'] }
  set col(__a__: any) {
    let __v__ = stringReader('')(__a__)
    if(!_.isEqual(__v__,this['_col'])) {
      setDirtyFlag()
      this['_col'] = __v__
    }
  }
  get ghostCol(): string { return this['_ghostCol'] }
  set ghostCol(__a__: any) {
    let __v__ = stringReader('')(__a__)
    if(!_.isEqual(__v__,this['_ghostCol'])) {
      setDirtyFlag()
      this['_ghostCol'] = __v__
    }
  }
  get semantics(): string { return this['_semantics'] }
  set semantics(__a__: any) {
    let __v__ = stringReader('')(__a__)
    if(!_.isEqual(__v__,this['_semantics'])) {
      setDirtyFlag()
      this['_semantics'] = __v__
    }
  }
  get description(): string { return this['_description'] }
  set description(__a__: any) {
    let __v__ = stringReader('')(__a__)
    if(!_.isEqual(__v__,this['_description'])) {
      setDirtyFlag()
      this['_description'] = __v__
    }
  }
  get dflt(): string | null { return this['_dflt'] }
  set dflt(__a__: any) {
    if(__a__ == null) {
      if(this['_dflt'] == null) { return }
      setDirtyFlag()
      this['_dflt'] = null
      return
    } else {
      let __v__ = stringReader('')(__a__)
      if(!_.isEqual(__v__,this['_dflt'])) {
        setDirtyFlag()
        this['_dflt'] = __v__
      }
    }
  }
  get isnullable(): boolean { return this['_isnullable'] }
  set isnullable(__a__: any) {
    let __v__ = boolReader(false)(__a__)
    if(!_.isEqual(__v__,this['_isnullable'])) {
      setDirtyFlag()
      this['_isnullable'] = __v__
    }
  }
  get possibleActions(): Array<string> { return this['_possibleActions'] }
  set possibleActions(__a__: any) {
    setDirtyFlag()
    this['_possibleActions'] = __a__
  }

  toJson(): string { return JSON.stringify(removeLeadingUnderscore(this)); }

  static fromJson(__a__: any): DatascopeRecord {
    disableDF()
    let cls = new DatascopeRecord()
    if(typeof __a__ === 'object' && __a__ != null) {
       cls.id = __a__['id'] == null ? null : __a__['id']
       cls.tableId = __a__['tableId']
       cls.connection = __a__['connection']
       cls.connectionId = __a__['connectionId'] == null ? null : __a__['connectionId']
       cls.schema = __a__['schema']
       cls.ghostSchema = __a__['ghostSchema']
       cls.table = __a__['table']
       cls.ghostTable = __a__['ghostTable']
       cls.typ = __a__['typ']
       cls.ghostTyp = __a__['ghostTyp']
       cls.position = __a__['position']
       cls.reference = __a__['reference'] == null ? null : Reference.fromJson(__a__['reference'])
       cls.action = __a__['action']
       cls.col = __a__['col']
       cls.ghostCol = __a__['ghostCol']
       cls.semantics = __a__['semantics']
       cls.description = __a__['description']
       cls.dflt = __a__['dflt'] == null ? null : __a__['dflt']
       cls.isnullable = __a__['isnullable']
       cls.possibleActions = array1Reader(stringReader(''))(__a__['possibleActions'])
    } else {
       doAlert(`DatascopeRecord: an attempt to initialize from ${__a__}`)
    }
    enableDF()
    return cls
  }
}

export class DatascopeTable {
  private '_connection': string
  private '_datascope': string
  private '_id': string
  private '_connectionId': string | null
  private '_datascopeId': string | null
  private '_schema': string
  private '_ghostSchema': string
  private '_table': string
  private '_ghostTable': string
  private '_description': string

  constructor() {
    this['_connection'] = ''
    this['_datascope'] = ''
    this['_id'] = ''
    this['_connectionId'] = null
    this['_datascopeId'] = null
    this['_schema'] = ''
    this['_ghostSchema'] = ''
    this['_table'] = ''
    this['_ghostTable'] = ''
    this['_description'] = ''
  }
  get connection(): string { return this['_connection'] }
  set connection(__a__: any) {
    let __v__ = stringReader('')(__a__)
    if(!_.isEqual(__v__,this['_connection'])) {
      setDirtyFlag()
      this['_connection'] = __v__
    }
  }
  get datascope(): string { return this['_datascope'] }
  set datascope(__a__: any) {
    let __v__ = stringReader('')(__a__)
    if(!_.isEqual(__v__,this['_datascope'])) {
      setDirtyFlag()
      this['_datascope'] = __v__
    }
  }
  get id(): string { return this['_id'] }
  set id(__a__: any) {
    let __v__ = stringReader('')(__a__)
    if(!_.isEqual(__v__,this['_id'])) {
      setDirtyFlag()
      this['_id'] = __v__
    }
  }
  get connectionId(): string | null { return this['_connectionId'] }
  set connectionId(__a__: any) {
    if(__a__ == null) {
      if(this['_connectionId'] == null) { return }
      setDirtyFlag()
      this['_connectionId'] = null
      return
    } else {
      let __v__ = stringReader('')(__a__)
      if(!_.isEqual(__v__,this['_connectionId'])) {
        setDirtyFlag()
        this['_connectionId'] = __v__
      }
    }
  }
  get datascopeId(): string | null { return this['_datascopeId'] }
  set datascopeId(__a__: any) {
    if(__a__ == null) {
      if(this['_datascopeId'] == null) { return }
      setDirtyFlag()
      this['_datascopeId'] = null
      return
    } else {
      let __v__ = stringReader('')(__a__)
      if(!_.isEqual(__v__,this['_datascopeId'])) {
        setDirtyFlag()
        this['_datascopeId'] = __v__
      }
    }
  }
  get schema(): string { return this['_schema'] }
  set schema(__a__: any) {
    let __v__ = stringReader('')(__a__)
    if(!_.isEqual(__v__,this['_schema'])) {
      setDirtyFlag()
      this['_schema'] = __v__
    }
  }
  get ghostSchema(): string { return this['_ghostSchema'] }
  set ghostSchema(__a__: any) {
    let __v__ = stringReader('')(__a__)
    if(!_.isEqual(__v__,this['_ghostSchema'])) {
      setDirtyFlag()
      this['_ghostSchema'] = __v__
    }
  }
  get table(): string { return this['_table'] }
  set table(__a__: any) {
    let __v__ = stringReader('')(__a__)
    if(!_.isEqual(__v__,this['_table'])) {
      setDirtyFlag()
      this['_table'] = __v__
    }
  }
  get ghostTable(): string { return this['_ghostTable'] }
  set ghostTable(__a__: any) {
    let __v__ = stringReader('')(__a__)
    if(!_.isEqual(__v__,this['_ghostTable'])) {
      setDirtyFlag()
      this['_ghostTable'] = __v__
    }
  }
  get description(): string { return this['_description'] }
  set description(__a__: any) {
    let __v__ = stringReader('')(__a__)
    if(!_.isEqual(__v__,this['_description'])) {
      setDirtyFlag()
      this['_description'] = __v__
    }
  }

  toJson(): string { return JSON.stringify(removeLeadingUnderscore(this)); }

  static fromJson(__a__: any): DatascopeTable {
    disableDF()
    let cls = new DatascopeTable()
    if(typeof __a__ === 'object' && __a__ != null) {
       cls.connection = __a__['connection']
       cls.datascope = __a__['datascope']
       cls.id = __a__['id']
       cls.connectionId = __a__['connectionId'] == null ? null : __a__['connectionId']
       cls.datascopeId = __a__['datascopeId'] == null ? null : __a__['datascopeId']
       cls.schema = __a__['schema']
       cls.ghostSchema = __a__['ghostSchema']
       cls.table = __a__['table']
       cls.ghostTable = __a__['ghostTable']
       cls.description = __a__['description']
    } else {
       doAlert(`DatascopeTable: an attempt to initialize from ${__a__}`)
    }
    enableDF()
    return cls
  }
}

export class DatascopeTables {
  private '_status': string
  private '_errormessage': string
  private '_tables': Array<DatascopeTable>

  constructor() {
    this['_status'] = ''
    this['_errormessage'] = ''
    this['_tables'] = []
  }
  get status(): string { return this['_status'] }
  set status(__a__: any) {
    let __v__ = stringReader('')(__a__)
    if(!_.isEqual(__v__,this['_status'])) {
      setDirtyFlag()
      this['_status'] = __v__
    }
  }
  get errormessage(): string { return this['_errormessage'] }
  set errormessage(__a__: any) {
    let __v__ = stringReader('')(__a__)
    if(!_.isEqual(__v__,this['_errormessage'])) {
      setDirtyFlag()
      this['_errormessage'] = __v__
    }
  }
  get tables(): Array<DatascopeTable> { return this['_tables'] }
  set tables(__a__: any) {
    setDirtyFlag()
    this['_tables'] = __a__
  }

  toJson(): string { return JSON.stringify(removeLeadingUnderscore(this)); }

  static fromJson(__a__: any): DatascopeTables {
    disableDF()
    let cls = new DatascopeTables()
    if(typeof __a__ === 'object' && __a__ != null) {
       cls.status = __a__['status']
       cls.errormessage = __a__['errormessage']
       cls.tables = array1Reader(DatascopeTable.fromJson)(__a__['tables'])
    } else {
       doAlert(`DatascopeTables: an attempt to initialize from ${__a__}`)
    }
    enableDF()
    return cls
  }
}

export class DatascopesStatus {
  private '_status': string
  private '_errormessage': string
  private '_records': Array<DatascopeIdName>

  constructor() {
    this['_status'] = ''
    this['_errormessage'] = ''
    this['_records'] = []
  }
  get status(): string { return this['_status'] }
  set status(__a__: any) {
    let __v__ = stringReader('')(__a__)
    if(!_.isEqual(__v__,this['_status'])) {
      setDirtyFlag()
      this['_status'] = __v__
    }
  }
  get errormessage(): string { return this['_errormessage'] }
  set errormessage(__a__: any) {
    let __v__ = stringReader('')(__a__)
    if(!_.isEqual(__v__,this['_errormessage'])) {
      setDirtyFlag()
      this['_errormessage'] = __v__
    }
  }
  get records(): Array<DatascopeIdName> { return this['_records'] }
  set records(__a__: any) {
    setDirtyFlag()
    this['_records'] = __a__
  }

  toJson(): string { return JSON.stringify(removeLeadingUnderscore(this)); }

  static fromJson(__a__: any): DatascopesStatus {
    disableDF()
    let cls = new DatascopesStatus()
    if(typeof __a__ === 'object' && __a__ != null) {
       cls.status = __a__['status']
       cls.errormessage = __a__['errormessage']
       cls.records = array1Reader(DatascopeIdName.fromJson)(__a__['records'])
    } else {
       doAlert(`DatascopesStatus: an attempt to initialize from ${__a__}`)
    }
    enableDF()
    return cls
  }
}

export class DockerDownloads {
  private '_meshconnector': string
  private '_machineclient': string

  constructor() {
    this['_meshconnector'] = ''
    this['_machineclient'] = ''
  }
  get meshconnector(): string { return this['_meshconnector'] }
  set meshconnector(__a__: any) {
    let __v__ = stringReader('')(__a__)
    if(!_.isEqual(__v__,this['_meshconnector'])) {
      setDirtyFlag()
      this['_meshconnector'] = __v__
    }
  }
  get machineclient(): string { return this['_machineclient'] }
  set machineclient(__a__: any) {
    let __v__ = stringReader('')(__a__)
    if(!_.isEqual(__v__,this['_machineclient'])) {
      setDirtyFlag()
      this['_machineclient'] = __v__
    }
  }

  toJson(): string { return JSON.stringify(removeLeadingUnderscore(this)); }

  static fromJson(__a__: any): DockerDownloads {
    disableDF()
    let cls = new DockerDownloads()
    if(typeof __a__ === 'object' && __a__ != null) {
       cls.meshconnector = __a__['meshconnector']
       cls.machineclient = __a__['machineclient']
    } else {
       doAlert(`DockerDownloads: an attempt to initialize from ${__a__}`)
    }
    enableDF()
    return cls
  }
}

export class GroupAssignment {
  private '_name': string
  private '_id': string
  private '_groups': Array<DatascopeIdName>

  constructor() {
    this['_name'] = ''
    this['_id'] = ''
    this['_groups'] = []
  }
  get name(): string { return this['_name'] }
  set name(__a__: any) {
    let __v__ = stringReader('')(__a__)
    if(!_.isEqual(__v__,this['_name'])) {
      setDirtyFlag()
      this['_name'] = __v__
    }
  }
  get id(): string { return this['_id'] }
  set id(__a__: any) {
    let __v__ = stringReader('')(__a__)
    if(!_.isEqual(__v__,this['_id'])) {
      setDirtyFlag()
      this['_id'] = __v__
    }
  }
  get groups(): Array<DatascopeIdName> { return this['_groups'] }
  set groups(__a__: any) {
    setDirtyFlag()
    this['_groups'] = __a__
  }

  toJson(): string { return JSON.stringify(removeLeadingUnderscore(this)); }

  static fromJson(__a__: any): GroupAssignment {
    disableDF()
    let cls = new GroupAssignment()
    if(typeof __a__ === 'object' && __a__ != null) {
       cls.name = __a__['name']
       cls.id = __a__['id']
       cls.groups = array1Reader(DatascopeIdName.fromJson)(__a__['groups'])
    } else {
       doAlert(`GroupAssignment: an attempt to initialize from ${__a__}`)
    }
    enableDF()
    return cls
  }
}

export class GroupMapping {
  private '_id': string | null
  private '_dymiumgroup': string
  private '_directorygroup': string
  private '_comments': string
  private '_adminaccess': boolean

  constructor() {
    this['_id'] = null
    this['_dymiumgroup'] = ''
    this['_directorygroup'] = ''
    this['_comments'] = ''
    this['_adminaccess'] = false
  }
  get id(): string | null { return this['_id'] }
  set id(__a__: any) {
    if(__a__ == null) {
      if(this['_id'] == null) { return }
      setDirtyFlag()
      this['_id'] = null
      return
    } else {
      let __v__ = stringReader('')(__a__)
      if(!_.isEqual(__v__,this['_id'])) {
        setDirtyFlag()
        this['_id'] = __v__
      }
    }
  }
  get dymiumgroup(): string { return this['_dymiumgroup'] }
  set dymiumgroup(__a__: any) {
    let __v__ = stringReader('')(__a__)
    if(!_.isEqual(__v__,this['_dymiumgroup'])) {
      setDirtyFlag()
      this['_dymiumgroup'] = __v__
    }
  }
  get directorygroup(): string { return this['_directorygroup'] }
  set directorygroup(__a__: any) {
    let __v__ = stringReader('')(__a__)
    if(!_.isEqual(__v__,this['_directorygroup'])) {
      setDirtyFlag()
      this['_directorygroup'] = __v__
    }
  }
  get comments(): string { return this['_comments'] }
  set comments(__a__: any) {
    let __v__ = stringReader('')(__a__)
    if(!_.isEqual(__v__,this['_comments'])) {
      setDirtyFlag()
      this['_comments'] = __v__
    }
  }
  get adminaccess(): boolean { return this['_adminaccess'] }
  set adminaccess(__a__: any) {
    let __v__ = boolReader(false)(__a__)
    if(!_.isEqual(__v__,this['_adminaccess'])) {
      setDirtyFlag()
      this['_adminaccess'] = __v__
    }
  }

  toJson(): string { return JSON.stringify(removeLeadingUnderscore(this)); }

  static fromJson(__a__: any): GroupMapping {
    disableDF()
    let cls = new GroupMapping()
    if(typeof __a__ === 'object' && __a__ != null) {
       cls.id = __a__['id'] == null ? null : __a__['id']
       cls.dymiumgroup = __a__['dymiumgroup']
       cls.directorygroup = __a__['directorygroup']
       cls.comments = __a__['comments']
       cls.adminaccess = __a__['adminaccess']
    } else {
       doAlert(`GroupMapping: an attempt to initialize from ${__a__}`)
    }
    enableDF()
    return cls
  }
}

export class GroupMappingStatus {
  private '_status': string
  private '_errormessage': string
  private '_records': Array<GroupMapping>

  constructor() {
    this['_status'] = ''
    this['_errormessage'] = ''
    this['_records'] = []
  }
  get status(): string { return this['_status'] }
  set status(__a__: any) {
    let __v__ = stringReader('')(__a__)
    if(!_.isEqual(__v__,this['_status'])) {
      setDirtyFlag()
      this['_status'] = __v__
    }
  }
  get errormessage(): string { return this['_errormessage'] }
  set errormessage(__a__: any) {
    let __v__ = stringReader('')(__a__)
    if(!_.isEqual(__v__,this['_errormessage'])) {
      setDirtyFlag()
      this['_errormessage'] = __v__
    }
  }
  get records(): Array<GroupMapping> { return this['_records'] }
  set records(__a__: any) {
    setDirtyFlag()
    this['_records'] = __a__
  }

  toJson(): string { return JSON.stringify(removeLeadingUnderscore(this)); }

  static fromJson(__a__: any): GroupMappingStatus {
    disableDF()
    let cls = new GroupMappingStatus()
    if(typeof __a__ === 'object' && __a__ != null) {
       cls.status = __a__['status']
       cls.errormessage = __a__['errormessage']
       cls.records = array1Reader(GroupMapping.fromJson)(__a__['records'])
    } else {
       doAlert(`GroupMappingStatus: an attempt to initialize from ${__a__}`)
    }
    enableDF()
    return cls
  }
}

export class GroupStatus {
  private '_status': string
  private '_errormessage': string
  private '_admincount': number

  constructor() {
    this['_status'] = ''
    this['_errormessage'] = ''
    this['_admincount'] = 0
  }
  get status(): string { return this['_status'] }
  set status(__a__: any) {
    let __v__ = stringReader('')(__a__)
    if(!_.isEqual(__v__,this['_status'])) {
      setDirtyFlag()
      this['_status'] = __v__
    }
  }
  get errormessage(): string { return this['_errormessage'] }
  set errormessage(__a__: any) {
    let __v__ = stringReader('')(__a__)
    if(!_.isEqual(__v__,this['_errormessage'])) {
      setDirtyFlag()
      this['_errormessage'] = __v__
    }
  }
  get admincount(): number { return this['_admincount'] }
  set admincount(__a__: any) {
    let __v__ = intReader(0)(__a__)
    if(!_.isEqual(__v__,this['_admincount'])) {
      setDirtyFlag()
      this['_admincount'] = __v__
    }
  }

  toJson(): string { return JSON.stringify(removeLeadingUnderscore(this)); }

  static fromJson(__a__: any): GroupStatus {
    disableDF()
    let cls = new GroupStatus()
    if(typeof __a__ === 'object' && __a__ != null) {
       cls.status = __a__['status']
       cls.errormessage = __a__['errormessage']
       cls.admincount = __a__['admincount']
    } else {
       doAlert(`GroupStatus: an attempt to initialize from ${__a__}`)
    }
    enableDF()
    return cls
  }
}

export class Invitation {
  private '_id': string
  private '_email': string
  private '_contactName': string
  private '_status': string
  private '_created': string

  constructor() {
    this['_id'] = ''
    this['_email'] = ''
    this['_contactName'] = ''
    this['_status'] = ''
    this['_created'] = ''
  }
  get id(): string { return this['_id'] }
  set id(__a__: any) {
    let __v__ = stringReader('')(__a__)
    if(!_.isEqual(__v__,this['_id'])) {
      setDirtyFlag()
      this['_id'] = __v__
    }
  }
  get email(): string { return this['_email'] }
  set email(__a__: any) {
    let __v__ = stringReader('')(__a__)
    if(!_.isEqual(__v__,this['_email'])) {
      setDirtyFlag()
      this['_email'] = __v__
    }
  }
  get contactName(): string { return this['_contactName'] }
  set contactName(__a__: any) {
    let __v__ = stringReader('')(__a__)
    if(!_.isEqual(__v__,this['_contactName'])) {
      setDirtyFlag()
      this['_contactName'] = __v__
    }
  }
  get status(): string { return this['_status'] }
  set status(__a__: any) {
    let __v__ = stringReader('')(__a__)
    if(!_.isEqual(__v__,this['_status'])) {
      setDirtyFlag()
      this['_status'] = __v__
    }
  }
  get created(): string { return this['_created'] }
  set created(__a__: any) {
    let __v__ = stringReader('')(__a__)
    if(!_.isEqual(__v__,this['_created'])) {
      setDirtyFlag()
      this['_created'] = __v__
    }
  }

  toJson(): string { return JSON.stringify(removeLeadingUnderscore(this)); }

  static fromJson(__a__: any): Invitation {
    disableDF()
    let cls = new Invitation()
    if(typeof __a__ === 'object' && __a__ != null) {
       cls.id = __a__['id']
       cls.email = __a__['email']
       cls.contactName = __a__['contactName']
       cls.status = __a__['status']
       cls.created = __a__['created']
    } else {
       doAlert(`Invitation: an attempt to initialize from ${__a__}`)
    }
    enableDF()
    return cls
  }
}

export class InviteCustomer {
  private '_email': string
  private '_contactName': string

  constructor() {
    this['_email'] = ''
    this['_contactName'] = ''
  }
  get email(): string { return this['_email'] }
  set email(__a__: any) {
    let __v__ = stringReader('')(__a__)
    if(!_.isEqual(__v__,this['_email'])) {
      setDirtyFlag()
      this['_email'] = __v__
    }
  }
  get contactName(): string { return this['_contactName'] }
  set contactName(__a__: any) {
    let __v__ = stringReader('')(__a__)
    if(!_.isEqual(__v__,this['_contactName'])) {
      setDirtyFlag()
      this['_contactName'] = __v__
    }
  }

  toJson(): string { return JSON.stringify(removeLeadingUnderscore(this)); }

  static fromJson(__a__: any): InviteCustomer {
    disableDF()
    let cls = new InviteCustomer()
    if(typeof __a__ === 'object' && __a__ != null) {
       cls.email = __a__['email']
       cls.contactName = __a__['contactName']
    } else {
       doAlert(`InviteCustomer: an attempt to initialize from ${__a__}`)
    }
    enableDF()
    return cls
  }
}

export class MachineTunnel {
  private '_id': string | null
  private '_accesskey': string | null
  private '_secret': string | null
  private '_name': string
  private '_username': string | null
  private '_password': string | null
  private '_groups': Array<string>

  constructor() {
    this['_id'] = null
    this['_accesskey'] = null
    this['_secret'] = null
    this['_name'] = ''
    this['_username'] = null
    this['_password'] = null
    this['_groups'] = []
  }
  get id(): string | null { return this['_id'] }
  set id(__a__: any) {
    if(__a__ == null) {
      if(this['_id'] == null) { return }
      setDirtyFlag()
      this['_id'] = null
      return
    } else {
      let __v__ = stringReader('')(__a__)
      if(!_.isEqual(__v__,this['_id'])) {
        setDirtyFlag()
        this['_id'] = __v__
      }
    }
  }
  get accesskey(): string | null { return this['_accesskey'] }
  set accesskey(__a__: any) {
    if(__a__ == null) {
      if(this['_accesskey'] == null) { return }
      setDirtyFlag()
      this['_accesskey'] = null
      return
    } else {
      let __v__ = stringReader('')(__a__)
      if(!_.isEqual(__v__,this['_accesskey'])) {
        setDirtyFlag()
        this['_accesskey'] = __v__
      }
    }
  }
  get secret(): string | null { return this['_secret'] }
  set secret(__a__: any) {
    if(__a__ == null) {
      if(this['_secret'] == null) { return }
      setDirtyFlag()
      this['_secret'] = null
      return
    } else {
      let __v__ = stringReader('')(__a__)
      if(!_.isEqual(__v__,this['_secret'])) {
        setDirtyFlag()
        this['_secret'] = __v__
      }
    }
  }
  get name(): string { return this['_name'] }
  set name(__a__: any) {
    let __v__ = stringReader('')(__a__)
    if(!_.isEqual(__v__,this['_name'])) {
      setDirtyFlag()
      this['_name'] = __v__
    }
  }
  get username(): string | null { return this['_username'] }
  set username(__a__: any) {
    if(__a__ == null) {
      if(this['_username'] == null) { return }
      setDirtyFlag()
      this['_username'] = null
      return
    } else {
      let __v__ = stringReader('')(__a__)
      if(!_.isEqual(__v__,this['_username'])) {
        setDirtyFlag()
        this['_username'] = __v__
      }
    }
  }
  get password(): string | null { return this['_password'] }
  set password(__a__: any) {
    if(__a__ == null) {
      if(this['_password'] == null) { return }
      setDirtyFlag()
      this['_password'] = null
      return
    } else {
      let __v__ = stringReader('')(__a__)
      if(!_.isEqual(__v__,this['_password'])) {
        setDirtyFlag()
        this['_password'] = __v__
      }
    }
  }
  get groups(): Array<string> { return this['_groups'] }
  set groups(__a__: any) {
    setDirtyFlag()
    this['_groups'] = __a__
  }

  toJson(): string { return JSON.stringify(removeLeadingUnderscore(this)); }

  static fromJson(__a__: any): MachineTunnel {
    disableDF()
    let cls = new MachineTunnel()
    if(typeof __a__ === 'object' && __a__ != null) {
       cls.id = __a__['id'] == null ? null : __a__['id']
       cls.accesskey = __a__['accesskey'] == null ? null : __a__['accesskey']
       cls.secret = __a__['secret'] == null ? null : __a__['secret']
       cls.name = __a__['name']
       cls.username = __a__['username'] == null ? null : __a__['username']
       cls.password = __a__['password'] == null ? null : __a__['password']
       cls.groups = array1Reader(stringReader(''))(__a__['groups'])
    } else {
       doAlert(`MachineTunnel: an attempt to initialize from ${__a__}`)
    }
    enableDF()
    return cls
  }
}

export class OperationStatus {
  private '_status': string
  private '_errormessage': string

  constructor() {
    this['_status'] = ''
    this['_errormessage'] = ''
  }
  get status(): string { return this['_status'] }
  set status(__a__: any) {
    let __v__ = stringReader('')(__a__)
    if(!_.isEqual(__v__,this['_status'])) {
      setDirtyFlag()
      this['_status'] = __v__
    }
  }
  get errormessage(): string { return this['_errormessage'] }
  set errormessage(__a__: any) {
    let __v__ = stringReader('')(__a__)
    if(!_.isEqual(__v__,this['_errormessage'])) {
      setDirtyFlag()
      this['_errormessage'] = __v__
    }
  }

  toJson(): string { return JSON.stringify(removeLeadingUnderscore(this)); }

  static fromJson(__a__: any): OperationStatus {
    disableDF()
    let cls = new OperationStatus()
    if(typeof __a__ === 'object' && __a__ != null) {
       cls.status = __a__['status']
       cls.errormessage = __a__['errormessage']
    } else {
       doAlert(`OperationStatus: an attempt to initialize from ${__a__}`)
    }
    enableDF()
    return cls
  }
}

export class PIIDetector {
  private '_id': string | null
  private '_name': string
  private '_method': PIIDetectionType
  private '_data': string

  constructor() {
    this['_id'] = null
    this['_name'] = ''
    this['_method'] = 'comprehend'
    this['_data'] = ''
  }
  get id(): string | null { return this['_id'] }
  set id(__a__: any) {
    if(__a__ == null) {
      if(this['_id'] == null) { return }
      setDirtyFlag()
      this['_id'] = null
      return
    } else {
      let __v__ = stringReader('')(__a__)
      if(!_.isEqual(__v__,this['_id'])) {
        setDirtyFlag()
        this['_id'] = __v__
      }
    }
  }
  get name(): string { return this['_name'] }
  set name(__a__: any) {
    let __v__ = stringReader('')(__a__)
    if(!_.isEqual(__v__,this['_name'])) {
      setDirtyFlag()
      this['_name'] = __v__
    }
  }
  get method(): PIIDetectionType { return this['_method'] }
  set method(__a__: any) {
    let __v__ = enumReader(['comprehend','columnregexp','contentregexp'],'comprehend')(__a__)
    if(!_.isEqual(__v__,this['_method'])) {
      setDirtyFlag()
      this['_method'] = __v__
    }
  }
  get data(): string { return this['_data'] }
  set data(__a__: any) {
    let __v__ = stringReader('')(__a__)
    if(!_.isEqual(__v__,this['_data'])) {
      setDirtyFlag()
      this['_data'] = __v__
    }
  }

  toJson(): string { return JSON.stringify(removeLeadingUnderscore(this)); }

  static fromJson(__a__: any): PIIDetector {
    disableDF()
    let cls = new PIIDetector()
    if(typeof __a__ === 'object' && __a__ != null) {
       cls.id = __a__['id'] == null ? null : __a__['id']
       cls.name = __a__['name']
       cls.method = __a__['method']
       cls.data = __a__['data']
    } else {
       doAlert(`PIIDetector: an attempt to initialize from ${__a__}`)
    }
    enableDF()
    return cls
  }
}

export class PIISuggestor {
  private '_actions': Array<DataAction>
  private '_detector': PIIDetector

  constructor() {
    this['_actions'] = []
    this['_detector'] = new PIIDetector()
  }
  get actions(): Array<DataAction> { return this['_actions'] }
  set actions(__a__: any) {
    setDirtyFlag()
    this['_actions'] = __a__
  }
  get detector(): PIIDetector { return this['_detector'] }
  set detector(__a__: any) {
    setDirtyFlag()
    this['_detector'] = __a__
  }

  toJson(): string { return JSON.stringify(removeLeadingUnderscore(this)); }

  static fromJson(__a__: any): PIISuggestor {
    disableDF()
    let cls = new PIISuggestor()
    if(typeof __a__ === 'object' && __a__ != null) {
       cls.actions = array1Reader(DataAction.fromJson)(__a__['actions'])
       cls.detector = PIIDetector.fromJson(__a__['detector'])
    } else {
       doAlert(`PIISuggestor: an attempt to initialize from ${__a__}`)
    }
    enableDF()
    return cls
  }
}

export class RegistryID {
  private '_id': string

  constructor() {
    this['_id'] = ''
  }
  get id(): string { return this['_id'] }
  set id(__a__: any) {
    let __v__ = stringReader('')(__a__)
    if(!_.isEqual(__v__,this['_id'])) {
      setDirtyFlag()
      this['_id'] = __v__
    }
  }

  toJson(): string { return JSON.stringify(removeLeadingUnderscore(this)); }

  static fromJson(__a__: any): RegistryID {
    disableDF()
    let cls = new RegistryID()
    if(typeof __a__ === 'object' && __a__ != null) {
       cls.id = __a__['id']
    } else {
       doAlert(`RegistryID: an attempt to initialize from ${__a__}`)
    }
    enableDF()
    return cls
  }
}

export class RequestById {
  private '_id': string

  constructor() {
    this['_id'] = ''
  }
  get id(): string { return this['_id'] }
  set id(__a__: any) {
    let __v__ = stringReader('')(__a__)
    if(!_.isEqual(__v__,this['_id'])) {
      setDirtyFlag()
      this['_id'] = __v__
    }
  }

  toJson(): string { return JSON.stringify(removeLeadingUnderscore(this)); }

  static fromJson(__a__: any): RequestById {
    disableDF()
    let cls = new RequestById()
    if(typeof __a__ === 'object' && __a__ != null) {
       cls.id = __a__['id']
    } else {
       doAlert(`RequestById: an attempt to initialize from ${__a__}`)
    }
    enableDF()
    return cls
  }
}

export class SetConnectorStatus {
  private '_customer': string
  private '_key': string
  private '_secret': string
  private '_status': string

  constructor() {
    this['_customer'] = ''
    this['_key'] = ''
    this['_secret'] = ''
    this['_status'] = ''
  }
  get customer(): string { return this['_customer'] }
  set customer(__a__: any) {
    let __v__ = stringReader('')(__a__)
    if(!_.isEqual(__v__,this['_customer'])) {
      setDirtyFlag()
      this['_customer'] = __v__
    }
  }
  get key(): string { return this['_key'] }
  set key(__a__: any) {
    let __v__ = stringReader('')(__a__)
    if(!_.isEqual(__v__,this['_key'])) {
      setDirtyFlag()
      this['_key'] = __v__
    }
  }
  get secret(): string { return this['_secret'] }
  set secret(__a__: any) {
    let __v__ = stringReader('')(__a__)
    if(!_.isEqual(__v__,this['_secret'])) {
      setDirtyFlag()
      this['_secret'] = __v__
    }
  }
  get status(): string { return this['_status'] }
  set status(__a__: any) {
    let __v__ = stringReader('')(__a__)
    if(!_.isEqual(__v__,this['_status'])) {
      setDirtyFlag()
      this['_status'] = __v__
    }
  }

  toJson(): string { return JSON.stringify(removeLeadingUnderscore(this)); }

  static fromJson(__a__: any): SetConnectorStatus {
    disableDF()
    let cls = new SetConnectorStatus()
    if(typeof __a__ === 'object' && __a__ != null) {
       cls.customer = __a__['customer']
       cls.key = __a__['key']
       cls.secret = __a__['secret']
       cls.status = __a__['status']
    } else {
       doAlert(`SetConnectorStatus: an attempt to initialize from ${__a__}`)
    }
    enableDF()
    return cls
  }
}

export class TableQuery {
  private '_connectionId': string
  private '_schema': string
  private '_table': string

  constructor() {
    this['_connectionId'] = ''
    this['_schema'] = ''
    this['_table'] = ''
  }
  get connectionId(): string { return this['_connectionId'] }
  set connectionId(__a__: any) {
    let __v__ = stringReader('')(__a__)
    if(!_.isEqual(__v__,this['_connectionId'])) {
      setDirtyFlag()
      this['_connectionId'] = __v__
    }
  }
  get schema(): string { return this['_schema'] }
  set schema(__a__: any) {
    let __v__ = stringReader('')(__a__)
    if(!_.isEqual(__v__,this['_schema'])) {
      setDirtyFlag()
      this['_schema'] = __v__
    }
  }
  get table(): string { return this['_table'] }
  set table(__a__: any) {
    let __v__ = stringReader('')(__a__)
    if(!_.isEqual(__v__,this['_table'])) {
      setDirtyFlag()
      this['_table'] = __v__
    }
  }

  toJson(): string { return JSON.stringify(removeLeadingUnderscore(this)); }

  static fromJson(__a__: any): TableQuery {
    disableDF()
    let cls = new TableQuery()
    if(typeof __a__ === 'object' && __a__ != null) {
       cls.connectionId = __a__['connectionId']
       cls.schema = __a__['schema']
       cls.table = __a__['table']
    } else {
       doAlert(`TableQuery: an attempt to initialize from ${__a__}`)
    }
    enableDF()
    return cls
  }
}

export class Usage {
  private '_connections': number
  private '_datascopes': number
  private '_logins': number
  private '_tunnels': number
  private '_blocked': number
  private '_obfuscated': number
  private '_redacted': number
  private '_connectors': number
  private '_connectortunnels': number
  private '_bytesin': string
  private '_bytesout': string

  constructor() {
    this['_connections'] = 0
    this['_datascopes'] = 0
    this['_logins'] = 0
    this['_tunnels'] = 0
    this['_blocked'] = 0
    this['_obfuscated'] = 0
    this['_redacted'] = 0
    this['_connectors'] = 0
    this['_connectortunnels'] = 0
    this['_bytesin'] = ''
    this['_bytesout'] = ''
  }
  get connections(): number { return this['_connections'] }
  set connections(__a__: any) {
    let __v__ = intReader(0)(__a__)
    if(!_.isEqual(__v__,this['_connections'])) {
      setDirtyFlag()
      this['_connections'] = __v__
    }
  }
  get datascopes(): number { return this['_datascopes'] }
  set datascopes(__a__: any) {
    let __v__ = intReader(0)(__a__)
    if(!_.isEqual(__v__,this['_datascopes'])) {
      setDirtyFlag()
      this['_datascopes'] = __v__
    }
  }
  get logins(): number { return this['_logins'] }
  set logins(__a__: any) {
    let __v__ = intReader(0)(__a__)
    if(!_.isEqual(__v__,this['_logins'])) {
      setDirtyFlag()
      this['_logins'] = __v__
    }
  }
  get tunnels(): number { return this['_tunnels'] }
  set tunnels(__a__: any) {
    let __v__ = intReader(0)(__a__)
    if(!_.isEqual(__v__,this['_tunnels'])) {
      setDirtyFlag()
      this['_tunnels'] = __v__
    }
  }
  get blocked(): number { return this['_blocked'] }
  set blocked(__a__: any) {
    let __v__ = intReader(0)(__a__)
    if(!_.isEqual(__v__,this['_blocked'])) {
      setDirtyFlag()
      this['_blocked'] = __v__
    }
  }
  get obfuscated(): number { return this['_obfuscated'] }
  set obfuscated(__a__: any) {
    let __v__ = intReader(0)(__a__)
    if(!_.isEqual(__v__,this['_obfuscated'])) {
      setDirtyFlag()
      this['_obfuscated'] = __v__
    }
  }
  get redacted(): number { return this['_redacted'] }
  set redacted(__a__: any) {
    let __v__ = intReader(0)(__a__)
    if(!_.isEqual(__v__,this['_redacted'])) {
      setDirtyFlag()
      this['_redacted'] = __v__
    }
  }
  get connectors(): number { return this['_connectors'] }
  set connectors(__a__: any) {
    let __v__ = intReader(0)(__a__)
    if(!_.isEqual(__v__,this['_connectors'])) {
      setDirtyFlag()
      this['_connectors'] = __v__
    }
  }
  get connectortunnels(): number { return this['_connectortunnels'] }
  set connectortunnels(__a__: any) {
    let __v__ = intReader(0)(__a__)
    if(!_.isEqual(__v__,this['_connectortunnels'])) {
      setDirtyFlag()
      this['_connectortunnels'] = __v__
    }
  }
  get bytesin(): string { return this['_bytesin'] }
  set bytesin(__a__: any) {
    let __v__ = stringReader('')(__a__)
    if(!_.isEqual(__v__,this['_bytesin'])) {
      setDirtyFlag()
      this['_bytesin'] = __v__
    }
  }
  get bytesout(): string { return this['_bytesout'] }
  set bytesout(__a__: any) {
    let __v__ = stringReader('')(__a__)
    if(!_.isEqual(__v__,this['_bytesout'])) {
      setDirtyFlag()
      this['_bytesout'] = __v__
    }
  }

  toJson(): string { return JSON.stringify(removeLeadingUnderscore(this)); }

  static fromJson(__a__: any): Usage {
    disableDF()
    let cls = new Usage()
    if(typeof __a__ === 'object' && __a__ != null) {
       cls.connections = __a__['connections']
       cls.datascopes = __a__['datascopes']
       cls.logins = __a__['logins']
       cls.tunnels = __a__['tunnels']
       cls.blocked = __a__['blocked']
       cls.obfuscated = __a__['obfuscated']
       cls.redacted = __a__['redacted']
       cls.connectors = __a__['connectors']
       cls.connectortunnels = __a__['connectortunnels']
       cls.bytesin = __a__['bytesin']
       cls.bytesout = __a__['bytesout']
    } else {
       doAlert(`Usage: an attempt to initialize from ${__a__}`)
    }
    enableDF()
    return cls
  }
}

export class UserDatascopes {
  private '_schema': string
  private '_username': string
  private '_password': string
  private '_datascopes': Array<DatascopeIdName>

  constructor() {
    this['_schema'] = ''
    this['_username'] = ''
    this['_password'] = ''
    this['_datascopes'] = []
  }
  get schema(): string { return this['_schema'] }
  set schema(__a__: any) {
    let __v__ = stringReader('')(__a__)
    if(!_.isEqual(__v__,this['_schema'])) {
      setDirtyFlag()
      this['_schema'] = __v__
    }
  }
  get username(): string { return this['_username'] }
  set username(__a__: any) {
    let __v__ = stringReader('')(__a__)
    if(!_.isEqual(__v__,this['_username'])) {
      setDirtyFlag()
      this['_username'] = __v__
    }
  }
  get password(): string { return this['_password'] }
  set password(__a__: any) {
    let __v__ = stringReader('')(__a__)
    if(!_.isEqual(__v__,this['_password'])) {
      setDirtyFlag()
      this['_password'] = __v__
    }
  }
  get datascopes(): Array<DatascopeIdName> { return this['_datascopes'] }
  set datascopes(__a__: any) {
    setDirtyFlag()
    this['_datascopes'] = __a__
  }

  toJson(): string { return JSON.stringify(removeLeadingUnderscore(this)); }

  static fromJson(__a__: any): UserDatascopes {
    disableDF()
    let cls = new UserDatascopes()
    if(typeof __a__ === 'object' && __a__ != null) {
       cls.schema = __a__['schema']
       cls.username = __a__['username']
       cls.password = __a__['password']
       cls.datascopes = array1Reader(DatascopeIdName.fromJson)(__a__['datascopes'])
    } else {
       doAlert(`UserDatascopes: an attempt to initialize from ${__a__}`)
    }
    enableDF()
    return cls
  }
}

export class Reference {
  private '_schema': string
  private '_table': string
  private '_column': string

  constructor() {
    this['_schema'] = ''
    this['_table'] = ''
    this['_column'] = ''
  }
  get schema(): string { return this['_schema'] }
  set schema(__a__: any) {
    let __v__ = stringReader('')(__a__)
    if(!_.isEqual(__v__,this['_schema'])) {
      setDirtyFlag()
      this['_schema'] = __v__
    }
  }
  get table(): string { return this['_table'] }
  set table(__a__: any) {
    let __v__ = stringReader('')(__a__)
    if(!_.isEqual(__v__,this['_table'])) {
      setDirtyFlag()
      this['_table'] = __v__
    }
  }
  get column(): string { return this['_column'] }
  set column(__a__: any) {
    let __v__ = stringReader('')(__a__)
    if(!_.isEqual(__v__,this['_column'])) {
      setDirtyFlag()
      this['_column'] = __v__
    }
  }

  toJson(): string { return JSON.stringify(removeLeadingUnderscore(this)); }

  static fromJson(__a__: any): Reference {
    disableDF()
    let cls = new Reference()
    if(typeof __a__ === 'object' && __a__ != null) {
       cls.schema = __a__['schema']
       cls.table = __a__['table']
       cls.column = __a__['column']
    } else {
       doAlert(`Reference: an attempt to initialize from ${__a__}`)
    }
    enableDF()
    return cls
  }
}

function boolReader(__dflt__) {
  return ((__a__) => {
    if(_.isBoolean(__a__)) {
      return __a__
    }
    doAlert(`boolReader: ${__a__} is not a boolean`)
    if(__a__ === "yes" || __a__ === "true") {
      return true
    }
    if(__a__ === "no" || __a__ === "false") {
      return false
    }
    return __dflt__
  })
}
function intReader(__dflt__) {
  return ((__a__) => {
    if(_.isInteger(__a__)) {
      return __a__
    }
    doAlert(`intReader: ${__a__} is not an integer`)
    if(_.isString(__a__)) {
      let v = parseInt(__a__)
      if(_.isFinite(v)) {
        return v
      }
    }
    return __dflt__
  })
}
function stringReader(__dflt__) {
  return ((__a__) => {
    if(_.isString(__a__)) {
      return __a__
    }
    doAlert(`stringReader: ${__a__} is not a string`)
    return __dflt__
  })
}
function array1Reader(__r__) {
  return ((__a__) => {
    if(!_.isArray(__a__)) {
      doAlert(`arrayReader: ${__a__} is not an array`)
      return []
    }
    return __a__.map(__r__)
  })
}
function enumReader(__v__,__dflt__) {
  return ((__a__) => {
    if(!_.isString(__a__)) {
      doAlert(`enumReader: ${__a__} is not a string`)
      return __dflt__
    }
    if(__a__ !== __dflt__ && !_.includes(__v__,__a__)) {
      doAlert(`enumReader: ${__a__} is not in ${__v__}`)
      return __dflt__
    }
    return __a__
  })
}

function removeLeadingUnderscore(obj: any): any {
  if (Array.isArray(obj)) {
    return obj.map(val => removeLeadingUnderscore(val));
  } else if (typeof obj === 'object' && obj !== null) {
    return Object.keys(obj).reduce((newObj, key) => {
      const newKey = ( key.length > 0 && key[0] === '_' ) ? key.substring(1) : key;
      newObj[newKey] = removeLeadingUnderscore(obj[key]);
      return newObj;
    }, {} as any)
  } else {
    return obj;
  }
}

let setDirtyFlag = () => { dirtyFlag = true }
let [disableDF,enableDF] = (() => {
    let n = 0
    return [() => {
      if(n === 0) {
        setDirtyFlag = () => {}
        n = 1
      } else {
        n += 1
      }
    },
    () => {
      if(n === 1) {
        setDirtyFlag = () => { dirtyFlag = true }
        n = 0
      } else {
        n -= 1
      }
    }]
})()
