import React, { useEffect, useState, useRef, useCallback } from 'react';
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Card from 'react-bootstrap/Card'
import Modal from 'react-bootstrap/Modal'
import BootstrapTable from 'react-bootstrap-table-next';
import cloneDeep from 'lodash/cloneDeep';
import { isThereConflict } from '../Utils/PostgresConflicts'

import * as com from '../Common'
import * as types from '@dymium/common/Types/Common'
import * as internal from '@dymium/common/Types/Internal'

enum OverlayMode {
    None = 0,
    Schema = 1,
    Name = 2
}
interface OverlayState {
    show: boolean; // Determines if the overlay is visible
    typ: OverlayMode;
    target: HTMLElement | null; // The clicked element to position the overlay
    top: number; // Top coordinate for overlay positioning
    left: number; // Left coordinate for overlay positioning
    value: string; // Editable value in the overlay
    originalValue: string;
    row: any
}

interface TableSchemaMapping {
    schema: string
    table: string
    ghostSchema: string
    ghostTable: string
}

//
// dbname - datascope name
// onDbname - handler for recording dbname change
// onTablesMapUpdate - callback on Table change
// onEditTable - table edit is requested 
// AddNewTable - add new table is requested 
// onAddTableRef - pass ref to addTable function
// connections={conns} 
// setAlert={setAlert} 
// nameToConnection
export interface DatascopeFormProps {
    edit: boolean,
    initialTables?: internal.TablesMap,
    onEditTable: (ar: internal.TableScope) => void,
    onTablesMapUpdate: (ar: internal.TablesMap) => void,
    onAddTableRef: (ar: any) => void,
    connections: internal.Connection[],
    onDeleteConnection: (c: string) => void,
    AddNewTable: (id: string, tableId: string, connectionId: string, dbtype: string, description: string, schema?: string, table?: string) => void,
    nameToConnection: internal.ConnectionMap,
    dbname: string,
    onDbname: (ar: string) => void,
    setAlert: (ar: any) => void, // not sure how to deal with the type properly here
    description: string,
    onDescription: (ar: string) => void,
    //    table: types.TableScope,
    //    connectionId: string,
    //    onAddTable: (ar: types.TableScope) => void,
}
const DatascopeForm: React.FC<DatascopeFormProps> = (props) => {

    let empty: any[] = []
    const [connections, setConnections] = useState<string[]>(empty)
    const [tables, setTables] = useState<internal.TablesMap>({})
    const [selectedConnection, setSelectedConnection] = useState<string>("")
    const [counter, setCounter] = useState(0)
    const editedConnection = useRef("")
    const containerref = useRef<HTMLDivElement>(null);
    const [selecteddb, setSelecteddb] = useState<string>("")
    const [applyAll, setApplyAll] = useState(false)
    const [tableConflict, setTableConflict] = useState(false)
    const [tsMapping, setTsMapping] = useState<TableSchemaMapping>()
    const [overlayState, setOverlayState] = useState<OverlayState>({
        show: false,
        target: null,
        typ: OverlayMode.None,
        top: 0,
        left: 0,
        value: '',
        originalValue: '',
        row: []
    });

    // make sure we don't have a stale closure
    let refs = useRef({})
    refs.current["connections"] = connections
    refs.current["tables"] = tables
    refs.current["setTables"] = setTables
    refs.current["setCounter"] = setCounter

    const handleSave = () => {

        // Hide the overlay
        setOverlayState({ show: false, target: null, typ: OverlayMode.None, top: 0, left: 0, value: "", originalValue: "", row: [] });

    };
    const handleCancel = () => {
        setOverlayState({ show: false, target: null, typ: OverlayMode.None, top: overlayState.top, left: overlayState.left, value: "", originalValue: "", row: [] });
    };
    const handleClick = useCallback(
        (e: React.MouseEvent<HTMLElement>, rowIndex: number, mode: OverlayMode, dbname: string, row: any) => {
            const targetRect = e.currentTarget.getBoundingClientRect();
            const container = containerref.current;
            const containerRect = container?.getBoundingClientRect() || { top: 0, left: 0 };
            let schema = row.schema
            let table = row.table
            let ghostSchema = row.ghostSchema
            let ghostTable = row.ghostTable

            setApplyAll(false)
            // Using the exact click coordinates instead of the element bounds

            setSelecteddb(dbname)
            let ts: TableSchemaMapping = { schema: schema, table: table, ghostSchema: ghostSchema, ghostTable: ghostTable }

            setOverlayState(overlayState)
            setTsMapping(ts)
            setOverlayState({
                show: true,
                target: e.currentTarget,
                typ: mode,
                top: e.clientY - containerRect.top + 10,  // Adding 10px offset to move it below the click
                left: e.clientX - containerRect.left,
                value: (mode === OverlayMode.Schema) ? ghostSchema : ghostTable,
                originalValue: (mode === OverlayMode.Schema) ? schema : table,
                row
            });
        },
        [containerref]
    );
    const handleSaveSchema = useCallback(() => {
        let t = cloneDeep(tables)
        let db = t[selecteddb]
        let tbl = db[tsMapping!.schema + "." + tsMapping!.table]

        tbl.ghostSchema = overlayState.value
        if (applyAll) {
            // iterate over tbl dictionary keys
            Object.keys(db).forEach(x => {
                let ln = db[x]
                if (ln.schema === tbl.schema) {
                    ln.ghostSchema = overlayState.value
                }
            })

        }
        props.onTablesMapUpdate(t)
        setTables(tables => t)
        overlayState.show = false
        setOverlayState(overlayState)

    }, [overlayState, props.onTablesMapUpdate, tsMapping])

    const handleSaveTable = useCallback(() => {
        let t = cloneDeep(tables)
        let db = t[selecteddb]
        let tbl = db[tsMapping!.schema + "." + tsMapping!.table]

        tbl.ghostTable = overlayState.value

        props.onTablesMapUpdate(t)
        setTables(tables => t)
        overlayState.show = false
        setOverlayState(overlayState)

    }, [overlayState, props.onTablesMapUpdate, tsMapping])

    let onEdit = (connection, schema, table, connectionid) => {
        return e => {
            let ob: internal.TableScope = refs.current["tables"][connection]
            Object.keys(ob).forEach(x => {
                if (ob[x].schema === schema && ob[x].table === table) {

                    setSelectedConnection(connection)
                    props.onEditTable(ob[x])
                    editedConnection.current = connection
                }
            })
        }
    }
    let onDelete = (connection: string, schema: string, table: string) => {
        return e => {
            let tables: internal.TablesMap = cloneDeep(refs.current["tables"])
            delete tables[connection][schema + "." + table]
            setTables(tables)
            //refs.current["setTables"](tables)
            setCounter(counter + 1)
            setConnections(refs.current["connections"])
        }
    }

    const getColumns = useCallback((dbname: string, connectionid: string) => {
        let columns = [
            {
                dataField: 'id',
                text: 'id:',
                hidden: true,
                searchable: false
            },
            {
                dataField: 'connection',
                text: 'connection:',
                isDummyField: true,
                hidden: true,
            },
            {
                dataField: 'ghostSchema',
                text: 'Schema:',
                isDummyField: true,
                sort: true,
                formatter: (cell: any, row: any, rowIndex: number) => {
                    return <div
                        style={{
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                            fontStyle: (row.schema === row.ghostSchema) ? 'normal' : 'italic'
                        }}
                        className="underscore"
                        title={(row.schema !== row.ghostSchema) ? row.schema + "=>" + row.ghostSchema : "Click to map"}
                        onClick={(e) => handleClick(e, rowIndex, OverlayMode.Schema, dbname, row)}
                    >
                        {row.ghostSchema}
                    </div>
                },
            },
            {
                dataField: 'ghostTable',
                text: 'Table name:',
                isDummyField: true,
                sort: true,
                formatter: (cell: any, row: any, rowIndex: number) => (
                    <div
                        style={{
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                            fontStyle: (row.table === row.ghostTable) ? 'normal' : 'italic'
                        }}
                        className="underscore"
                        title={(row.table === row.ghostTable) ? "Click to map" : row.table + "=>" + row.ghostTable}
                        onClick={(e) => handleClick(e, rowIndex, OverlayMode.Name, dbname, row)}
                    >
                        {row.ghostTable}
                    </div>
                ),
            },
            {
                dataField: 'columns',
                text: '# of columns:',
                //isDummyField: true,
                sort: true,
                sortFunc: (a, b, order, dataField, rowA, rowB) => {
                    let dif = rowA.tablescope.length - rowB.tablescope.length
                    // check asc
                    if (dif === 0)
                        return 0
                    if (dif > 0) {
                        return order === 'asc' ? 1 : -1;
                    } else {
                        return order === 'asc' ? -1 : 1;
                    }
                },
                formatter: (cell, row, rowIndex, formatExtraData) => {

                    return row.tablescope.length
                }
            },
            {
                dataField: 'pii',
                text: 'Sensitivity:',
                isDummyField: true,
                sort: true,
                sortFunc: (a, b, order, dataField, rowA, rowB) => {
                    let senseA = false
                    rowA.tablescope.forEach(x => {
                        if (x.semantics !== "N/A" && x.semantics != null && x.semantics !== "")
                            senseA = true
                    })
                    let senseB = false
                    rowB.tablescope.forEach(x => {
                        if (x.semantics !== "N/A" && x.semantics != null && x.semantics !== "")
                            senseB = true
                    })
                    if (senseA && !senseB) return order === 'asc' ? 1 : -1;
                    if (!senseA && senseB) return order === 'asc' ? -1 : 1;
                    return 0;
                },
                formatter: (cell, row, rowIndex, formatExtraData) => {
                    let sense = false
                    row.tablescope.forEach(x => {
                        if (x.semantics !== "N/A" && x.semantics != null && x.semantics !== "")
                            sense = true
                    })
                    if (sense) return <>Contains PII</>
                    return <>Not sensitive</>
                }
            },
            {
                dataField: 'disposition',
                text: 'Access:',
                isDummyField: true,
                sortFunc: (a, b, order, dataField, rowA, rowB) => {
                    let managedA = false
                    rowA.tablescope.forEach(x => {
                        if (x.action.toLowerCase() !== "allow")
                            managedA = true
                    })
                    let managedB = false
                    rowB.tablescope.forEach(x => {
                        if (x.action.toLowerCase() !== "allow")
                            managedB = true
                    })
                    if (managedA && !managedB) return order === 'asc' ? 1 : -1;
                    if (!managedA && managedB) return order === 'asc' ? -1 : 1;
                    return 0;
                },
                sort: true,
                formatter: (cell, row, rowIndex, formatExtraData) => {
                    let managed = false
                    row.tablescope.forEach(x => {
                        if (x.action.toLowerCase() !== "allow")
                            managed = true
                    })
                    if (managed) return <>Managed</>
                    return <>Transparent</>
                }
            },
            {
                text: 'Edit',
                dataField: 'edit',
                isDummyField: true,
                formatter: (cell, row, rowIndex, formatExtraData) => {

                    return <i className="fas fa-edit ablue" onClick={onEdit(row["connection"], row["schema"], row["table"], connectionid)} role="button"></i>
                },
                //formatExtraData: { hoverIdx: this.state.hoverIdx },
                headerStyle: { width: '50px' },
                style: { height: '30px' },
                align: 'center'
            },
            {
                text: 'Delete',
                dataField: 'delete',
                isDummyField: true,
                formatter: (cell, row, rowIndex, formatExtraData) => {
                    return <i className="fas fa-trash ablue" onClick={onDelete(row["connection"], row["schema"], row["table"])} role="button"></i>
                },
                //formatExtraData: { hoverIdx: this.state.hoverIdx },
                headerStyle: { width: '90px' },
                style: { height: '30px' },
                align: 'center'
            }
        ]
        return columns
    }, [])

    let addTable = (table) => {
        let _tables = cloneDeep(refs.current["tables"])
        if (_tables[editedConnection.current] === undefined)
            _tables[editedConnection.current] = {}
        table["connection"] = editedConnection.current

        _tables[editedConnection.current][table.schema + "." + table.table] = table
        let setTables = refs.current["setTables"]

        setTables(_tables)
        setCounter(counter + 1)
    }
    useEffect(() => {
        props.onTablesMapUpdate(tables)
    }, [tables]
    )

    useEffect(() => {
        if (props.initialTables !== undefined) {
            let conns: string[] = []
            Object.keys(props.initialTables).forEach(x => {
                conns.push(x)
            })
            setTables(props.initialTables)
            setConnections(conns)
        }
    }, [props.initialTables]
    )

    const addTableRef = useRef(addTable)
    useEffect(() => {
        props.onAddTableRef(addTableRef)
        let _tables = {}
        props.connections.forEach(x => {
            _tables[x.name] = {} // table name: table
        })
        setTables(_tables)
    }, [])

    const available = () => {
        return props.connections
            .filter(x => !connections.includes(x.name))
            .sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()))
            .map(x => (
                <option data-testid={x.name} key={x.name} value={x.name}>
                    {x.name}
                </option>
            ));
    };

    let onAddConnection = (e: any) => {
        if (selectedConnection === "") {
            return
        }

        let db = [...connections, selectedConnection]
        setConnections(db)
        setSelectedConnection("")
    }

    let displayTables = (name: string) => {
        let ret: any[] = []

        if (tables[name] === undefined)
            return ret

        Object.keys(tables[name]).forEach((x: any) => {
            tables[name][x]["connection"] = name
            tables[name][x]["id"] = tables[name][x]["connection"] + tables[name][x]["schema"] + tables[name][x]["table"]
            ret.push(tables[name][x])
        })
        return ret
    }

    let showConnection = (db: types.ConnectionRecord) => {
        if (db === undefined || db.name === undefined)
            return <></>

        let deleteConnection = e => {
            let d = connections.filter(function (value, index, arr) {
                return value !== db.name;
            })
            // need to send upstream

            setConnections(d)
            props.onDeleteConnection(db.name)
        }
        let showDependencies = () => {
            // map tables
            if (tables[db.name] === undefined)
                return ""
            let references = {}
            let tbnames = Object.keys(tables[db.name])
            for (let i = 0; i < tbnames.length; i++) {
                let tbl = tables[db.name][tbnames[i]].tablescope
                for (let j = 0; j < tbl.length; j++) {
                    if (tbl[j].reference != null) {

                        let rsch = tbl[j].reference.schema
                        let rtbl = tbl[j].reference.table
                        references[rsch + "." + rtbl] = {
                            schema: rsch, table: rtbl,
                            refby: tables[db.name][tbnames[i]].schema + "." + tables[db.name][tbnames[i]].table
                        }
                    }
                }
            }

            //
            let lines: JSX.Element[] = []
            Object.keys(references).forEach((key) => {
                if (tables[db.name][key] !== undefined)
                    return
                let { schema, table, refby } = references[key]
                let r = <div className="m-1">Table {refby} refers to {schema}.{table}. <Button onClick={
                    e => {

                        if (db.id !== null) {
                            let c = props.nameToConnection[db.name]
                            editedConnection.current = c.name
                            props.AddNewTable("", "", props.nameToConnection[db.name].id!, props.nameToConnection[db.name].dbtype, props.description, schema, table)
                        }
                    }

                } size="sm" style={{ marginTop: "-2px" }} variant="dymium">Click to link {schema}.{table}</Button></div>
                lines.push(r)
            })
            return lines
        }

        return <Card key={db.name} id={db.name} className="card mb-3">
            <Card.Header >
                <Row>
                    <Col xs="auto" style={{ paddingTop: '2px', fontSize: '1.2em' }} className="thickblue">
                        <i className="fa-solid fa-database mr-2"></i>
                        Connection: {db.name}</Col>
                    <Col><Button onClick={e => {

                        editedConnection.current = db.name
                        if (db.id !== null) {
                            if (props.nameToConnection[db.name]?.id) {
                                props.AddNewTable("", "", props.nameToConnection[db.name].id!, props.nameToConnection[db.name].dbtype, "")
                            }
                        }

                    }} size="sm" variant="dymium"><i className="fa fa-table mr-1" aria-hidden="true"></i>Link Table</Button></Col>
                    <Col xs="auto" className="text-right"><i onClick={deleteConnection} title={"Unlink " + db.name} className="fa fa-unlink blue trash" style={{ marginRight: '18px' }} aria-hidden="true"></i></Col>
                </Row>
            </Card.Header>
            <Card.Body className="p-0 mx-0">
                {tables[db.name] !== undefined && Object.keys(tables[db.name]).length > 0 &&
                    <BootstrapTable id={"scaledtable" + db.name}
                        striped bordered={false}
                        bootstrap4
                        keyField='id'
                        data={displayTables(db.name)}
                        columns={getColumns(db.name, db.id!)}

                    />
                }
                {
                    showDependencies()
                }
            </Card.Body>
        </Card>
    }
    let showConnections = () => {
        return connections.map(name => {
            if (name === "") {
                return <></>
            }
            let ob: types.ConnectionRecord = props.nameToConnection[name]
            return showConnection(ob)
        })
    }

    return (
        <div id="datascopehost" ref={containerref} style={{ position: 'relative' }}>

            <Modal
                show={overlayState.show && (overlayState.typ == OverlayMode.Schema)}
                target={overlayState.target}
                placement="bottom"
                container={containerref}
                onHide={handleCancel}
                style={{
                    position: 'absolute',
                    top: overlayState.top,
                    left: overlayState.left,
                    width: "300px", // Increase the width here
                    height: '300px'
                }}

            >
                <Modal.Header className="whiteonblue " style={{ fontSize: "0.8em", padding: '4px' }}>
                    Map original schema to GhostDb schema
                </Modal.Header>
                <Modal.Body style={{ padding: '0px' }}>

                    <div style={{ padding: '10px' }}>
                        <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                            <span style={{ fontWeight: 'bold', whiteSpace: 'nowrap' }}>{overlayState.originalValue} =&gt;</span>
                            <Form.Control
                                type="text"
                                value={overlayState.value}
                                size="sm"

                                style={{ flex: 1, maxWidth: '15em' }}
                                onChange={({ target: { value } }) => {
                                    let dbl = false
                                    value = value.trim()
                                    let tuples: [string, string][] = []

                                    Object.keys(tables).forEach(sdb => {
                                        let db = tables[sdb]
    
                                        // iterate over tbl dictionary keys
                                        Object.keys(db).forEach(x => {
                                            let ln = db[x]
                                            
                                            if(ln.schema === overlayState.row.schema && ln.table === overlayState.row.table &&
                                                ln.connection === overlayState.row.connection
                                            )
                                                return
                                            tuples.push([ln.ghostSchema, ln.ghostTable])
                                        })
    
                                    })
                                    dbl = isThereConflict(tuples, [value, overlayState.row.ghostTable])
                                    setTableConflict(dbl)   
                                    
                                setOverlayState((state) => ({ ...state, value }))
                            }}
                            />
                        </div>
                    </div>
                </Modal.Body>

                <Modal.Footer style={{ padding: '0px' }} >
                    <div className="w-100 d-flex justify-content-between align-items-center mt-2">
                        <Form.Check
                            defaultChecked={applyAll}
                            style={{ marginTop: '0.2em' }}
                            type="checkbox"
                            label="Apply to all"
                            id="applyall"
                            onChange={e => {
                                setApplyAll(e.target.checked)
                            }}
                        />
                        <div className="d-flex">
                            <Button
                                variant={(overlayState.value.length === 0 || tableConflict) ? "danger" : "dymium"}
                                size="sm"
                                disabled={overlayState.value.length === 0 || tableConflict}
                                onClick={handleSaveSchema}
                            >
                                OK
                            </Button>
                            <Button
                                variant="dymium"
                                size="sm"
                                className="ms-2"
                                onClick={handleCancel}
                            >
                                Cancel
                            </Button>
                        </div>
                    </div>
                </Modal.Footer>
            </Modal>

            {/* Shared Overlay for table*/}
            <Modal
                show={overlayState.show && (overlayState.typ == OverlayMode.Name)}
                target={overlayState.target}
                placement="bottom"
                container={containerref}
                onHide={handleCancel}
                style={{
                    position: 'absolute',
                    top: overlayState.top,
                    left: overlayState.left,
                    width: "300px", // Increase the width here
                    height: '300px'
                }}
            >
                <Modal.Header className="whiteonblue " style={{ fontSize: "0.8em", padding: '4px' }}>
                    Map original table to GhostDb table
                </Modal.Header>
                <Modal.Body style={{ padding: '4px' }}>

                    <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                        <span style={{ fontWeight: 'bold', whiteSpace: 'nowrap' }}>{overlayState.originalValue} =&gt;</span>
                        <Form.Control
                            type="text"
                            value={overlayState.value}
                            size="sm"

                            style={{ flex: 1, maxWidth: '15em' }}
                            onChange={({ target: { value } }) => {
                                let dbl = false
                                value = value.trim()
                                let tuples: [string, string][] = []

                                Object.keys(tables).forEach(sdb => {
                                    let db = tables[sdb]

                                    // iterate over tbl dictionary keys
                                    Object.keys(db).forEach(x => {
                                        let ln = db[x]
                                        if(ln.schema === overlayState.row.schema && ln.table === overlayState.row.table &&
                                            ln.connection === overlayState.row.connection
                                        )
                                            return
                                        tuples.push([ln.ghostSchema, ln.ghostTable])
                                    })

                                })
                                dbl = isThereConflict(tuples, [overlayState.row.ghostSchema, value])
                                setTableConflict(dbl)
                                setOverlayState((state) => ({ ...state, value }))
                            }
                            }
                        />
                    </div>
                </Modal.Body>
                <Modal.Footer style={{ padding: '0px' }} >
                    <div className="d-flex justify-content-end mt-2">
                        <Button
                            variant={(overlayState.value.length === 0 || tableConflict) ? "danger" : "dymium"}
                            size="sm"
                            onClick={handleSaveTable}
                            disabled={overlayState.value.length === 0 || tableConflict}
                        >
                            OK
                        </Button>
                        <Button
                            variant="dymium"
                            size="sm"
                            className="ms-2"
                            onClick={handleCancel}
                        >
                            Cancel
                        </Button>
                    </div>
                </Modal.Footer>
            </Modal>

            {!props.edit &&
                <Row>
                    <Col xs="auto">
                        <Form.Group className="mb-3" controlId="dbname">
                            <Form.Label>Ghost Database Name:</Form.Label>
                            <Form.Control size="sm" type="text" placeholder="alphanumeric_"
                                required
                                autoComplete="off"
                                pattern="(?![Pp][Oo][Ss][Tt][Gg][Rr][Ee][Ss]$)(?![Pp][Gg]_)[A-Za-z][A-Za-z0-9_]+"
                                value={props.dbname}
                                onChange={e => {

                                    props.onDbname(e.target.value)
                                }}
                            />
                            <Form.Control.Feedback >Looks good!</Form.Control.Feedback>
                            <Form.Control.Feedback type="invalid" >
                                Client side name for Dymium database
                            </Form.Control.Feedback>
                        </Form.Group>

                    </Col>
                </Row>
            }
            <Row>
                <Col xs="auto" className="d-flex" style={{ alignItems: "center" }}>
                    <Form.Group className="mb-3" controlId="connection" >
                        <Form.Label >Available Data Sources:</Form.Label>
                        <Form.Control as="select" size="sm"
                            onChange={e => {

                                setSelectedConnection(e.target.value)
                            }}
                            value={selectedConnection}
                        >
                            <option value="">...</option>
                            {available()}
                        </Form.Control>

                    </Form.Group>
                    <Form.Group>
                        <Form.Label ></Form.Label>
                        <Button onClick={onAddConnection} variant="dymium" style={{ marginTop: '0.95em' }} size="sm"><i className="fa-solid fa-database mr-2"></i>Link Connection</Button>
                    </Form.Group>
                </Col>
                <Col className="text-left">

                </Col>

            </Row>
            {showConnections()}

            <Form.Group className="mb-3 mt-4 me-3 ms-3" controlId="description">
                <Form.Label>Ghost Database Description:</Form.Label>
                <Form.Control
                    as="textarea"
                    rows={3}
                    style={{ width: "100%", marginRight: "30px" }}
                    required
                    placeholder="Please put in the semantic description of this database"
                    onChange={(e) => {
                        let value = e.target.value.trimStart()
                        props.onDescription(value)}
                    }
                    value={props.description}
                />
                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                <Form.Control.Feedback type="invalid">
                    Please put in some description
                </Form.Control.Feedback>
            </Form.Group>
        </div>
    )
}

export default DatascopeForm