// Precompiled regular expressions
const keywords = new RegExp(
  /^(ALL|ANALYSE|ANALYZE|AND|ANY|ARRAY|AS|ASC|ASYMMETRIC|AUTHORIZATION|BINARY|BOTH|CASE|CAST|CHECK|COLLATE|COLLATION|COLUMN|CONCURRENTLY|CONSTRAINT|CREATE|CROSS|CURRENT_CATALOG|CURRENT_DATE|CURRENT_ROLE|CURRENT_SCHEMA|CURRENT_TIME|CURRENT_TIMESTAMP|CURRENT_USER|DEFAULT|DEFERRABLE|DESC|DISTINCT|DO|ELSE|END|EXCEPT|FALSE|FETCH|FOR|FOREIGN|FREEZE|FROM|FULL|GRANT|GROUP|HAVING|ILIKE|IN|INITIALLY|INNER|INTERSECT|INTO|IS|ISNULL|JOIN|LATERAL|LEADING|LEFT|LIKE|LIMIT|LOCALTIME|LOCALTIMESTAMP|NATURAL|NOT|NOTNULL|NULL|OFFSET|ON|ONLY|OR|ORDER|OUTER|OVERLAPS|PLACING|PRIMARY|REFERENCES|RETURNING|RIGHT|SELECT|SESSION_USER|SIMILAR|SOME|SYMMETRIC|TABLE|TABLESAMPLE|THEN|TO|TRAILING|TRUE|UNION|UNIQUE|USER|USING|VARIADIC|VERBOSE|WHEN|WHERE|WINDOW|WITH)$/i
);

const invalid = new RegExp(/(^\d)|([^a-zA-Z0-9_])/);

// Escapes a string for use as a PostgreSQL identifier or keyword
const postgresEscape = (tok: string): string => {
    if (invalid.test(tok)) {
	return tok;
    } else if (keywords.test(tok)) {
	return tok;
    }
    return tok.toLowerCase();
};


// isThereconflict returns true if there is a conflict
export const isThereConflict = (existingTables: [string, string][], newTable: [string, string]): boolean => {
    const [ns, nt] = [postgresEscape(newTable[0]), postgresEscape(newTable[1])];
    return existingTables.some(([s,t]) => postgresEscape(s) === ns && postgresEscape(t) === nt);
}
