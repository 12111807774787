import React, { useEffect, useState, useRef } from 'react';
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Offcanvas from '@dymium/common/Components/Offcanvas'
import Alert from 'react-bootstrap/Alert'
import Spinner from '@dymium/common/Components/Spinner'
import { Link } from 'react-router-dom'
import Modal from 'react-bootstrap/Modal'
import cloneDeep from 'lodash/cloneDeep';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { useNavigate } from "react-router-dom";
import AddTable from './AddTable'
import { useAppDispatch, useAppSelector } from './hooks'
import { setSelectedDatascopeDefault } from '../Slices/menuSlice'
import * as com from '../Common'
import DatascopeForm from './DatascopeForm'
import * as internal from '@dymium/common/Types/Internal'
import * as types from '@dymium/common/Types/Common'
import * as capi from '../Api/Connections'
import * as http from '@dymium/common/Api/Http'
import { isThereConflict } from '../Utils/PostgresConflicts'

const { SearchBar, ClearSearchButton } = Search;

let remap = new internal.ConnectionMap();

export default function EditDatascopes() {
    const [spinner, setSpinner] = useState(false)
    let [conns, setConns] = useState<internal.Connection[]>([])
    const [alert, setAlert] = useState<JSX.Element>(<></>)
    const [showdelete, setShowdelete] = useState(false)
    const [slatedToDelete, setSlatedToDelete] = useState("")
    const [datascopes, setDatascopes] = useState<types.DatascopeIdName[]>([])
    const [selectedDatascope, setSelectedDatascope] = useState("")
    const [selectedDatascopeDetails, setSelectedDatascopeDetails] = useState<types.Datascope | null>(null)

    const [initialTables, setInitialTables] = useState<internal.TablesMap>({})
    const [validated, setValidated] = useState(false)
    let form = useRef<HTMLFormElement>(null)

    let setSDRef = useRef(setSelectedDatascope)
    let cloneref = useRef<HTMLFormElement>(null)
    const [clonevalidated, setClonevalidated] = useState(false)
    const [showOffcanvas, setShowOffcanvas] = useState<boolean>(false)
    const [table, setTable] = useState<internal.TableScope>({ schema: "", description: "", table: "", ghostSchema: "", ghostTable: "", id: "", tableId: "", connectionId: "", tablescope: [] })
    const [dbname, setDbname] = useState("")
    const [datascope, setDatascope] = useState({})
    const [currentConnectionType, setCurrentConnectionType] = useState("")
    const [showOffhelp, setShowOffhelp] = useState(com.isInstaller())
    const [showclone, setShowclone] = useState(false)
    const [clonename, setClonename] = useState("")
    const [description, setDescription] = useState("")
    let timeoutid = useRef<number | null>(null)
    const navigate = useNavigate();
    let t = useAppSelector((state) => {

        return state.reducer.selectedDatascope
    }
    )
    const appDispatch = useAppDispatch()
    let nameById = id => {
        for (let i = 0; i < datascopes.length; i++) {
            if (id === datascopes[i].id)
                return datascopes[i].name
        }
        return ""
    }
    let onEdit = (id) => {
        return e => {
            console.log(id)
            setSDRef.current(id)

            appDispatch(setSelectedDatascopeDefault(id))
        }
    }
    let onDelete = (id) => {
        return e => {
            setSlatedToDelete(id)
            setShowdelete(true)
        }
    }
    let columns = [
        {
            dataField: 'id',
            text: 'id',
            hidden: true,
            searchable: false
        },

        {
            dataField: 'name',
            text: 'Name:',
            sort: true,
            classes: 'overflow-x-scroll'
        },
        {
            dataField: 'created',
            text: 'Created:',
            sort: true,
            headerStyle: { width: 'auto' },
            formatter: (cell, row, rowIndex, formatExtraData) => {
                const date = new Date(row["created"]);
                const options: Intl.DateTimeFormatOptions = {
                    timeZoneName: "short"
                }
                const dateString = date.toLocaleString("en-US", options)
                return <div>{dateString}</div>
            }
        },
        {
            dataField: 'modified',
            text: 'Modified:',
            sort: true,
            headerStyle: { width: 'auto' },
            formatter: (cell, row, rowIndex, formatExtraData) => {
                const date = new Date(row["modified"]);
                const options: Intl.DateTimeFormatOptions = {
                    timeZoneName: "short"
                }
                const dateString = date.toLocaleString("en-US", options)
                return <div>{dateString}</div>
            }
        },
        {
            text: 'Edit',
            dataField: 'edit',
            isDummyField: false,
            formatter: (cell, row, rowIndex, formatExtraData) => {
                return <i className="fas fa-edit ablue" onClick={onEdit(row["id"])} role="button"></i>
            },
            //formatExtraData: { hoverIdx: this.state.hoverIdx },
            headerStyle: { width: '50px' },
            style: { height: '30px' },
            align: 'center'
        },
        {
            text: 'Delete',
            dataField: 'delete',
            isDummyField: true,
            formatter: (cell, row, rowIndex, formatExtraData) => {
                return <i className="fas fa-trash ablue" onClick={onDelete(row["id"])} role="button"></i>
            },
            //formatExtraData: { hoverIdx: this.state.hoverIdx },
            headerStyle: { width: '90px' },
            style: { height: '30px' },
            align: 'center'
        }
    ]

    let selectRow = {
        mode: 'radio',
        //clickToSelect: true,
        style: { backgroundColor: 'rgba(0, 151, 206, 0.3)' },
        selected: [t],
        onSelect: (row, isSelect, rowIndex, e) => {
            setAlert(<></>)
            setSDRef.current(row["id"])
            appDispatch(setSelectedDatascopeDefault(row["id"]))
        },
    };
    let reload = () => {
        capi.getConnections(setSpinner, setConns, setAlert, remap, () => {

            com.getDatascopes(setSpinner, setAlert, setDatascopes, () => {
                setSelectedDatascope(t)
            })
        })
    }
    useEffect(() => {
        reload()
    }, [])
    useEffect(() => {
        if (selectedDatascope === "")
            return
        let body = types.DatascopeId.fromJson({ id: selectedDatascope }).toJson()
        setSpinner(true)
        http.sendToServer("POST", "/api/getdatascopedetails",
            null, body,
            resp => {
                resp.json().then(fjs => {
                    let ojs = types.DatascopeInfoStatus.fromJson(fjs)
                    if (ojs.status !== "OK") {
                        setSpinner(false)
                        if (ojs.status !== "sql: no rows in result set")
                            setAlert(
                                <Alert variant="success" onClose={() => setAlert(<></>)} dismissible>
                                    {ojs.errormessage}
                                </Alert>
                            )
                        setSelectedDatascope("")
                        appDispatch(setSelectedDatascopeDefault(""))
                        return
                    }
                    
                    let js = ojs.record
                    if (js == null) {
                        setSpinner(false)
                        setAlert(
                            <Alert variant="success" onClose={() => setAlert(<></>)} dismissible>
                                No record returned
                            </Alert>
                        )
                    }

                    setSelectedDatascopeDetails(js)
                    if (js != null && !js.groupsconfigured)
                        setAlert(
                            <Alert variant="warning" onClose={() => setAlert(<></>)} dismissible>
                                No groups are configured for the Ghost Database {js?.name}! <Link to="?key=groups">Click here</Link> to configure.
                            </Alert>
                        )

                    if (js != null)
                        setDbname(js.name)
                    let ob = {}
                    
                    if (js != null && js.records != null) {
                        // first build the tables
                        js.tables.forEach(r => {
                            if (ob[r.connection] === undefined) {
                                ob[r.connection] = {}
                            }
                            let key = r.schema + "." + r.table

                            ob[r.connection][key] = {}
                            ob[r.connection][key]["id"] = r.id
                            ob[r.connection][key]["tableId"] = r.id
                            ob[r.connection][key]["connection"] = r.connection
                            ob[r.connection][key]["connectionId"] = r.connectionId
                            ob[r.connection][key]["schema"] = r.schema
                            ob[r.connection][key]["ghostSchema"] = r.ghostSchema
                            ob[r.connection][key]["table"] = r.table
                            ob[r.connection][key]["ghostTable"] = r.ghostTable
                            ob[r.connection][key]["description"] = r.description
                            ob[r.connection][key]["tablescope"] = []
                        })

                        js.records.forEach(r => {
                            if (ob[r.connection] === undefined) {
                                ob[r.connection] = {}
                            }
                            let key = r.schema + "." + r.table

                            let line = {}
                            line["id"] = r.id
                            line["table_id"] = r.tableId
                            line["connection"] = r.connection
                            line["action"] = r.action
                            line["name"] = r.col
                            line["ghostName"] = r.ghostCol
                            line["position"] = r.position
                            line["reference"] = r.reference                          
                            line["semantics"] = r.semantics
                            line["description"] = r.description
                            line["dflt"] = r.dflt
                            line["isnullable"] = r.isnullable
                            line["possibleActions"] = r.possibleActions
                            line["typ"] = r.typ
                            line["ghostTyp"] = r.ghostTyp

                            ob[r.connection][key]["tablescope"].push(line)

                        })
                    }
                    setDescription(js!.description)
                    setInitialTables(ob)

                })
                setSpinner(false)
                capi.getConnections(setSpinner, setConns, setAlert, remap, () => {
                    com.getDatascopes(setSpinner, setAlert, setDatascopes, () => {
                        setSelectedDatascope(t)
                    })
                })
            },
            resp => {
                console.log("on error")
                setSpinner(false)
                resp != null && resp.text().then(t =>
                    setAlert(
                        <Alert variant="danger" onClose={() => setAlert(<></>)} dismissible>
                            {t}
                        </Alert>
                    )
                )
            },
            error => {
                console.log("on exception: " + error)
                setSpinner(false)
            })

    }, [selectedDatascope]
    )

    let updateConnection = () => {
        let retarray: internal.DatascopeRecord[] = []
        let tables: types.DatascopeTable[] = []

        Object.keys(datascope).forEach(connection => {
            let conn = datascope[connection]
            Object.keys(conn).forEach(schematable => {
                let st = conn[schematable]
                // connection, schema, table, tablescope[typ, semantics, name, position, reference, action]
                let tbl: types.DatascopeTable = types.DatascopeTable.fromJson( {
                    connection: st.connection, 
                    id: st.tableId,
                    datascope:  dbname,
                    connectionId: st.connectionId,
                    datascopeId: selectedDatascope,
                    schema: st.schema, ghostSchema: st.ghostSchema, 
                    table: st.table, ghostTable: st.ghostTable, 
                    description: st.description
                } )
                tables.push(tbl)

                st.tablescope.forEach(ts => {
                    let ref: null | internal.Reference = null
                    if (ts.reference != null) {
                        ref = { schema: ts.reference.schema, table: ts.reference.table, column: ts.reference.column }
                    }
                    let ob: internal.DatascopeRecord = {
                        connection: st.connection, 
                        connectionId: st.connectionId,
                        tableId: st.tableId,
                        schema: st.schema, ghostSchema: st.ghostSchema, 
                        table: st.table, ghostTable: st.ghostTable,
                        typ: ts.typ, ghostTyp: ts.ghostTyp,
                        position: ts.position, reference: ts.reference, action: ts.action,
                        col: ts.name, 
                        ghostCol: ts.ghostName,
                        description: ts.description,
                        semantics: ts.semantics, dflt: ts.dflt, isnullable: ts.isnullable,
                        possibleActions: ts.possibleActions
                    }
                    retarray.push(ob)
                })
            })

        })
        if (retarray.length === 0) {
            DeleteDatascope(selectedDatascope)
            setSelectedDatascope("")
            return
        }
        // extract tables
        //datascope.tables.
        // now do send
        setSpinner(true)
        let retob = new types.Datascope()
        retob.name = dbname
        retob.id = selectedDatascope
        retob.records = retarray
        retob.description = description
        retob.tables = tables
        let body = retob.toJson()
        http.sendToServer("POST", "/api/updatedatascope",
            null, body,
            resp => {

                resp.json().then(js => {
                    if (js.status === "OK") {
                        setAlert(
                            <Alert variant="success" onClose={() => setAlert(<></>)} dismissible>
                                Ghost Database {dbname} updated successfully!
                            </Alert>
                        )
                        capi.getConnections(setSpinner, setConns, setAlert, remap, () => {

                            com.getDatascopes(setSpinner, setAlert, setDatascopes, () => {
                                setSelectedDatascope(t)
              
                            })
                        })
                    } else {
                        setAlert(
                            <Alert variant="danger" onClose={() => setAlert(<></>)} dismissible>
                                Error updating {dbname}:  {js.errormessage}!
                            </Alert>
                        )
                    }
                })
                setSpinner(false)
            },
            resp => {
                console.log("on error")
                setSpinner(false)
                resp != null && resp.text().then(t =>
                    setAlert(
                        <Alert variant="danger" onClose={() => setAlert(<></>)} dismissible>
                            Error updating {dbname}:  {t}
                        </Alert>
                    ))
            },
            error => {
                console.log("on exception: " + error)
                setSpinner(false)
            })

    }
    let addTableR: any = useRef(null)
    let onAddTableRef = (theref) => {
        addTableR.current = theref
    }
    let onAddTable = (table) => {
        setShowOffcanvas(false)
        if (addTableR.current !== undefined && addTableR.current.current) {

            addTableR.current.current(table)
        }
    }
    let isSubmittable = (datascope) => {
        let conns = Object.keys(datascope)
        let _submittable = true
        if (conns.length === 0) {
            _submittable = false
        } else {
            for (let i = 0; i < conns.length; i++) {
                let connection = conns[i]
                let conn = datascope[connection]
                let schematables = Object.keys(conn)
                if (schematables.length === 0) {
                    _submittable = false
                    break
                }
                for (let j = 0; j < schematables.length; j++) {
                    let schematable = schematables[j]
                    let st = conn[schematable]
                    if (st.tablescope.length === 0) {
                        _submittable = false
                        break
                    }
                }
            }
        }
        return _submittable
    }
    let handleSubmit = event => {
        if (form.current == null) {
            return false
        }
        if (form.current.reportValidity() === false) {
            event.preventDefault();
            setValidated(true)
            return false
        }
        let _submittable = isSubmittable(datascope)
        if (!_submittable) {
            setAlert(
                <Alert variant="danger" onClose={() => setAlert(<></>)} dismissible>
                    Can't save a Ghost Database with empty or no connections. Either remove them, or add connections and tables.
                </Alert>
            )
            event.preventDefault();
            setValidated(true)
            return false
        }
        event.preventDefault();
        setValidated(false)
        event.stopPropagation();

        updateConnection()

        return false
    }
    let onTablesMapUpdate = (t: internal.TablesMap) => {
        setDatascope(t)
    }

    let onEditTable = (t: internal.TableScope) => {
        setTable(t)
        setShowOffcanvas(true)
    }

    let addNewTable = (id: string, tableId: string, connectionId:string, dbtype: string, description:string, schema?: string, tbl?: string, ghostSchema?: string, ghostTable?: string) => {

        setCurrentConnectionType(dbtype)
        
        if (schema !== undefined && table !== undefined && ghostSchema === undefined ) {
            ghostSchema = schema
            ghostTable = tbl
        }

        if (schema === undefined || table === undefined || ghostSchema === undefined || ghostTable === undefined)
            setTable({ schema: "", description :"", table: "", ghostSchema: "", ghostTable: "", id: "", tableId: "", connectionId: connectionId, tablescope: [] })
        else
            setTable({ schema, description, table:tbl!, ghostSchema, ghostTable, id, tableId, connectionId })
        setTimeout( () => setShowOffcanvas(true), 100)
    }
    let DeleteDatascope = toDelete => {
        let retob: types.DatascopeIdName = new types.DatascopeIdName()
        retob.id = toDelete
        retob.name = nameById(toDelete)
        let body = retob.toJson()

        http.sendToServer("POST", "/api/deletedatascope",
            null, body,
            resp => {

                resp.json().then(js => {
                    if (js.status === "OK") {
                        setAlert(
                            <Alert variant="success" onClose={() => setAlert(<></>)} dismissible>
                                Ghost Database {dbname} deleted successfully!
                            </Alert>
                        )
                        capi.getConnections(setSpinner, setConns, setAlert, remap, () => {

                            com.getDatascopes(setSpinner, setAlert, setDatascopes, () => {
                                if (slatedToDelete !== t) {
                                    //setSelectedDatascope(t)
                                    //appDispatch(setSelectedDatascopeDefault(""))
                                } else {
                                    setSelectedDatascope("")
                                    appDispatch(setSelectedDatascopeDefault(""))
                                }
                            })
                        })
                    } else {
                        setAlert(
                            <Alert variant="danger" onClose={() => setAlert(<></>)} dismissible>
                                Error deleting {dbname}:  {js.errormessage}
                            </Alert>
                        )
                    }
                })
                setSpinner(false)
            },
            resp => {
                console.log("on error")
                setSpinner(false)
                resp != null && resp.text().then(t =>
                    setAlert(
                        <Alert variant="danger" onClose={() => setAlert(<></>)} dismissible>
                            Error deleting {dbname}:  {t}
                        </Alert>
                    )
                )
            },
            error => {
                console.log("on exception: " + error)
                setSpinner(false)
            })

    }
    let onDeleteDatascope = () => {
        setShowdelete(false)
        setSpinner(true)
        DeleteDatascope(slatedToDelete)

    }
    let onDeleteConnection = (c: string) => {
        delete datascope[c]
        setDatascope(datascope)
    }
    let cloneSelected = () => {
        let _submittable = isSubmittable(datascope)
        if (!_submittable) {
            setAlert(
                <Alert variant="danger" onClose={() => setAlert(<></>)} dismissible>
                    Can't save a Ghost Database with empty or no connections. Either remove them, or add connections and tables.
                </Alert>
            )
            return
        }

        let retarray: internal.DatascopeRecord[] = []
        let tables: types.DatascopeTable[] = []

        Object.keys(datascope).forEach(connection => {
            let conn = datascope[connection]
            Object.keys(conn).forEach(schematable => {
                let st = conn[schematable]
                // connection, schema, table, tablescope[typ, semantics, name, position, reference, action]
                let tbl: types.DatascopeTable = types.DatascopeTable.fromJson( {
                    connection: st.connection, 
                    id: "",
                    datascope:  dbname,
                    connectionId: st.connectionId,
                    datascopeId: "",
                    schema: st.schema, ghostSchema: st.ghostSchema, 
                    table: st.table, ghostTable: st.ghostTable, 
                    description: st.description
                } )
                tables.push(tbl)

                st.tablescope.forEach(ts => {
                    let ref: null | internal.Reference = null
                    if (ts.reference != null) {
                        ref = { schema: ts.reference.schema, table: ts.reference.table, column: ts.reference.column }
                    }
                    let ob: internal.DatascopeRecord = {
                        connection: st.connection, 
                        connectionId: st.connectionId,
                        tableId: "",
                        schema: st.schema, ghostSchema: st.ghostSchema, 
                        table: st.table, ghostTable: st.ghostTable,
                        typ: ts.typ, ghostTyp: ts.ghostTyp,
                        position: ts.position, reference: ts.reference, action: ts.action,
                        col: ts.name, 
                        ghostCol: ts.ghostName,
                        description: ts.description,
                        semantics: ts.semantics, dflt: ts.dflt, isnullable: ts.isnullable,
                        possibleActions: ts.possibleActions
                    }
                    retarray.push(ob)
                })
            })

        })
        if (retarray.length === 0) {
            DeleteDatascope(selectedDatascope)
            setSelectedDatascope("")
            return
        }
        // extract tables
        //datascope.tables.
        // now do send
        setSpinner(true)
        let retob = new types.Datascope()
        retob.name = clonename
        retob.id = ""
        retob.records = retarray
        retob.description = description
        retob.tables = tables
        let body = retob.toJson()  
        window.scroll({ top: 0, left: 0, behavior: 'smooth' })
        http.sendToServer("POST", "/api/savedatascope",
            null, body,
            resp => {

                resp.json().then(js => {
                    if (js.status === "OK") {
                        setAlert(
                            <Alert variant="success" onClose={() => setAlert(<></>)} dismissible>
                                Ghost Database {clonename} created successfully!<br />
                                <Link to="?key=groups">We are navigating you now </Link>to assign groups make it accessible to users.
                            </Alert>
                        )
                        window.scroll({ top: 0, left: 0, behavior: 'smooth' })
                        timeoutid.current = window.setTimeout(() => {
                            timeoutid.current = null
                            navigate("?key=groups")
                        }, 3000)

                    } else {
                        setAlert(
                            <Alert variant="danger" onClose={() => setAlert(<></>)} dismissible>
                                Error creating {clonename}:  {js.errormessage}!
                            </Alert>
                        )
                    }
                })
                setSpinner(false)
            },
            resp => {
                console.log("on error")
                setSpinner(false)
                resp != null && resp.text().then(t =>
                    setAlert(
                        <Alert variant="danger" onClose={() => setAlert(<></>)} dismissible>
                            Error creating {clonename}:  {t}!
                        </Alert>
                    )
                )
            },
            error => {
                console.log("on exception: " + error)
                setSpinner(false)
            })
    }
    let doClone = event => {
        if (cloneref.current == null) {
            setClonevalidated(false)
            return false
        }
        if (cloneref.current.reportValidity() === false) {
            event.preventDefault();
            setClonevalidated(true)
            return false
        }
        let done = cloneSelected()
        setShowclone(false)
        event.preventDefault();
        setClonevalidated(false)
        event.stopPropagation();
    }
    let onCheckCollision = (ar: internal.TableScope) => {
        let tuples: [string, string][] = []

        Object.keys(datascope).forEach(connection => {
            let conn = datascope[connection]
            
            Object.keys(conn).forEach(schematable => {
                if(conn[schematable].connectionId === ar.connectionId  && 
                    conn[schematable].schema === ar.schema && conn[schematable].table === ar.table
                ) {
                    return
                }
                let st = conn[schematable]
                let t: [string, string] = [st.ghostSchema, st.ghostTable]
                tuples.push(t)
            }
            )
        }
        )
        return isThereConflict(tuples, [ar.ghostSchema, ar.ghostTable])
    }
    return (
        <div className=" text-left">
            {alert}
            <Offcanvas show={showOffcanvas} onClose={(e) => { setShowOffcanvas(false) }}
                title={table["connection"] === undefined ? "Link table" : "Edit table"}>
                {showOffcanvas &&
                    <AddTable onCheckCollision={onCheckCollision} onHide={() => { setShowOffcanvas(false) }} onAlert={setAlert} onAddTable={onAddTable} table={table} 
                    currentConnectionType={currentConnectionType} connectionId={table.connectionId}id={table.id} tableId={table.tableId} />
                }
            </Offcanvas>
            <Offcanvas modal={false} width={300} show={showOffhelp} onClose={(e) => { setShowOffhelp(false) }}>
                <h5>Editing Ghost Database</h5>
                <div className="mb-3">
                    This page allows to edit or remove a Ghost Database.
                </div>
                <div className="mb-3">
                    The same interface as for adding a Ghost Database is used. You select Data Sources and tables that you want to expose
                </div>
            </Offcanvas>
            <Modal size="lg" centered show={showdelete} onHide={() => {
                setShowdelete(false)
            }} data-testid="modal-delete">
                <Modal.Header closeButton>
                    <Modal.Title>Delete Datascope {nameById(slatedToDelete)}?</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure you want to remove the Datascope? This operation is irreversible.</Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" role="button" id="Delete" data-testid="Delete"
                        aria-label={"Delete"}
                        onClick={() => {
                            onDeleteDatascope()
                        }
                        }>Delete</Button> <Button variant="dymium" onClick={() => {
                            setShowdelete(false)
                        }}>Cancel</Button>
                </Modal.Footer>
            </Modal>

            <Modal centered show={showclone} onHide={() => {
                setShowclone(false)
                setClonevalidated(false)
            }
            } data-testid="modal-delete">
                <Modal.Header closeButton>
                    <Modal.Title>Clone {selectedDatascopeDetails?.name}?</Modal.Title>
                </Modal.Header>
                <Form ref={cloneref} noValidate validated={clonevalidated} onSubmit={doClone}>
                    <Modal.Body>

                        <Row>
                            <Col>
                                <Form.Group className="mb-3" controlId="name" >
                                    <Form.Label >New Ghost Database Name:</Form.Label>
                                    <Form.Control type="text" size="sm"
                                        required
                                        placeholder="alphanumeric_"
                                        autoComplete="off"
                                        pattern="(?![Pp][Oo][Ss][Tt][Gg][Rr][Ee][Ss]$)(?![Pp][Gg]_)[A-Za-z][A-Za-z0-9_]+"
                                        onChange={e => {
                                            setClonename(e.target.value)
                                        }}
                                        value={clonename}
                                    />
                                    <Form.Control.Feedback >Looks good!</Form.Control.Feedback>
                                    <Form.Control.Feedback type="invalid" >
                                        Type a unique Ghost Database name
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                        </Row>

                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="danger" role="button" id="Clone" data-testid="Clone"
                            aria-label={"Clone"} type="submit"
                        >Clone</Button> <Button variant="dymium" onClick={() => {
                            setShowclone(false)
                            setClonevalidated(false)
                        }}>Cancel</Button>
                    </Modal.Footer>
                </Form>
            </Modal>

            <div className=" text-left">
                {/*
                <Row> <Col xs="auto">
                    <Form.Group className="mb-3" controlId="connection" >
                        <Form.Label >Available Ghost Databases</Form.Label>
                        <Form.Control as="select" size="sm"
                            onChange={e => {

                                setSelectedDatascope(e.target.value)
                                appDispatch( setSelectedDatascopeDefault(e.target.value) )

                            }}
                            value={selectedDatascope}
                        >
                            return <option value="">...</option>
                            {datascopes != null && datascopes.map(x => {

                                return <option key={x.id} value={x.id}>{x.name}</option>
                            })
                            }
                        </Form.Control>

                    </Form.Group>
                </Col>
                </Row>
                */}
                <Row>
                    <Col>
                        <ToolkitProvider
                            bootstrap4

                            keyField='id'
                            data={datascopes}
                            columns={columns}
                            search >
                            {
                                props => (
                                    <div className="text-left">

                                        <div className="d-flex">
                                            <h5 >Edit Ghost Databases  <i onClick={e => { setShowOffhelp(!showOffhelp) }} className="trash fa-solid fa-circle-info mr-1"></i><Spinner show={spinner} style={{ width: '28px' }}></Spinner></h5>


                                            <div style={{ marginLeft: "auto" }}>
                                                <SearchBar size="sm" {...props.searchProps} />
                                                <ClearSearchButton {...props.searchProps} />
                                                <i onClick={e => reload()} className="fa fa-refresh ablue cursor-pointer" style={{ position: 'relative', top: '2px' }} aria-hidden="true"></i>

                                            </div>
                                        </div>
                                        <div className="d-block">
                                            <BootstrapTable id="datascopetable"
                                                condensed
                                                keyField='id'
                                                selectRow={selectRow}
                                                defaultSorted={[{
                                                    dataField: 'modified',
                                                    order: 'desc'
                                                }]}
                                                striped bootstrap4 bordered={false}
                                                pagination={paginationFactory({
                                                    sizePerPage: 5,
                                                    sizePerPageList: [5, 10, 15, 20]
                                                })}
                                                {...props.baseProps}
                                            />
                                        </div>
                                    </div>
                                )
                            }
                        </ToolkitProvider>
                    </Col>
                </Row>
                {(selectedDatascope !== "") &&
                    <div className=" text-left p-4 mt-4" style={{ backgroundColor: "rgba(255, 255, 255, 0.7)" }}>
                        <h5>{selectedDatascopeDetails?.name}</h5>
                        <Form onSubmit={handleSubmit} ref={form} noValidate validated={validated}>
                            <DatascopeForm edit={true} dbname={dbname} onDbname={setDbname}
                                onDeleteConnection={onDeleteConnection}
                                onTablesMapUpdate={onTablesMapUpdate} onEditTable={onEditTable}
                                AddNewTable={addNewTable} onAddTableRef={onAddTableRef} connections={conns}
                                setAlert={setAlert} nameToConnection={remap}
                                initialTables={initialTables} description={description} onDescription={setDescription}
                            />

                            <Button variant="dymium" size="sm" className="mt-4" type="submit">
                                Apply
                            </Button>
                            <Button variant="dymium" size="sm" className="mt-4 ml-3"
                                onClick={e => {
                                    setShowclone(true)
                                }}>
                                Clone
                            </Button>
                        </Form>
                    </div>
                }

            </div>
        </div>
    )
}