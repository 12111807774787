import * as types from '@dymium/common/Types/Common'
export interface Reference {
    schema: string
    table: string
    column: string
}
export interface DatascopeRecord {
    connection: string
    connectionId?: string
    schema: string
    ghostSchema: string
    table: string
    tableId?: string
    ghostTable: string
    typ: string
    ghostTyp: string
    position: number
    reference: null|Reference
    action: string
    col: string
    ghostCol: string
    semantics: string
    description: string    
    dflt: string
    isnullable: boolean
    possibleActions: string[]
}
export interface TableLine {
    id?: string
    connection: string
    action: string
    name: string //connection name
    ghostName: string
    position: number
    reference: null|Reference
    semantics: string
    description: string
    possibleActions?: string[]
    typ: string
    dflt: string
    isnullable: boolean    
  }
  export interface TableScope {
    id: string
    tableId: string
    connection?: string
    connectionId: string
    schema: string
    table: string
    ghostSchema: string
    ghostTable: string
    description: string
    tablescope?: Array<TableLine>
}
export interface Connection {
    id?: string
    credid?:string
    dbtype: string
    name: string

    address: string
    port: number
    description: string
    usetls: boolean
}
export interface DataScope { 
    name: string
    records: Array<DatascopeRecord>

}
export class ConnectionMap {
    [index: string]: types.ConnectionRecord;
}

export interface TablesMap {
    [index: string]: TableScope
}

export interface Group {
    id: string;
    name: string;
}
export interface DataScopeInfo {
    id: string;
    name: string;
    records?: TableLine[];
    groups?: Group[];
}

export interface Mapping {
    id?: string;
    dymiumgroup: string;
    directorygroup: string;
    comments: string;
    adminaccess: boolean;
}