import * as T from "./typedefs"
import { parsePsql } from "./parser"

export type Result =
    | 'Ok'
    | 'Questionable'
    | 'Incompatible'
    | 'Error'


export function verify(original_type: string, ghost_type: string) : [Result,string[]] {
    let ot = parsePsql(original_type);
    if (ot === null) return ['Error',[]];
    let gt = parsePsql(ghost_type);
    if (gt === null) return ['Error',[]];
    
    let allowed_trans: string[] = trans(gt)
    let Ok: [Result, string[]] = ['Ok', allowed_trans]
    let Questionable: [Result, string[]] = ['Questionable', allowed_trans]
    let Incompatible: [Result, string[]] = ['Incompatible', []]
 
    if (gt.kind === 'text' || ((gt.kind === 'bpchar' || gt.kind === 'varchar') && gt.size === undefined))
	return Ok;
    if (ot.kind === gt.kind) {
	if(['char', 'boolean', 'date', 'bytea', 'json', 'jsonb', 'uuid', 'cidr', 'inet',
	    'macaddr', 'macaddr8', 'point', 'line', 'lseg', 'box', 'path', 'polygon', 'circle',
	    'money', 'tsvector', 'xml', 'int4range', 'int8range', 'numrange', 'tsrange',
	    'tstzrange', 'daterange'].includes(ot.kind)) return Ok;
    }
    switch(ot.kind) {
	// simple types
    case 'char':
	if (['bytea','int','float','numeric','character','varchar',"bpchar"].includes(gt.kind)) return Ok;
	if (['boolean'].includes(gt.kind)) return Questionable;
	return Incompatible;
    case 'boolean':
	if (gt.kind === 'character' || gt.kind === 'varchar' || gt.kind === 'bpchar') {
	    if(typeof gt.size === 'number' && gt.size < 5) return Questionable;
	    return Ok;
	}
	if (['char','bytea','int','float','numeric'].includes(gt.kind)) return Ok;
	return Incompatible;
    case 'date':
	if (gt.kind === 'character' || gt.kind === 'varchar' || gt.kind === 'bpchar') {
	    if(typeof gt.size === 'number' && gt.size < 10) return Incompatible;
	    return Ok;
	}
	if (['bytea', 'timestamp'].includes(gt.kind)) return Ok;
	return Incompatible;
    case 'json':
	if(gt.kind == 'jsonb') return Ok;
	if(gt.kind === 'character' || gt.kind === 'varchar' || gt.kind === 'bpchar') return Questionable;
	return Incompatible;
    case 'jsonb':
	if(gt.kind === 'json') return Ok;
	else if(gt.kind === 'character' || gt.kind === 'varchar' || gt.kind === 'bpchar') return Questionable;
	else return Incompatible;
    case 'uuid':
	if (gt.kind === 'character' || gt.kind === 'varchar' || gt.kind === 'bpchar') {
	    if(typeof gt.size === 'number' && gt.size < 36) return Incompatible;
	    else return Ok;
	};
	return Incompatible;
    case 'cidr':
	if(gt.kind === 'inet') return Ok;
	if (gt.kind === 'character' || gt.kind === 'varchar' || gt.kind === 'bpchar') {
	    if(typeof gt.size === 'number' && gt.size < 43) return Questionable;
	    else return Ok;
	};
	return Incompatible;
    case 'inet':
	if(gt.kind === 'cidr') return Questionable;
	if (gt.kind === 'character' || gt.kind === 'varchar' || gt.kind === 'bpchar') {
	    if(typeof gt.size === 'number' && gt.size < 43) return Questionable;
	    else return Ok;
	};
	return Incompatible;
    case 'macaddr':
	if(gt.kind === 'macaddr8') return Ok;
	if (gt.kind === 'character' || gt.kind === 'varchar' || gt.kind === 'bpchar') {
	    if(typeof gt.size === 'number' && gt.size < 23) return Incompatible;
	    else return Ok;
	};
	return Incompatible;
    case 'macaddr8':
	if(gt.kind === 'macaddr') return Questionable;
	if (gt.kind === 'character' || gt.kind === 'varchar' || gt.kind === 'bpchar') {
	    if(typeof gt.size === 'number' && gt.size < 17) return Incompatible;
	    else return Ok;
	};
	return Incompatible;
    case 'point':
	if (gt.kind === 'character' || gt.kind === 'varchar' || gt.kind === 'bpchar') {
	    if(typeof gt.size === 'number' && gt.size < 33) return Questionable;
	    else return Ok;
	};
	return Incompatible;
    case 'line':
	if (gt.kind === 'character' || gt.kind === 'varchar' || gt.kind === 'bpchar') {
	    if(typeof gt.size === 'number' && gt.size < 96) return Questionable;
	    else return Ok;
	};
	return Incompatible;
    case 'lseg':
	if (gt.kind === 'character' || gt.kind === 'varchar' || gt.kind === 'bpchar') {
	    if(typeof gt.size === 'number' && gt.size < 66) return Questionable;
	    else return Ok;
	};
	return Incompatible;
    case 'box':
	if (gt.kind === 'character' || gt.kind === 'varchar' || gt.kind === 'bpchar') {
	    if(typeof gt.size === 'number' && gt.size < 66) return Questionable;
	    else return Ok;
	};
	return Incompatible;
    case 'path':
	if (gt.kind === 'character' || gt.kind === 'varchar' || gt.kind === 'bpchar') return Questionable;
	return Incompatible;
    case 'polygon':
	if (gt.kind === 'character' || gt.kind === 'varchar' || gt.kind === 'bpchar') return Questionable;
	return Incompatible;
    case 'circle':
	if (gt.kind === 'character' || gt.kind === 'varchar' || gt.kind === 'bpchar') {
	    if(typeof gt.size === 'number' && gt.size < 100) return Questionable;
	    else return Ok;
	};
	return Incompatible;
    case 'money':
	if (gt.kind === 'character' || gt.kind === 'varchar' || gt.kind === 'bpchar') {
	    if(typeof gt.size === 'number' && gt.size < 27) return Questionable;
	    else return Ok;
	};
	return Incompatible;
    case 'tsvector':
	if (gt.kind === 'character' || gt.kind === 'varchar' || gt.kind === 'bpchar') return Questionable;
	return Incompatible;
    case 'xml':
	if (gt.kind === 'character' || gt.kind === 'varchar') return Questionable;
	return Incompatible;
    case 'int4range':
	if (gt.kind === 'character' || gt.kind === 'varchar' || gt.kind === 'bpchar') {
	    if(typeof gt.size === 'number' && gt.size < 24) return Questionable;
	    else return Ok;
	};
	return Incompatible;
    case 'int8range':
	if (gt.kind === 'character' || gt.kind === 'varchar' || gt.kind === 'bpchar') {
	    if(typeof gt.size === 'number' && gt.size < 41) return Questionable;
	    else return Ok;
	};
	return Incompatible;
    case 'numrange':
	if (gt.kind === 'character' || gt.kind === 'varchar' || gt.kind === 'bpchar') return Questionable;
	return Incompatible;	
    case 'tsrange':
	if (gt.kind === 'character' || gt.kind === 'varchar' || gt.kind === 'bpchar') {
	    if(typeof gt.size === 'number' && gt.size < 55) return Questionable;
	    else return Ok;
	};
	if (gt.kind === 'tstzrange') return Ok;
	return Incompatible;	
    case 'tstzrange':
	if (gt.kind === 'character' || gt.kind === 'varchar' || gt.kind === 'bpchar') {
	    if(typeof gt.size === 'number' && gt.size < 67) return Questionable;
	    else return Ok;
	};
	if (gt.kind === 'tsrange') return Questionable;
	return Incompatible;
    case 'daterange':
	if (gt.kind === 'character' || gt.kind === 'varchar' || gt.kind === 'bpchar') {
	    if(typeof gt.size === 'number' && gt.size < 23) return Questionable;
	    else return Ok;
	};
	if (gt.kind === 'tsrange' || gt.kind === 'tstzrange') return Ok;
	return Incompatible;
	// complex types
    case 'int':
	if(gt.kind === 'int') {
	    if(gt.size >= ot.size) return Ok;
	    return Questionable;
	}
	if(['float', 'numeric', 'money'].includes(gt.kind)) return Ok;
	if (gt.kind === 'character' || gt.kind === 'varchar' || gt.kind === 'bpchar') {
	    if(typeof gt.size === 'number') {
		if(ot.size === 2 && gt.size >= 6) return Ok;
		if(ot.size === 4 && gt.size >= 11) return Ok;
		if(ot.size === 8 && gt.size >= 20) return Ok;
		return Questionable;
	    };
	}
	return Incompatible;
    case 'float':
	if(gt.kind === 'float') {
	    if(gt.size >= ot.size) return Ok;
	    return Questionable;
	}
	if(['int', 'float', 'money'].includes(gt.kind)) return Questionable;
	if(gt.kind === 'numeric') {
	    if(ot.size === 4) {
		if(gt.precision >= 9 && gt.scale >= 6) return Ok;
		return Questionable;
	    } else {
		if(gt.precision >= 309 && gt.scale >= 308) return Ok;
		return Questionable;
	    }
	}
	if (gt.kind === 'character' || gt.kind === 'varchar' || gt.kind === 'bpchar') {
	    if(typeof gt.size === 'number') {
		if(ot.size === 2 && gt.size >= 6) return Ok;
		if(ot.size === 4 && gt.size >= 11) return Ok;
		if(ot.size === 8 && gt.size >= 20) return Ok;
		return Questionable;
	    };
	}
	return Incompatible;	
    case 'numeric':
	if(['int', 'float', 'money'].includes(gt.kind)) return Questionable;
	if(gt.kind === 'numeric') {
	    if(gt.scale >= 0) {
		if(gt.scale >= ot.scale && gt.precision - gt.scale >= ot.precision - ot.scale) return Ok;
		return Questionable;
	    } else {
		if(gt.scale <= ot.scale && gt.precision - gt.scale >= ot.precision - ot.scale) return Ok;
		return Questionable;
	    }
	}
	if (gt.kind === 'character' || gt.kind === 'varchar' || gt.kind === 'bpchar') {
	    if(ot.scale >= 0) {
		if(typeof gt.size === 'number' && gt.size >= ot.precision + ot.scale + 2) return Ok
		return Questionable;
	    } else {
		if(typeof gt.size === 'number' && gt.size >= ot.precision - ot.scale + 1) return Ok
		return Questionable;
	    }
	}
	return Incompatible;
    case 'serial':
	if(gt.kind === 'serial' || gt.kind === 'int') {
	    if(ot.size <= gt.size) return Ok;
	    return Questionable;
	}
	return Incompatible;
    case 'character':
    case 'varchar':
    case 'bpchar':
	if (gt.kind === 'character' || gt.kind === 'varchar' || gt.kind === 'bpchar') {
	    if (ot.size === undefined) return Questionable;
	    if (typeof ot.size === 'number' && typeof gt.size === 'number') {
		if (ot.size <= gt.size) return Ok;
		return Questionable;
	    }
	}
	if (gt.kind === 'char') {
	    if (ot.size === undefined) return Questionable;
	    if (typeof ot.size === 'number') {
		if (ot.size === 1) return Ok;
		return Questionable;
	    }
	}
	return Questionable;
    case 'text':
	return Questionable;
    case 'time':
	if (gt.kind === 'time' || gt.kind === 'timestamp') {
	    if (gt.precision >= ot.precision && (!ot.withTimezone || gt.withTimezone)) return Ok;
	    return Questionable;
	}
	if (gt.kind === 'character' || gt.kind === 'varchar' || gt.kind === 'bpchar') {
	    if (ot.withTimezone) {
		if(typeof gt.size === 'number' && gt.size < 8 + ot.precision + 1 + 6) return Questionable;
		return Ok;
	    } else {
		if(typeof gt.size === 'number' && gt.size < 8 + ot.precision + 1) return Questionable;
		return Ok;
	    }
	};
	return Incompatible;
    case 'timestamp':
	if (gt.kind === 'timestamp') {
	    if (gt.precision >= ot.precision && (!ot.withTimezone || gt.withTimezone)) return Ok;
	    return Questionable;
	}
	if (['date', 'time'].includes(gt.kind)) return Questionable;
	if (gt.kind === 'character' || gt.kind === 'varchar' || gt.kind === 'bpchar') {
	    if (ot.withTimezone) {
		if(typeof gt.size === 'number' && gt.size < 19 + (ot.precision + 1) + 6) return Questionable;
		return Ok;
	    } else {
		if(typeof gt.size === 'number' && gt.size < 19 + (ot.precision + 1)) return Questionable;
		return Ok;
	    }
	};
	return Incompatible;
    case 'interval':
	if(gt.kind === 'interval') {
	    if(typeof gt.fields === 'undefined') return Ok
	    if(typeof ot.fields === 'undefined') return Questionable
	    switch(ot.fields) {
	    case 'YEAR':
		switch(gt.fields) {
		case 'YEAR': return Ok
		case 'YEAR TO MONTH': return Ok
		default: return Incompatible
		}
	    case 'MONTH':
		switch(gt.fields) {
		case 'MONTH': return Ok
		case 'YEAR TO MONTH': return Ok
		default: return Incompatible
		}
	    case 'DAY':
		switch(gt.fields) {
		case 'DAY': return Ok
		case 'DAY TO HOUR': return Ok
		case 'DAY TO MINUTE': return Ok
		case 'DAY TO SECOND': return Ok
		default: return Incompatible
		}
	    case 'HOUR':
		switch(gt.fields) {
		case 'HOUR': return Ok
		case 'HOUR TO MINUTE': return Ok
		case 'HOUR TO SECOND': return Ok
		case 'DAY TO HOUR': return Ok
		case 'DAY TO MINUTE': return Ok
		case 'DAY TO SECOND': return Ok
		default: return Incompatible
		}
	    case 'MINUTE':
		switch(gt.fields) {
		case 'MINUTE': return Ok
		case 'MINUTE TO SECOND': return Ok
		case 'HOUR TO MINUTE': return Ok
		case 'HOUR TO SECOND': return Ok
		case 'DAY TO MINUTE': return Ok
		case 'DAY TO SECOND': return Ok
		default: return Incompatible
		}
	    case 'SECOND':
		switch(gt.fields) {
		case 'SECOND':
		case 'MINUTE TO SECOND':
		case 'HOUR TO SECOND':
		case 'DAY TO SECOND':
		    if(ot.precision <= gt.precision) return Ok
		    else return Questionable
		default: return Incompatible
		}
	    case 'YEAR TO MONTH':
		switch(gt.fields) {
		case 'YEAR TO MONTH': return Ok
		case 'YEAR': return Questionable
		default: return Incompatible
		}
	    case 'DAY TO HOUR':
		switch(gt.fields) {
		case 'DAY TO HOUR':
		case 'DAY TO MINUTE':
		case 'DAY TO SECOND':
		    return Ok
		case 'DAY': return Questionable
		default: return Incompatible
		}
	    case 'DAY TO MINUTE':
		switch(gt.fields) {
		case 'DAY TO MINUTE':
		case 'DAY TO SECOND':
		    return Ok
		case 'DAY TO HOUR':
		case 'DAY':
		    return Questionable
		default: return Incompatible
		}
	    case 'DAY TO SECOND':
		switch(gt.fields) {
		case 'DAY TO SECOND':
		    if(ot.precision <= gt.precision) return Ok
		    else return Questionable		    
		case 'DAY TO MINUTE':
		case 'DAY TO HOUR':
		case 'DAY':
		    return Questionable
		default: return Incompatible
		}
	    case 'HOUR TO MINUTE':
		switch(gt.fields) {
		case 'HOUR TO MINUTE':
		case 'HOUR TO SECOND':
		case 'DAY TO MINUTE':
		case 'DAY TO SECOND':
		    return Ok
		case 'HOUR':
		case 'DAY TO HOUR':
		    return Questionable
		default: return Incompatible
		}
	    case 'HOUR TO SECOND':
		switch(gt.fields) {
		case 'HOUR TO SECOND':
		case 'DAY TO SECOND':
		    if(ot.precision <= gt.precision) return Ok
		    else return Questionable
		case 'DAY TO MINUTE':
		case 'DAY TO HOUR':
		case 'HOUR':
		    return Questionable
		default: return Incompatible
		}
	    case 'MINUTE TO SECOND':
		switch(gt.fields) {
		case 'MINUTE TO SECOND':
		case 'HOUR TO SECOND':
		case 'DAY TO SECOND':
		    if(ot.precision <= gt.precision) return Ok
		    else return Questionable
		case 'DAY TO MINUTE':
		case 'MINUTE':
		    return Questionable
		default: return Incompatible
		}
	    }
	}
	if(gt.kind === 'character' || gt.kind === 'varchar' || gt.kind === 'bpchar') {
	    if(typeof gt.size === 'number') {
		switch(ot.fields) {
		case 'YEAR':
		    if(gt.size >= 17) return Ok
		    else return Questionable
		case 'MONTH':
		    if(gt.size >= 8) return Ok
		    else return Questionable
		case 'DAY':
		    if(gt.size >= 16) return Ok
		    else return Questionable
		case 'HOUR':
		    if(gt.size >= 9) return Ok
		    else return Questionable
		case 'MINUTE':
		    if(gt.size >= 6) return Ok
		    else return Questionable
		case 'SECOND':
		    if(gt.size >= 4 + ot.precision) return Ok
		    else return Questionable
		case 'YEAR TO MONTH':
		    if(gt.size >= 24) return Ok
		    else return Questionable
		case 'DAY TO HOUR':
		    if(gt.size >= 24) return Ok
		    else return Questionable
		case 'DAY TO MINUTE':
		    if(gt.size >= 27) return Ok
		    else return Questionable
		case 'DAY TO SECOND':
		    if(gt.size >= 33 + ot.precision) return Ok
		    else return Questionable
		case 'HOUR TO MINUTE':
		    if(gt.size >= 14) return Ok
		    else return Questionable
		case 'HOUR TO SECOND':
		    if(gt.size >= 18 + ot.precision) return Ok
		    else return Questionable
		case 'MINUTE TO SECOND':
		    if(gt.size >= 9 + ot.precision) return Ok
		    else return Questionable
		}
	    }
	    return Ok
	}
	return Incompatible
    case 'bits':
	if(gt.kind === 'bits') return Questionable; // TBD
    case 'varbits':
	if(gt.kind === 'bits') return Questionable; // TBD
    };
    return Incompatible;
}

function trans(t: T.PostgreSQLDataType) : string[] {

    let obfuscatable : string[] = ['block', 'redact', 'obfuscate', 'allow']
    let allowable : string[] = ['block', 'redact', 'allow']

    switch(t.kind) {
    case  'int': return allowable
    case  'float': return allowable
    case  'numeric': return allowable
    case  'serial': return allowable
    case  'text': return obfuscatable
    case  'character':
	if(typeof t.size === 'number' && t.size <= 4) return allowable
	return obfuscatable
    case  'bpchar':
	if(typeof t.size === 'number' && t.size <= 4) return allowable
	return obfuscatable
    case  'varchar':
	if(typeof t.size === 'number' && t.size <= 4) return allowable
	return obfuscatable
    case  'time': return allowable
    case  'timestamp': return allowable
    case  'interval': return allowable
    case  'array': return trans(t.elementType)
    case  'bits': return allowable
    case  'varbits': return allowable
    // simple types
    case  'char': return allowable
    case  'boolean': return allowable
    case  'date': return allowable
    case  'bytea': return allowable
    case  'json': return allowable
    case  'jsonb': return allowable
    case  'uuid': return obfuscatable
    case  'cidr': return allowable
    case  'inet': return allowable
    case  'macaddr': return allowable
    case  'macaddr8': return allowable
    case  'point': return allowable
    case  'line': return allowable
    case  'lseg': return allowable
    case  'box': return allowable
    case  'path': return allowable
    case  'polygon': return allowable
    case  'circle': return allowable
    case  'money': return allowable
    case  'tsvector': return allowable
    case  'xml': return allowable
    case  'int4range': return allowable
    case  'int8range': return allowable
    case  'numrange': return allowable
    case  'tsrange': return allowable
    case  'tstzrange': return allowable
    case  'daterange': return allowable
    }
}
