import React, { useEffect, useCallback, useState, useRef } from 'react';
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import InputGroup from 'react-bootstrap/InputGroup'
import Card from 'react-bootstrap/Card'
import Offcanvas from '@dymium/common/Components/Offcanvas'
import { Typeahead } from 'react-bootstrap-typeahead';
import Modal from 'react-bootstrap/Modal'
import Alert from 'react-bootstrap/Alert'
import BootstrapTable from 'react-bootstrap-table-next';
import { Overlay, OverlayTrigger } from 'react-bootstrap';
import cloneDeep from 'lodash/cloneDeep';
import Spinner from '@dymium/common/Components/Spinner'
import * as com from '../Common'
import * as types from '@dymium/common/Types/Internal'
import * as ctypes from '@dymium/common/Types/Common'
import * as dba from '@dymium/common/Types/DbAnalyzer'
import * as http from '@dymium/common/Api/Http'
// verify, Result
import * as tst from 'ghost-verify'

import 'bootstrap/dist/css/bootstrap.min.css'

import { PrefillUnclassified, Confidential, Secret, TopSecret } from "./Detectors"
export const DefaultPrefills = {
    unclassified: PrefillUnclassified,
    confidential: Confidential,
    secret: Secret,
    topsecret: TopSecret
}


const Actions = [
    "Allow",
    "Block",
    "Redact",
    "Obfuscate",
    "Smart Redact"
]

interface Rule {
    regexp: string
    detection: string
    action: string
}

type SubRule = {
    regexp: string,
    semantics: string,
    detection: string,
    action: string
}
type RuleSet = {
    name: string,
    rules: SubRule[]
}

type Formatter = (cell: any, row: any, rowIndex: any, formatExtraData: any) => JSX.Element
type TableColumn = {
    dataField: string;
    text: string;
    hidden?: boolean;
    editable?: boolean;
    classes?: string;
    formatter?: any;
    editor?: any;
}
type PiiPair = {
    id: string;
    label: string;
}
let Id2Label = (x: string) => {
    let opts: ctypes.DataHandling[] = ["allow", "block", "obfuscate", "redact"]
    for (let i = 0; i < opts.length; i++) {
        if (x == opts[i])
            return ctypes.humanReadableDataHandling(opts[i])
    }
    return ""
}
export interface AddTableProps {
    table: types.TableScope,
    connectionId: string,
    id: string,
    tableId: string,
    currentConnectionType: string,
    onCheckCollision: (ar: types.TableScope) => boolean,
    onAddTable: (ar: types.TableScope) => void,
    onAlert: (ar: JSX.Element) => void,
    onHide: () => void
}
// create type for enum, with values None, Column, Type, Comment
enum OverlayMode {
    None = 0,
    Column = 1,
    Type = 2,
    Comment = 3
}
interface OverlayState {
    show: boolean; // Determines if the overlay is visible
    typ: OverlayMode;
    target: HTMLElement | null; // The clicked element to position the overlay
    top: number; // Top coordinate for overlay positioning
    left: number; // Left coordinate for overlay positioning
    rowIndex: number | null; // Index of the row in the table structure
    value: string; // Editable value in the overlay
    originalValue: string; // Original value before editing
    allowedActions: string[];
}
const AddTable: React.FC<AddTableProps> = (props) => {
    const [validated, setValidated] = useState(false)
    const [database, setDatabase] = useState({})
    const [tables, setTables] = useState<dba.Column[]>([])
    const [schema, setSchema] = useState("")
    const [table, setTable] = useState("")
    const [ghostSchema, setGhostSchema] = useState("")
    const [ghostTable, setGhostTable] = useState("")
    const [dummy, setDummy] = useState(true)
    const [level, setLevel] = useState("")
    const [spinner, setSpinner] = useState(false)
    const [Prefills, setPrefills] = useState<Object>(DefaultPrefills)
    const [policy, setPolicy] = useState(new ctypes.DataPolicy())
    const [PIIs, setPIIs] = useState<PiiPair[]>([])
    const [tabledef, setTabledef] = useState<TableColumn[]>([])
    const [typecastResult, setTypecastResult] = useState<tst.Result>("Ok")
    const [showModal, setShowModal] = useState(false)

    let emptyarray: any[] = []
    const [tablestructure, setTableStructure] = useState(emptyarray)
    const [description, setDescription] = useState("")
    const [nameConflict, setNameConflict] = useState(false)
    const textareaRef = useRef<HTMLTextAreaElement>(null); // Ref for the textarea
    const overlayRef = useRef<HTMLDivElement>(null);


    const [overlayState, setOverlayState] = useState<OverlayState>({
        show: false,
        target: null,
        typ: OverlayMode.None,
        top: 0,
        left: 0,
        rowIndex: 0,
        value: '',
        originalValue: '',
        allowedActions: []
    });

    let form = useRef<HTMLFormElement>(null)

    //add useEffect for opening component with a callback for closing the component

    useEffect(() => {
        // Add callback for hiding scrollbar
        const rootElement = document.getElementById("root");
        if (rootElement) {
            rootElement.style.overflow = "hidden"; // Hide the scrollbar
        }

        return () => {
            // Restore the scrollbar when the component is unmounted
            if (rootElement) {
                rootElement.style.overflow = ""; // Reset to default;
            }
        };
    }, []);

    useEffect(() => {
        if (overlayState.show && textareaRef.current) {
            textareaRef.current.focus();
        }
    }, [overlayState.show]);
    const handleSaveColumn = useCallback(() => {
        if (overlayState.rowIndex !== null) {
            // Update the table structure with the new value
            const updatedStructure = cloneDeep(tablestructure);

            updatedStructure[overlayState.rowIndex].ghostName = overlayState.value;
            setTableStructure(tablestructure => updatedStructure);

            // Hide the overlay
            setOverlayState({ show: false, target: null, typ: OverlayMode.None, top: overlayState.top, left: overlayState.left, rowIndex: 0, value: "", originalValue: "", allowedActions: [] });
        }
    }, [tablestructure, setTableStructure, overlayState]);
    const handleSaveType = useCallback(() => {
        if (overlayState.rowIndex !== null) {
            // Update the table structure with the new value
            const updatedStructure = cloneDeep(tablestructure);

            updatedStructure[overlayState.rowIndex].ghostTyp = overlayState.value;
            updatedStructure[overlayState.rowIndex].possibleActions = overlayState.allowedActions;
            if (!updatedStructure[overlayState.rowIndex].possibleActions.includes(updatedStructure[overlayState.rowIndex].action)) {
                updatedStructure[overlayState.rowIndex].action = ""
            }
            setTableStructure(updatedStructure);

            // Hide the overlay
            setOverlayState({ show: false, target: null, typ: OverlayMode.None, top: overlayState.top, left: overlayState.left, rowIndex: 0, value: "", originalValue: "", allowedActions: [] });
        }
    }, [tablestructure, setTableStructure, overlayState]);

    const handleSave = useCallback(() => {
        if (overlayState.rowIndex !== null) {

            setTableStructure(prevStructure => {
                const updatedStructure = cloneDeep(prevStructure);
                updatedStructure[overlayState.rowIndex].description = overlayState.value;
                setTabledef(t => cloneDeep(t))
                return updatedStructure;
            });

            // Hide the overlay
            setOverlayState(overlayState => {
                return {
                    show: false,
                    target: null,
                    typ: OverlayMode.None,
                    top: overlayState.top,
                    left: overlayState.left,
                    rowIndex: 0,
                    value: "",
                    originalValue: "",
                    allowedActions: []
                }
            });
        }

    }, [tablestructure, overlayState, setTableStructure, tabledef, setTabledef]);

    const handleCancel = () => {
        setOverlayState({ show: false, target: null, typ: OverlayMode.None, top: overlayState.top, left: overlayState.left, rowIndex: 0, value: "", originalValue: "", allowedActions: [] });
    };

    const handleClick = useCallback(
        (e: React.MouseEvent<HTMLElement>, rowIndex: number) => {
            const targetRect = e.currentTarget.getBoundingClientRect();

            // If the container is relatively positioned
            const container = form.current;
            const containerRect = container?.getBoundingClientRect() || { top: 0, left: 0 };

            setOverlayState(overlayState => {
                return {
                    show: true,
                    target: e.currentTarget,
                    typ: OverlayMode.Comment,
                    top: targetRect.top - containerRect.top + container!.scrollTop,
                    rowIndex,
                    left: targetRect.left - containerRect.left + container!.scrollLeft,
                    value: tablestructure[rowIndex].description,
                    originalValue: tablestructure[rowIndex].description,
                    allowedActions: tablestructure[rowIndex].actions
                }
            });
        },
        [tablestructure, setTableStructure, overlayState]
    );
    const handleClickRef = useRef(handleClick);
    useEffect(() => {
        handleClickRef.current = handleClick;
    }, [handleClick]);

    const handleClickColumn = useCallback(
        (e: React.MouseEvent<HTMLElement>, rowIndex: number) => {
            const targetRect = e.currentTarget.getBoundingClientRect();

            // If the container is relatively positioned
            const container = form.current;
            const containerRect = container?.getBoundingClientRect() || { top: 0, left: 0 };
            setNameConflict(false)
            setOverlayState({
                show: true,
                target: e.currentTarget,
                typ: OverlayMode.Column,
                top: targetRect.top - containerRect.top + container!.scrollTop,
                rowIndex,
                left: targetRect.left - containerRect.left + container!.scrollLeft,
                value: tablestructure[rowIndex].ghostName,
                originalValue: tablestructure[rowIndex].name,
                allowedActions: tablestructure[rowIndex].actions
            });
        },
        [tablestructure, setTableStructure, overlayState]
    );
    const handleClickColumnRef = useRef(handleClickColumn);
    useEffect(() => {
        handleClickColumnRef.current = handleClickColumn;
    }, [handleClickColumn]);

    const handleClickType = useCallback(
        (e: React.MouseEvent<HTMLElement>, rowIndex: number) => {
            const targetRect = e.currentTarget.getBoundingClientRect();
            setTypecastResult("Ok")

            // If the container is relatively positioned
            const container = form.current;
            const containerRect = container?.getBoundingClientRect() || { top: 0, left: 0 };

            setOverlayState({
                show: true,
                target: e.currentTarget,
                typ: OverlayMode.Type,
                top: targetRect.top - containerRect.top + container!.scrollTop,
                rowIndex,
                left: targetRect.left - containerRect.left + container!.scrollLeft,
                value: tablestructure[rowIndex].ghostTyp,
                originalValue: tablestructure[rowIndex].typ,
                allowedActions: tablestructure[rowIndex].actions
            });
        },
        [tablestructure, setTableStructure, overlayState]
    );
    const handleClickTypeRef = useRef(handleClickType);
    useEffect(() => {
        handleClickTypeRef.current = handleClickType;
    }, [handleClickType]);

    let handleSubmit = event => {
        if (form.current == null) {
            return false
        }

        if (form.current.reportValidity() === false) {
            event.preventDefault();
            setValidated(true)
            setDummy(!dummy)
            return false
        }

        event.preventDefault();
        setValidated(false)
        event.stopPropagation();
        let t = {
            schema, table, ghostSchema, ghostTable, description: description,
            id: props.id, tableId: props.tableId, connectionId: props.connectionId, tablescope: tablestructure
        }
        if (props.onCheckCollision(t)) {
            setNameConflict(true)
            setShowModal(true)
            return
        }
        props.onAddTable(t)
        setTableStructure(emptyarray)

        setSchema("")
        setTable("")
        setGhostSchema("")
        setGhostTable("")
        return false
    }

    let createLevel = (policy: ctypes.DataPolicy, index: number) => {
        let r: RuleSet = { name: policy.actions[index].role, rules: [] }

        return r
    }
    let createLevels = (policy: ctypes.DataPolicy) => {
        let Prefills = {}
        for (let i = 0; i < policy.actions.length; i++) {
            let role = policy.actions[i].role
            Prefills[role] = createLevel(policy, i)
            for (let j = 0; j < policy.piisuggestions.length; j++) {

                let rule: SubRule = {
                    detection: policy.piisuggestions[j].detector.name,
                    semantics: policy.piisuggestions[j].detector.id!,
                    regexp: policy.piisuggestions[j].detector.data,
                    action: policy.piisuggestions[j].actions[i].handling
                }
                Prefills[role].rules.push(rule)
            }
            let defaultrule: SubRule = {
                regexp: "",
                semantics: "",
                detection: "N/A",
                action: "Allow"
            }
            Prefills[role].rules.push(defaultrule)
        }
        setPrefills(Prefills)
    }
    let getConnections = () => {
        if (props.table.connection !== undefined && props.table.connection !== "") {
            setSchema(props.table.schema)
            setTable(props.table.table)
            setGhostSchema(props.table.ghostSchema)
            setGhostTable(props.table.ghostTable)
            setDescription(props.table.description)
            setTableStructure(tablestructure => cloneDeep(props.table.tablescope))
        } else {
            let body = JSON.stringify({
                ConnectionId: props.connectionId
            })
            setSpinner(true)
            http.sendToServer("POST", "/api/queryconnection",
                null, body,
                resp => {
                    resp.json().then(js => {
                        if (js.status !== "OK") {
                            props.onAlert(<Alert variant="danger" onClose={() => props.onAlert(<></>)} dismissible>
                                {js.errormessage}
                            </Alert>)
                            props.onHide()
                            setSpinner(false)
                            return
                        }

                        setDatabase(js.response.dbInfo)
                        if (props.table.schema !== undefined && props.table.table !== undefined) {
                            setSchema(schema => {
                                setTable(props.table.table)
                                return props.table.schema
                            })
                            setGhostSchema(ghostSchema => {
                                setTable(props.table.table)
                                setGhostTable(props.table.ghostTable)
                                return props.table.schema
                            })
                        }
                        setSpinner(false)


                    }).catch((error) => {
                        console.log("exception: ", error.message)
                        setSpinner(false)
                    })
                },
                resp => {
                    console.log("on error")
                    setSpinner(false)
                    resp != null && resp.text().then(t =>
                        props.onAlert(<Alert variant="danger" onClose={() => props.onAlert(<></>)} dismissible>Query Connection failed: {t}</Alert>
                        ))
                },
                error => {
                    console.log("on exception: ", error.message)
                    setSpinner(false)

                })
        }
    }
    let getPolicies = () => {

        //setSpinner(true)
        http.sendToServer("GET", "/api/getpolicies",
            null, "",
            resp => {
                resp.json().then(js => {
                    //setSpinner(false)                    
                    if (js.error === undefined) {
                        let po = ctypes.DataPolicy.fromJson(js)
                        setPolicy(po)
                        createLevels(po)
                        let newPIIs: PiiPair[] = [{ id: "", label: "N/A" }].concat(po.piisuggestions.map(x => {
                            let id = ""
                            if (x.detector.id != null)
                                id = x.detector.id
                            return { id, label: x.detector.name }
                        }))
                        setPIIs(PIIs => newPIIs)
                    } else {
                        props.onAlert(<Alert variant="danger" onClose={() => props.onAlert(<></>)} dismissible>
                            Error: {js.error}
                            <br />
                            Check if you defined any rules and access levels!
                        </Alert>)
                        setPrefills(DefaultPrefills)
                    }

                }).catch((error) => {
                    props.onAlert(<Alert variant="danger" onClose={() => props.onAlert(<></>)} dismissible>
                        Exception: {error.message}
                    </Alert>)
                    console.log(error.stack)
                })
            },
            resp => {

            },
            error => {

            })
    }
    useEffect(() => {
        getPolicies()


    }, [])
    useEffect(() => {
        if (tablestructure != null && tablestructure.length !== 0)
            setTableStructure(tables)

    }, [tables])

    let getSemanticsFromId = (semantics) => {
        if (semantics === '' || semantics == null)
            return "N/A"
        if (semantics === "UNSUPPORTED")
            return "UNSUPPORTED"

        for (let i = 0; i < policy.piisuggestions.length; i++) {
            if (policy.piisuggestions[i].detector.id === semantics) {
                return policy.piisuggestions[i].detector.name
            }
        }
        return "N/A"
    }

    let getOptions = () => {
        if (database["schemas"] === undefined) {
            return []
        }
        let schemas = database["schemas"].filter(x => !x.isSystem).map(x => {
            return x.name
        })
        return schemas
    }
    let selectSchema = (schema: any) => {
        if (schema[0] === undefined) {
            setSchema("")
            setGhostSchema("")
        } else {
            setSchema(schema[0].toString())
            setGhostSchema(schema[0].toString())
        }
        setTable("")
        setGhostTable("")
    }
    let _selectTable = useCallback((table: any) => {

        if (table.length === 0) {
            setTable("")
            setGhostTable("")
            return
        }
        setTable(table[0].toString())
        setGhostTable(table[0].toString())
    }, [setTable, setGhostTable])

    let selectTable = (table: any[]) => {
        if (table.length === 0) {
            setTables(tables => [])
            return
        }
        let b = {
            ConnectionId: props.connectionId,
            Schema: schema,
            Table: table[0]
        }
        if (schema === "" && props.table.schema !== "") {
            b.Schema = props.table.schema
        }
        let body = JSON.stringify(b)
        setSpinner(true)
        http.sendToServer("POST", "/api/querytable",
            null, body,
            resp => {
                resp.json().then(js => {
                    if (js.status !== "OK") {
                        window.document.dispatchEvent(new Event('reauthenticate'));
                        props.onAlert(<Alert variant="danger" onClose={() => props.onAlert(<></>)} dismissible>
                            {js.errormessage}
                        </Alert>)
                        props.onHide()
                        setSpinner(false)
                        return
                    }

                    setTables(tables => {
                        setTable(table => {

                            return js.response.tblInfo.tblName
                        });
                        setGhostTable(ghostTable => {
                            return js.response.tblInfo.tblName
                        }
                        )
                        let cols = js.response.tblInfo.columns

                        let ret = cols.map(r => {
                            return { ...r, ghostName: r.name, ghostTyp: r.typ, ghostDescription: "" }
                        });
                        setTableStructure(ret)
                        return ret
                    })

                    setSpinner(false)

                }).catch((error) => {
                    console.log("exception: ", error.message)
                    setSpinner(false)
                })
            },
            resp => {
                console.log("on error")
                setSpinner(false)
                resp != null && resp.text().then(t =>
                    props.onAlert(<Alert variant="danger" onClose={() => props.onAlert(<></>)} dismissible>Query Connection failed: {t}</Alert>
                    ))
            },
            error => {
                console.log("on exception: ", error.message)
                setSpinner(false)

            })
    }


    useEffect(() => {
        if (tablestructure == null || tablestructure.length == 0)
            return
        if (PIIs.length == 0)
            return


        let schemacolumns: TableColumn[] = [
            {
                dataField: 'position',
                text: 'position',
                editable: false,
                hidden: true,
            },
            {
                dataField: 'ghostName',
                text: 'Column',
                editable: false,
                classes: 'overflow-x-scroll',
                formatter: (cell: any, row: any, rowIndex: number) => (
                    <div
                        style={{

                            whiteSpace: 'nowrap',
                            fontStyle: (row.name === row.ghostName) ? 'normal' : 'italic'
                        }}
                        className="underscore"
                        title={(row.name != row.ghostName) ? (row.name + "=>" + row.ghostName) : "Click to map"}
                        onClick={(e) => handleClickColumnRef.current(e, rowIndex)}
                    >
                        {row.ghostName || "..."}
                    </div>
                ),
            },
            {
                dataField: 'description',
                text: 'Comment',
                editable: false,

                formatter: (cell: any, row: any, rowIndex: number) => (
                    <div
                        style={{
                            textOverflow: 'ellipsis',
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                            textAlign: 'center',
                        }}
                        className="underscore"
                        title={((row.description === "" || row.description == undefined) ? "Click to edit" : row.description)}
                        onClick={(e) => handleClickRef.current(e, rowIndex)}
                    >
                        {row.description || "..."}
                    </div>
                ),
            },
            {
                dataField: 'ghostTyp',
                text: 'Type:',
                classes: 'overflow-x-scroll',
                formatter: (cell: any, row: any, rowIndex: number) => {
                    let castable = row.possibleActions.includes("allow")
                    return <div
                        style={{


                            whiteSpace: 'nowrap',

                            fontStyle: (row.typ === row.ghostTyp) ? 'normal' : 'italic'
                        }}
                        className={castable ? "underscore" : ""
                        }
                        title={castable ?
                            (((row.typ != row.ghostTyp) ? (row.typ + "=>" + row.ghostTyp) : "Click to cast")) :
                            "Can't cast this field"}
                        onClick={castable ? (e) => handleClickTypeRef.current(e, rowIndex) : (e => { })}
                    >
                        {row.ghostTyp || "..."}
                    </div>
                }
            },
            {
                dataField: 'semantics',
                editable: false,
                text: 'PII:',
                formatter: (cell, row, rowIndex, formatExtraData) => {

                    let pattern = "^(" + PIIs.map(x => x.label).join("|") + ")$"
                    let def = row.semantics !== undefined && row.semantics !== "" ? [{ id: row.semantics, label: getSemanticsFromId(row.semantics) }] : [{ id: "", label: "N/A" }]
                    return <Typeahead
                        clearButton={row.semantics !== "UNSUPPORTED"}
                        disabled={row.semantics === "UNSUPPORTED"}
                        id={"semantics" + rowIndex}
                        inputProps={{ required: true, pattern, id: "semantics" + rowIndex }}
                        key={"semantics" + rowIndex + validated}
                        onChange={selectPII(rowIndex)} size="sm"
                        options={PIIs}
                        defaultSelected={def}
                        placeholder="Data type..."
                    />
                }
            },
            {
                dataField: 'action',
                text: 'Action:',
                editable: false,
                formatter: (cell, row, rowIndex, formatExtraData) => {
                    let possibleActions = row.possibleActions
                    if (possibleActions == undefined)
                        possibleActions = []
                    let possible: string[] = cloneDeep(possibleActions)
                    // possible.push("allow")
                    let pattern = "^(" + possible.map(x => Id2Label(x)).join("|") + ")$"

                    return <Typeahead
                        id={"action" + rowIndex}
                        inputProps={{
                            required: true,
                            pattern, id: "action" + rowIndex
                        }}
                        key={"action" + rowIndex + validated}
                        onChange={selectAction(rowIndex)} size="sm"
                        options={possible.map(x => {
                            return { id: x, label: Id2Label(x) }
                        })
                        }
                        defaultSelected={row.action !== undefined && row.action !== "" ? [{ id: row.action, label: Id2Label(row.action) }] : []}
                        clearButton
                        placeholder="Access..."
                    />
                }
            },

        ]
  
        setTabledef(tabledef => cloneDeep(schemacolumns))
    }, [table, tablestructure])


    useEffect(() => {
        if (PIIs.length !== 0)
            getConnections()
    }, [PIIs])

    useEffect(() => {
        if (props.table.connection === undefined || props.table.connection === "") {
            initTableSchema()
        }
        if (table !== "" /*&& tables.length === 0*/ && (props.table.tablescope === undefined || props.table.tablescope.length === 0)) {
            selectTable([table])
        }
    }, [table])

    //console.log(">>>", schema, PIIs.length, tablestructure.length, tabledef.length)
    let getTables = () => {
        if (database["schemas"] === undefined)
            return []
        let schemas = database["schemas"]
        let tables: any[] = []
        schemas.map(x => {
            if (x.name == schema) {
                tables = x.tables
            }
        })
        return tables.filter(x => !x.isSystem).map(x => {
            return x.name
        })
    }
    let selectPII = (rowIndex) => {
        return event => {
            setTableStructure(tablestructure => {
                let t = cloneDeep(tablestructure)
                if (event.length === 0 || event[0].id == undefined) {
                    t[rowIndex].semantics = ""
                } else {
                    t[rowIndex].semantics = event[0].id
                }
                return t
            })
            setTabledef(tabledef => cloneDeep(tabledef))
        }
    }

    let selectAction = (rowIndex) => {
        return event => {
            setTableStructure(tablestructure => {
                let t = cloneDeep(tablestructure)
                if (event.length === 0 || event[0].id == undefined) {
                    t[rowIndex].action = ""
                } else {
                    t[rowIndex].action = event[0].id
                }
                return t
            })
            setTabledef(tabledef => cloneDeep(tabledef))
        }
    }

    let showTableSchema = () => {

        if (tables == null)
            return []

        let retval = tables.map(x => {

            return {
                position: x.position, name: x.name, typ: x.typ, semantics: x.semantics != null ? x.semantics : "",
                reference: x.reference, action: "", dflt: x["default"], isnullable: x.isnullable, possibleActions: x.possibleActions // TODO change this
            }
        })

        return retval
    }
    let initTableSchema = () => {
        let s = showTableSchema()
        setTableStructure(s)
    }
    let onPrefill = (e) => {
        setLevel(e.target.value)
    }
    let prefills = () => {
        let ret: any[] = []

        Object.keys(Prefills).forEach(key => {
            ret.push(<option data-testid={key} key={key} value={key} >{Prefills[key].name}</option>)
        }
        )
        return ret
    }
    let ActionByName = (predo, semantics: string) => {
        let action = "N/A"
        for (let i = 0; i < predo.rules.length; i++) {

            if (semantics === predo.rules[i].semantics) {

                return [predo.rules[i].action.toLowerCase(), predo.rules[i].id]
            }
        }
        return []
    }
    let sessionGetTablestructure = () => {
        return tablestructure
    }
    let applyPrefill = () => {
        if (level === "")
            return
        let newtablestructure = cloneDeep(sessionGetTablestructure())

        let predo = Prefills[level]

        for (let i = 0; i < newtablestructure.length; i++) {
            let table = newtablestructure[i]

            let [action, semantics] = ActionByName(predo, newtablestructure[i].semantics)
            let possible: string[] = cloneDeep(table.possibleActions)
            //possible.push("allow")

            if (possible.includes(action))
                newtablestructure[i].action = action
            else
                newtablestructure[i].action = possible[possible.length - 1]

        }

        setTableStructure(tablestructure => newtablestructure)
    }
    let correctname = ["MariaDB", "MySQL"].includes(props.currentConnectionType) ? "Database" : "Schema"
    return <div>

        <Form onSubmit={handleSubmit} ref={form} noValidate validated={validated}>

            <Row>
                <Col className="mr-0 pr-0">
                    <Form.Group className="mb-3" controlId="schemaname">
                        <Form.Label>{correctname} Name:</Form.Label>
                        <Typeahead id="schemaname" inputProps={{ id: "schemaname" }}
                            onChange={selectSchema} size="sm"
                            defaultSelected={props.table.ghostSchema != undefined ? [props.table.ghostSchema] : []}
                            options={getOptions()}
                            clearButton
                            data-testid="schemaname"
                            placeholder={`Choose ${correctname}...`}
                            disabled={props.table.connection !== undefined && props.table.connection !== "" || (props.table.table !== "")}
                        />

                        <Form.Control.Feedback >Looks good!</Form.Control.Feedback>
                        <Form.Control.Feedback type="invalid" >
                            Client side name for Dymium database
                        </Form.Control.Feedback>
                    </Form.Group>
                </Col>

                <Col xs="auto" className="ml-0 pl-0"><Spinner show={spinner} style={{ marginTop: '26px', width: '28px' }}></Spinner></Col>

                <Col>
                    {schema !== "" && schema != undefined && table !== "" && table != undefined && tablestructure != null && tablestructure.length > 0 &&
                        <Form.Group className="mb-3"  >
                            <Form.Label >Select Access Level:</Form.Label>
                            <InputGroup>
                                <Form.Control required={false}
                                    id="securityselect" as="select" size="sm" role="combobox" data-testid="seclevel"
                                    onChange={onPrefill}
                                >
                                    <option key="xx" value="">...</option>

                                    {prefills()}
                                </Form.Control>
                                <Button id="fillbutton" data-testid="fill-security" onClick={applyPrefill} variant="dymium" disabled={level === ""} className="mr-1" style={{ marginTop: '0.0em' }} size="sm"><i className="fa-solid fa-table mr-2"></i>Fill</Button>
                            </InputGroup>
                        </Form.Group>
                    }
                </Col>
            </Row>
            {schema !== "" && schema != undefined &&
                <Row>
                    <Col className="mr-2">
                        <Form.Group className="mb-3" controlId="dbname">
                            <Form.Label>Table Name:</Form.Label>
                            <Typeahead id="tables" inputProps={{ id: "tables" }}
                                onChange={_selectTable} size="sm"
                                clearButton
                                options={getTables()}
                                defaultOpen={false}
                                labelKey="Table"
                                placeholder="Choose table..."
                                selected={(props.table.ghostTable != undefined && props.table.ghostTable !== "") ? [props.table.ghostTable] : (table != undefined ? [ghostTable] : [])}
                                disabled={(props.table.connection !== undefined && props.table.connection !== "") || (props.table.table !== "")}
                            />

                            <Form.Control.Feedback >Looks good!</Form.Control.Feedback>
                            <Form.Control.Feedback type="invalid" >
                                Client side name for Dymium database
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>

                </Row>
            }
            {schema !== "" && schema != undefined && table !== "" && table != undefined &&
                PIIs.length != 0 && tablestructure != null && tablestructure.length !== 0 && tabledef.length !== 0 &&
                <>
                    {/* Shared Overlay for comment*/}
                    <Modal
                        show={overlayState.show && (overlayState.typ == OverlayMode.Column)}
                        target={overlayState.target}
                        placement="bottom"
                        container={form}
                        onHide={handleCancel}

                        onShow={() => {
                            let c = document.getElementById("overlaycolumn")
                            c?.focus()
                        }}
                        style={{
                            position: 'absolute',
                            top: overlayState.top,
                            left: overlayState.left,
                            width: "400px", // Increase the width here
                            height: '200px'
                        }}
                    >


                        <Modal.Body>
                            <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                                <span style={{ fontWeight: 'bold', whiteSpace: 'nowrap' }}>{overlayState.originalValue} =&gt;</span>
                                <Form.Control id="overlaycolumn"
                                    type="text"
                                    value={overlayState.value}
                                    size="sm"

                                    style={{ flex: 1, maxWidth: '15em' }}
                                    onChange={({ target: { value } }) => {
                                        value = value.trim()
                                        // do the validation here
                                        let conflicts = false
                                        tablestructure.forEach(x => {
                                            if (value === x.ghostName) {
                                                conflicts = true
                                            }
                                        })
                                        setNameConflict(conflicts)
                                        setOverlayState((state) => ({ ...state, value }))
                                    }
                                    }
                                />
                            </div>
                        </Modal.Body>

                        <Modal.Footer style={{ padding: '0px' }}>
                            <Button
                                variant={(nameConflict || overlayState.value === "") ? "danger" : "dymium"}
                                size="sm"
                                onClick={handleSaveColumn}
                                disabled={(nameConflict || overlayState.value === "")}
                            >
                                OK
                            </Button>
                            <Button
                                variant="dymium"
                                size="sm"
                                className="ms-2"
                                onClick={handleCancel}
                            >
                                Cancel
                            </Button>
                        </Modal.Footer>

                    </Modal>
                    {/* Shared Overlay for comment*/}
                    <Modal
                        show={overlayState.show && (overlayState.typ == OverlayMode.Type)}
                        target={overlayState.target}
                        placement="bottom"
                        container={form}
                        onHide={handleCancel}
                        style={{
                            position: 'absolute',
                            top: overlayState.top,
                            left: overlayState.left,
                            width: "300px", // Increase the width here
                            minHeight: '300px'
                        }}

                    >
                        <Modal.Body>
                            <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                                <span style={{ fontWeight: 'bold', whiteSpace: 'nowrap' }}>{overlayState.originalValue} =&gt;</span>
                                <Form.Control id="overlaytype"
                                    type="text"
                                    value={overlayState.value}
                                    size="sm"

                                    style={{ flex: 1, maxWidth: '15em' }}
                                    onChange={({ target: { value } }) => {
                                        value = value.trim()
                                        let r = tst.verify(overlayState.originalValue, value)
                                        let res: tst.Result = r[0]
                                        let _allowedActions = r[1]
                                        setTypecastResult(res)

                                        return setOverlayState((state) => ({ ...state, value, allowedActions: _allowedActions }))
                                    }
                                    }
                                />
                            </div>
                        </Modal.Body>

                        <Modal.Footer style={{ padding: '0px' }}>

                            <Button
                                variant={(typecastResult == "Error" || typecastResult == "Incompatible") ? "danger" :
                                    (typecastResult == "Questionable" ? "warning" : "dymium")

                                }
                                size="sm"
                                onClick={handleSaveType}
                                disabled={(typecastResult == "Error" || typecastResult == "Incompatible") ||
                                    overlayState.value == null || overlayState.value.length === 0}
                            >
                                OK
                            </Button>
                            <Button
                                variant="dymium"
                                size="sm"
                                className="ms-2"
                                onClick={handleCancel}
                            >
                                Cancel
                            </Button>

                        </Modal.Footer>


                    </Modal>
                    {/* Shared Overlay for comment*/}
                    <Modal
                        show={overlayState.show && (overlayState.typ == OverlayMode.Comment)}
                        target={overlayState.target}
                        placement="bottom"
                        container={form}
                        onHide={handleCancel}
                        style={{
                            position: 'absolute',
                            top: overlayState.top,
                            left: overlayState.left,
                            width: "400px", // Increase the width here
                            minHeight: '300px'
                        }}
                    >

                        <Form.Control
                            as="textarea"
                            rows={3}
                            value={overlayState.value}
                            ref={textareaRef}
                            onChange={(e) => {
                                let value = e.target.value.trimStart()
                                setOverlayState((prev) => ({
                                    ...prev,
                                    value: value,
                                }))
                            }
                            }
                        />

                        <Modal.Footer style={{ padding: '0px' }}>
                            <Button
                                variant="dymium"
                                size="sm"
                                onClick={handleSave}
                            >
                                OK
                            </Button>
                            <Button
                                variant="dymium"
                                size="sm"
                                className="ms-2"
                                onClick={handleCancel}
                            >
                                Cancel
                            </Button>
                        </Modal.Footer>


                    </Modal>
                    <Modal show={showModal} onHide={() => setShowModal(false)}
                        placement="bottom"
                        container={form}

                        style={{
                            position: 'absolute',
                            top: overlayState.top,
                            //left: overlayState.left,
                            width: "400px", // Increase the width here
                            height: '300px'
                        }}
                    >

                        <Modal.Body>Name collision detected! Give this table a different name.

                            <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                                <span style={{ fontWeight: 'bold', whiteSpace: 'nowrap' }}>{table} =&gt;</span>
                                <Form.Control id="overlaytype"
                                    type="text"
                                    value={ghostTable}
                                    size="sm"

                                    style={{ flex: 1, maxWidth: '15em' }}
                                    onChange={({ target: { value } }) => {
                                        value = value.trim()
                                        setGhostTable(value)
                                        let t = {
                                            schema, table, ghostSchema, ghostTable: value, description: description,
                                            id: props.id, tableId: props.tableId, connectionId: props.connectionId, tablescope: tablestructure
                                        }
                                        if (props.onCheckCollision(t)) {
                                            setNameConflict(true)
                                            return
                                        }
                                        setNameConflict(false)
                                    }
                                    }
                                />
                            </div>


                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant={nameConflict ? "danger" : "dymium"} size="sm"
                                disabled={nameConflict}
                                onClick={() => setShowModal(false)}>
                                Save
                            </Button>
                            <Button variant="dymium" size="sm" onClick={() => setShowModal(false)}>
                                Cancel
                            </Button>

                        </Modal.Footer>
                    </Modal>
                    <BootstrapTable id="schematable"
                        condensed
                        striped bordered={false}
                        bootstrap4
                        keyField='position'
                        data={tablestructure}
                        columns={tabledef}
                    />

                    <Form.Group className="mb-3 me-1" controlId="description">
                        <Form.Label>Table Description:</Form.Label>
                        <Form.Control
                            as="textarea"
                            rows={3}
                            style={{ width: "100%", marginRight: "30px" }}
                            required
                            placeholder="Please put in the semantic description of this table"
                            onChange={(e) => {
                                let value = e.target.value.trimStart()
                                setDescription(value)
                            }
                            }
                            value={description}

                        />
                        <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                        <Form.Control.Feedback type="invalid">
                            Please put in some description
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Button data-testid="apply-structure" variant="dymium" size="sm" className="mt-4" type="submit">
                        Apply
                    </Button>
                </>

            }
        </Form>


    </div>
}

export default AddTable
